import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import styles from "./monitor.module.scss";
import { selectedTabItem } from "helper/global";
import Tabs from "components/tabs/tabs";
import Project from "./project/project";

const Component = (props) => {
  //tabs

  // functions

  function changeView(e) {
    props.history.push(`/klient/manage/monitor${e.path}`);
  }

  const tabItems = [
    {
      text: "Project",
      icon: "fad fa-construction",
      path: "/project",
    },
  ];

  return (
    <div className={styles.main}>
      <div className={`${styles.tabs} `}>
        <Tabs
          tabItems={tabItems}
          selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)}
          changeView={changeView}
          className={"dx-theme-border-color"}
        />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route exact path={["/klient/manage", "/klient/manage/monitor"]} component={Project} />
          <Route exact path="/klient/manage/monitor/project" component={Project} />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
};

export default Component;
