import React, { useEffect, useRef } from "react";
import Grid from "components/data-grid/data-grid.js";
import { currency } from "helper/global";
import { Column } from "devextreme-react/data-grid";
import { Popup as DxPopup } from "devextreme-react";
import Editor from "components/editor/editor";
import { useImmerReducer } from "use-immer";

function reducer(draft, action) {
  switch (action.type) {
    case "showEditor":
      draft.showEditor = true;
      draft.nodeId = action.payload.nodeId;
      return;
    case "hideEditor":
      draft.showEditor = false;
      return;
    default:
      return;
  }
}

function Component(props) {
  //
  // hooks
  const initialState = {
    nodeId: null,
    showEditor: false,
  };

  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const dataGrid = useRef(null);

  // event handlers

  function hideEditor(e) {
    dispatch({ type: "hideEditor" });
  }

  function refresh() {
    dataGrid.current.refresh();
  }

  function onInitialized(e) {
    dataGrid.current = e.component;
  }

  // render

  return (
    <React.Fragment>
      <DxPopup
        visible={state.showEditor}
        onHiding={hideEditor}
        showCloseButton={true}
        dragEnabled={true}
        closeOnOutsideClick={false}
        showTitle={true}
        title={"Asset"}
        width={700}
        height={700}
      >
        <Editor nodeId={state.nodeId} refresh={refresh} hideEditor={hideEditor} />
      </DxPopup>
      <DataGrid
        title={props.title}
        dataSource={props.dataSource}
        onInitialized={onInitialized}
        onBackButtonClick={props.onBackButtonClick}
        dispatch={dispatch}
      />
    </React.Fragment>
  );
}

export default Component;

const DataGrid = React.memo(
  (props) => {
    //
    // hooks

    // event handlers

    function onBackButtonClick() {
      props.onBackButtonClick && props.onBackButtonClick();
    }

    function onClick(e) {
      props.dispatch({ type: "showEditor", payload: { nodeId: e.row.data.id, assetId: e.row.data.assetId } });
    }

    // render

    function onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: "before",
        widget: "dxButton",
        visible: !!props.onBackButtonClick,
        options: {
          hint: "Run Scenario",
          type: "back",
          onClick: onBackButtonClick,
        },
      });
    }

    return (
      <Grid
        title={props.title}
        dataSource={props.dataSource}
        allowAdding={false}
        allowUpdating={false}
        allowDeleting={false}
        onToolbarPreparing={onToolbarPreparing}
        onInitialized={props.onInitialized}
      >
        <Column caption={"AssetId"} dataField={"name"} width={200} />
        <Column caption={"Description"} dataField={"description"} width={400} />
        <Column caption={"Location"} dataField={"location"} width={200} visible={false} />
        <Column caption={"Ownership"} dataField={"ownership"} width={150} />
        <Column caption={"Status"} dataField={"status"} width={150} />
        <Column caption={"Class"} dataField={"class"} width={150} />
        <Column caption={"Type"} dataField={"type"} width={150} visible={true} />
        <Column caption={"Size"} dataField={"size"} width={150} visible={true} />
        <Column caption={"Material"} dataField={"material"} width={150} visible={true} />
        <Column caption={"Manufacturer"} dataField={"manufacturer"} width={150} visible={true} />
        <Column caption={"Model"} dataField={"model"} width={150} visible={true} />
        <Column caption={"Count"} dataField={"count"} dataType="number" width={150} />
        <Column caption={"Install Year"} dataType="number" dataField={"installYear"} width={150} />
        <Column caption={"Useful Life"} dataField={"usefulLife"} dataType="number" width={150} />
        <Column caption={"StrategyId"} dataField={"strategy"} width={150} />
        <Column caption={"Replacement / Program Cost"} dataField={"activityCost"} width={150} format={`${currency()}#,###`} />
        <Column caption={"Escalated Replacement / Program Cost"} dataField={"escalatedActivityCost"} width={150} format={`${currency()}#,###`} />
        <Column caption={"Consumed"} dataField={"consumed"} dataType="number" width={150} format={"0.##"} />
        <Column caption={"Condition"} dataField={"condition"} dataType="number" width={150} format={"0.##"} />
        <Column caption={"R"} dataField={"r"} dataType="number" width={150} format={"0.##"} />
        <Column caption={"Standby"} dataField={"standby"} dataType="number" width={150} />
        <Column caption={"PoF"} dataField={"pof"} dataType="number" width={150} format={"#0.00"} />
        <Column caption={"CoF"} dataField={"cof"} dataType="number" width={150} format={"#0.00"} />
        <Column caption={"Bre"} dataField={"bre"} dataType="number" width={150} format={"#0.##"} />
        <Column caption={"Condition Rating"} dataField={"conditionRating"} width={150} />
        <Column caption={"Risk Rating"} dataField={"riskRating"} width={150} />
        <Column
          type={"buttons"}
          buttons={[
            "edit",
            {
              hint: "Edit",
              icon: "edit",
              visible: true,
              onClick: onClick,
            },
          ]}
          width={125}
          fixed={true}
        />
      </Grid>
    );
  },
  (prev, next) => {
    return prev.dataSource === next.dataSource;
  }
);
