import React, { useRef } from "react";
import { FileUploader, Popup } from "devextreme-react";
import { api, url } from "config.js";
import ScrollView from "devextreme-react/scroll-view";
import { dict } from "helper/global";

function Component(props) {
  // hooks

  const fileUploader = useRef();

  // event handlers

  function onUploaded(e) {
    fileUploader.current.instance.reset();
    props.onUploaded(e);
  }

  // render

  return (
    <div>
      <Popup
        className={"popup"}
        visible={props.visible}
        onHiding={props.onHiding}
        dragEnabled={true}
        closeOnOutsideClick={false}
        showTitle={true}
        title={dict("File Uploader")}
        width={600}
        height={600}
      >
        <ScrollView>
          <FileUploader
            ref={fileUploader}
            uploadMode={"useButtons"}
            multiple={props.multiple || false}
            allowedFileExtensions={[".jpg", ".jpeg", ".gif", ".png"]}
            uploadUrl={`${api}/${url}/messagefile/blob`}
            onUploaded={onUploaded}
          />
          <span className="note">
            {`${dict("Allowed file extensions")}: `}
            <span>{props.allowedFileExtensions && props.allowedFileExtensions.map((i) => `  ${i}`)}</span>
          </span>
        </ScrollView>
      </Popup>
    </div>
  );
}

export default Component;
