import * as actionTypes from "./actionTypes";
import { request } from "helper/http-client";
import { url } from "config.js";

export const _loadScenario = (result) => {
  return {
    type: actionTypes.SCENARIO_LOAD,
    result: result,
  };
};

export const loadScenario = () => {
  return async (dispatch) => {
    try {
      let result = await request({ url: `${url}/scenario/active` });
      //console.log(result);
      dispatch(_loadScenario(result));
    } catch (error) {}

    return "done";
  };
};

export function saveScenario(data) {
  return function (dispatch) {
    dispatch(_loadScenario(data));
  };
}
