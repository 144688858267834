import React, { useContext } from "react";
import styles from "./activity.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { url } from "config.js";
import { request } from "helper/http-client";
import HierarchyContext from "components/hierarchy/context";
import ActivityGrid from "components/activity-grid/activity-grid";

function Activity(props) {
  // useContext

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  // stores

  const assetStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/engine/output/activities`,
          params: { ids: selectedRowsData.map((i) => i.id), filterExpression: filter },
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // render
  return (
    selectedRowsData.length > 0 && (
      <div className={styles.main}>
        <div className={styles.container}>
          <ActivityGrid
            title={"Activity"}
            dataSource={assetStore}
            allowEditing={false}
            rowNumbering={true}
            resetRowSelectionOnLoad={true}
            onToolbarPreparing={props.onToolbarPreparing}
          ></ActivityGrid>
        </div>
      </div>
    )
  );
}

export default React.memo(Activity, (prev, next) => {
  return prev.selectedRowsData === next.selectedRowsData && prev.filter === next.filter;
});
