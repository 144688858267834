import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import styles from "./lookup.module.scss";
import { selectedTabItem } from "helper/global";
import Salary from "./salary/salary";
import ActivityType from "./activity-type/activity-type";
import CostType from "./cost-type/cost-type";
import CostCenter from "./cost-center/cost-center";
import ConditionRating from "./condition-rating/condition-rating";
import RiskRating from "./risk-rating/risk-rating";
import Fund from "./fund/fund";
import { dict } from "helper/global";

function Lookup(props) {
  // tabs

  const tabItems = [
    {
      text: dict("Activity"),
      icon: "fad fa-hiking",
      path: "/activityType",
    },
    {
      text: dict("Fund"),
      icon: "fad fa-piggy-bank",
      path: "/fund",
    },
    {
      text: dict("Cost"),
      icon: "fad fa-search-dollar",
      path: "/costtype",
    },
    {
      text: dict("Cost Center"),
      icon: "fad fa-money-check-edit",
      path: "/costcenter",
    },
    {
      text: dict("Salary"),
      icon: "fad fa-hand-holding-usd",
      path: "/salary",
    },
    {
      text: dict("Condition"),
      icon: "fad fa-smile",
      path: "/conditionrating",
    },
    {
      text: dict("Risk"),
      icon: "fad fa-frown",
      path: "/riskrating",
    },
  ];

  // functions

  function changeView(e) {
    props.history.push(`/klient/analyze/lookup${e.path}`);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView} />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route exact path={["/klient/analyze", "/klient/analyze/lookup"]} component={ActivityType} />
          <Route exact path="/klient/analyze/lookup/activitytype" component={ActivityType} />
          <Route exact path="/klient/analyze/lookup/fund" component={Fund} />
          <Route exact path="/klient/analyze/lookup/costtype" component={CostType} />
          <Route exact path="/klient/analyze/lookup/costcenter" component={CostCenter} />
          <Route exact path="/klient/analyze/lookup/salary" component={Salary} />
          <Route exact path="/klient/analyze/lookup/conditionrating" component={ConditionRating} />
          <Route exact path="/klient/analyze/lookup/riskrating" component={RiskRating} />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

export default Lookup;
