//import themes from "devextreme/ui/themes";
import React, { useEffect } from "react";
import "devextreme/dist/css/dx.common.css";
import "theme/material-dark.css";
//import "theme/iris-light-theme.css";
import { LoadIndicator } from "devextreme-react/load-indicator";
//import themes from 'devextreme/ui/themes';
import Klient from "klient/klient.js";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import "./app.scss";
import Login from "auth/login/login";
import Start from "auth/start/start";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import "theme/typography.scss";
import "theme/components/datagrid.scss";
import "theme/components/treelist.scss";
import "theme/components/treeview.scss";
import "theme/components/form.scss";
import "theme/components/toolbar.scss";
import { locale, loadMessages } from "devextreme/localization";
import getLanguage from "assets/dictionaries";

function App(props) {
  // useRef

  // useEffect

  props.isAuthenticated &&
    (() => {
      let dict1 = {};
      let dict2 = {};
      dict1[props.dictionary] = getLanguage(props.dictionary);
      dict2[props.dictionary] = props.dictionaryKeyValue;
      loadMessages(dict1);
      loadMessages(dict2);
      locale(props.dictionary);
    })();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isAuthenticated]);

  useEffect(() => {
    props.checkUserStatus();
    // let dict1 = {};
    // let dict2 = {};
    // dict1[props.dictionary] = getLanguage(props.dictionary);
    // dict2[props.dictionary] = props.dictionaryKeyValue;
    // loadMessages(dict1);
    // loadMessages(dict2);
    // locale(props.dictionary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // render

  function loginRender() {
    return (
      <Switch>
        <Route path="/start" component={Start} />
        <Route path="/login" component={Login} />
        <Redirect from="/" to="/login" />
      </Switch>
    );
  }

  function klientRender() {
    return (
      <Switch>
        <Route path="/klient" component={Klient} />
        <Redirect from="/" to="/klient" />
      </Switch>
    );
  }

  if (props.isAuthenticated !== null) {
    return <div>{props.isAuthenticated ? klientRender() : loginRender()}</div>;
  }

  return (
    <div className="app_start_main">
      <div className={"app_start_logo"}>
        <img
          src={require(props.location.pathname === "/start" ? "assets/icons/logograyed.png" : "assets/icons/logogray.png")}
          width="75"
          height="48"
          alt=""
        />
      </div>
      <div className={"app_start_text"}>
        <LoadIndicator id="image-indicator" indicatorSrc={require("assets/gifs/5.gif")} height={15} width={120} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    checking: state.auth.checking,
    theme: state.user.theme,
    dictionaryKeyValue: state.dictionary,
    dictionary: state.org.dictionary,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkUserStatus: () => dispatch(actions.check()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

//export default App;
