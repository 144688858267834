import React from "react";
import Card from "../cards/card3/card";
import styles from "./project.module.scss";
import { getPalette } from "devextreme/viz/palette";
import { dict } from "helper/global";

function Component(props) {
  // render

  function cardRender() {
    if (!props.data) return null;
    console.log(props.data);

    let palette = getPalette("Office");
    let set = palette.simpleSet;
    let index = 0;

    return props.data.data.map((project) => {
      let color = set[index++];
      if (index > 5) index = 0;
      return (
        <div key={project.name} className={styles.item}>
          <Card data={project} color={set[0]} cursor={true} />
        </div>
      );
    });
  }

  return (
    <div className={styles.main}>
      <div className={styles.title}>{dict("Projects")}</div>
      {cardRender()}
    </div>
  );
}

export default Component;
