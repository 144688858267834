import React from "react";
import Hierarchy from "components/hierarchy/hierarchy";
import Layer from "./gis-layer/gis-layer";

function Component() {
  //
  // store

  // render

  return (
    <Hierarchy title={"Hierarchy"} allowEditing={false} allowDragging={false} multiSelect={false}>
      <Layer />
    </Hierarchy>
  );
}

export default Component;
