import * as actionTypes from "./actionTypes";
import { request } from "helper/http-client";
import { url } from "config.js";
import { notify } from "helper/global";

export const _loadUser = (result) => {
  return {
    type: actionTypes.USER_LOAD,
    ...result,
  };
};

function _upateUser(data) {
  return {
    type: actionTypes.USER_UPDATE,
    ...data,
  };
}

export const loadUser = () => {
  return async (dispatch) => {
    try {
      let result = await request({ url: `${url}/usersetting` });
      dispatch(_loadUser(result));
    } catch (error) {
      notify("Unable to load user settings", "error");
    }
    return "done";
  };
};

export function saveUser(data) {
  return async (dispatch) => {
    try {
      await request({ url: `${url}/usersetting`, method: "Put", data: data });
      dispatch(_upateUser(data));
    } catch (error) {}
  };
}
