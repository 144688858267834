import React from "react";
import Asset from "./asset/asset";
import Hierarchy from "components/hierarchy/hierarchy";
import { url } from "config.js";

function Component(props) {
  // render

  return (
    <Hierarchy
      type={1}
      countUrl={`${url}/node/count`}
      allowEditing={true}
      allowDragging={true}
      showCount={true}
      showFilter={true}
      filterFields={["Class", "Type", "Size", "Material", "Manufacturer", "Status", "Ownership", "Group", "Tag"]}
      filterType={0}
    >
      <Asset />
    </Hierarchy>
  );
}

export default Component;
