import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "helper/global";

const initialState = {
  isAuthenticated: null,
  error: null,
  loading: false,
  checking: true,
};

const authStart = (state) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    isAuthenticated: true,
    error: null,
    loading: false,
  });
};

const authCheckStart = (state, action) => {
  return updateObject(state, { error: null, checking: true });
};

const authCheckSuccess = (state, action) => {
  return updateObject(state, {
    isAuthenticated: true,
    error: null,
    checking: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    userId: false,
    error: action.error,
    loading: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    isAuthenticated: false,
    loading: false,
    checking: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_CHECK_START:
      return authCheckStart(state, action);
    case actionTypes.AUTH_CHECK_SUCCESS:
      return authCheckSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
