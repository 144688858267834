import React from "react";
import { url } from "config.js";
import { currency, toMillion } from "helper/global";
import styles from "./dist.module.scss";

function Component(props) {
  //
  // useState

  // constants

  const rows = (props.data && props.data.length) || 3;
  const gridGap = "10px";

  // styles

  const matrix = {
    height: "100%",
    display: "grid",
    gridGap: gridGap,
    gridTemplateRows: `repeat(${rows}, 1fr)`,
  };

  const box = (color) => ({
    backgroundColor: `${color}`,
  });

  // event handlers

  function onBoxClick(rating) {
    const title = `Assets with Risk Rating: ${rating}`;
    props.onBoxClick &&
      props.onBoxClick({
        url: `${url}/engine/output/risk-distribution-nodes`,
        params: { rating: rating },
        title: title,
      });
  }

  // render

  function Box(rating) {
    var color = props.data.find((i) => i.rating === rating).color;
    var cost = props.data.find((i) => i.rating === rating).cost;
    var count = props.data.find((i) => i.rating === rating).count;
    return (
      <div
        className={styles.box}
        style={box(color)}
        key={rating}
        onClick={() => {
          onBoxClick(rating);
        }}
      >
        {rating}
        <br />
        {cost ? `${currency()}${toMillion(cost)}` : "$0"}
        <br />
        {count || false ? count : "0"}
      </div>
    );
  }

  function Matrix() {
    var boxes = props.data.map((i) => Box(i.rating));
    return boxes;
  }

  return <div style={matrix}>{<Matrix />}</div>;
}

export default Component;
