import React, { useEffect, useRef } from "react";
import styles from "./dictionary.module.scss";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import ProgressPanel from "components/progress-panel/progress-panel";

function Component(props) {
  const dictStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/dictionary`, loadOptions: loadOptions });
      },
      update: (_, data) => {
        return request({ url: `${url}/dictionary`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/dictionary/${id}`, method: "Delete" });
      },
    }),
  };

  // hooks

  const exporting = useRef(false);

  // event handlers

  useEffect(() => {}, []);

  // render

  return (
    <ProgressPanel name={"UPLOAD_CLASS"}>
      <div className={`${styles.main} theme-form`}>
        <Grid
          title={"Class"}
          showTitle={false}
          dataSource={dictStore}
          editingMode={"row"}
          rowNumbering={true}
          showEditingPopup={true}
          showFilterRow={true}
          remoteOperations={false}
        >
          <Column visible={false} dataField={"id"} formItem={{ visible: false }} />
          <Column caption={"Key"} dataField={"key"} allowFiltering={true} width={400} allowEditing={false}>
            <RequiredRule></RequiredRule>
          </Column>
          <Column caption={"Value"} dataField={"value"} allowFiltering={true} width={400} />
          <Column caption={"Type"} dataField={"type"} allowFiltering={true} width={400} allowEditing={false} />
          <Column
            caption={"Notes"}
            dataField={"notes"}
            allowEditing={false}
            formItem={{ colSpan: 2, visible: false, editorType: "dxTextArea", editorOptions: { height: 75 } }}
          />
          <Column type={"buttons"} width={100} fixed={true} />
        </Grid>
      </div>
    </ProgressPanel>
  );
}

export default Component;
