import React from "react";
import styles from "./card.module.scss";
import { BarGauge, Label, Size } from "devextreme-react/bar-gauge";
import { commaFormat, currencyFormat } from "helper/global";

function Card(props) {
  // event handlers

  function onClick() {
    props.onClick && props.onClick(props.id);
  }

  function customizeText({ valueText }) {
    return `${valueText} %`;
  }

  function formatter() {
    if (!props.data.value) {
      return "No Value";
    }

    if (props.data.quantity === "currency") {
      return `${props.data.description} ${currencyFormat(props.data.value, props.data.unit)}`;
    }

    return `${props.data.description} ${commaFormat(props.data.value)} ${props.data.unit || ""}`;
  }

  // variables

  const format = {
    type: "fixedPoint",
    precision: 1,
  };

  const shade = {
    color: props.color,
  };

  // render

  return (
    <div className={` ${styles.main} ${props.cursor && styles.main_cursor}`} onClick={onClick}>
      <div className={styles.grid}>
        <div className={styles.text}>
          <div className={styles.title}>{props.data.name || "No Title"}</div>
          <div className={styles.value}>
            <div className={styles.shade} style={shade}>
              {formatter()}
            </div>
          </div>
        </div>

        <div className={styles.gauge}>
          <BarGauge
            id="gauge"
            startValue={0}
            endValue={100}
            defaultValues={[props.data.percentageCompleted]}
            relativeInnerRadius={0.8}
            palette={[props.color]}
          >
            <Label visible={false} indent={30} format={format} customizeText={customizeText} />
            <Size height={50}></Size>
          </BarGauge>
        </div>
        <div className={styles.gauge_value}>{`${props.data.percentageCompleted}%`}</div>
      </div>
    </div>
  );
}

export default Card;
