import React from "react";
import Profile from "./profile/profile";
import styles from "./cost.module.scss";
import Hierarchy from "components/hierarchy/hierarchy";

function Component(props) {
  return (
    <div className={styles.main}>
      <Profile scenarioId={props.scenarioId} />
    </div>
  );
}
export default Component;
