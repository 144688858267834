import React, { useContext, useEffect } from "react";
import styles from "./condition.module.scss";
import PieChart from "components/piechart/piechart";
import { currencyFormat, dict, withMemo } from "helper/global";
import AssetList from "components/asset-grid/asset-grid";
import { Series, Label, Connector, AdaptiveLayout, Legend } from "devextreme-react/chart";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { url } from "config.js";
import HierarchyContext from "components/hierarchy/context";
import { Slider } from "devextreme-react/slider";
import { connect } from "react-redux";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { Button } from "devextreme-react/button";
import { useImmerReducer } from "use-immer";
import { LoadIndicator } from "devextreme-react/load-indicator";

// reducer

function reducer(draft, action) {
  switch (action.type) {
    case "data":
      draft.data = action.payload;
      draft.firstYearLoaded = draft.year === draft.firstYear;
      draft.otherYearLoaded = draft.year !== draft.firstYear;
      draft.loading = false;
      return;
    case "loading":
      draft.loading = true;
      draft.firstYearLoaded = false;
      draft.otherYearLoaded = false;
      return;
    case "unload":
      draft.firstYearLoaded = false;
      draft.otherYearLoaded = false;
      return;
    case "toolbar":
      draft.toolbar = action.payload;
      return;
    case "showGrid":
      draft.rating = action.payload;
      draft.showGrid = true;
      return;
    case "hideGrid":
      draft.rating = null;
      draft.showGrid = false;
      if (!draft.firstYearLoaded && !draft.otherYearLoaded) {
        render(draft);
      }
      return;
    case "year":
      draft.year = action.payload;
      draft.otherYearLoaded = false;
      if (action.payload === draft.firstYear && !draft.firstYearLoaded) {
        render(draft);
      }
      return;
    case "escalate":
      draft.escalate = action.payload;
      draft.otherYearLoaded = false;
      //render(draft);
      return;
    case "run":
      render(draft);
      return;
    default:
      return;
  }

  function render(draft) {
    draft.render++;
    draft.loading = true;
    draft.firstYearLoaded = false;
    draft.otherYearLoaded = false;
  }
}

function Component(props) {
  // vars

  const items = [
    { id: 0, name: dict("Percent"), valueField: "percent" },
    { id: 1, name: dict("Count"), valueField: "count" },
    { id: 2, name: dict("Cost"), valueField: "cost" },
  ];

  const initialState = {
    data: null,
    toolbar: items[0],
    year: props.firstYear,
    loading: false,
    firstYear: props.firstYear,
    firstYearLoaded: true,
    otherYearLoaded: false,
    render: 0,
    escalate: false,
    showGrid: false,
    rating: "",
  };

  // hooks
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const context = useContext(HierarchyContext);
  const filter = context.filter;
  const selectedRowsData = context.selectedRowsData;

  // const

  const isFirstYear = state.year === props.firstYear;
  const isButton = !isFirstYear && !state.otherYearLoaded && !state.loading;
  const isEscalate = !isFirstYear && state.toolbar.name === "Cost";

  useEffect(() => {
    if (state.showGrid) {
      // renderChart.current = true;
      dispatch({ type: "unload" });
      return;
    }

    dispatch({ type: "loading" });

    (async () => {
      const result = await request({
        url: `${url}/engine/output/condition-distribution`,
        params: { ids: selectedRowsData.map((i) => i.id), year: state.year, escalate: state.escalate, filterExpression: filter },
      });
      dispatch({ type: "data", payload: result });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsData, filter, state.render]);

  // event handlers
  function customizeText(arg) {
    return state.toolbar.id === 2 ? currencyFormat(arg.value) : arg.value;
  }

  function onToolbarValueChanged(e) {
    dispatch({ type: "toolbar", payload: items.find((i) => i.id === e.value) });
  }

  function onPointClick(e) {
    dispatch({ type: "showGrid", payload: e.target.originalArgument });
  }

  function onBackButtonClick() {
    dispatch({ type: "hideGrid" });
  }

  function onSliderYearChanged(e) {
    dispatch({ type: "year", payload: e.value });
  }

  function onRunClick() {
    dispatch({ type: "run" });
  }

  function onEscalateChanged(e) {
    dispatch({ type: "escalate", payload: e.value });
  }

  // render

  function customizePoint(e) {
    return {
      color: e.data.color,
    };
  }

  function sliderRender() {
    return (
      <div className={styles.slider_container}>
        <div className={styles.slider_container_title}>{`${dict("Year")}:`}</div>
        <div className={styles.slider_container_slider}>
          <Slider
            min={props.firstYear}
            max={props.lastYear}
            defaultValue={state.year}
            value={state.year}
            step={1}
            showRange={true}
            onValueChanged={onSliderYearChanged}
            tooltip={{
              enabled: true,
              showMode: "always",
              position: "top",
            }}
          />
        </div>
      </div>
    );
  }

  function toolbarRender() {
    return (
      <div className={`${isEscalate ? styles.toolbar_expanded : styles.toolbar} theme-toolbar`}>
        <Toolbar>
          <Item location="after" locateInMenu="never">
            <div className={styles.item}>{`${dict("Show")}:`}</div>
          </Item>
          <Item
            location="after"
            locateInMenu="auto"
            widget="dxSelectBox"
            options={{
              valueExpr: "id",
              displayExpr: "name",
              value: state.toolbar.id,
              items: items,
              onValueChanged: onToolbarValueChanged,
            }}
          />
          <Item location="after" locateInMenu="never" visible={isEscalate}>
            <div className={styles.toolbar_item}>{`${dict("Escalate")}:`}</div>
          </Item>
          <Item
            location="after"
            locateInMenu="auto"
            widget="dxCheckBox"
            visible={isEscalate}
            options={{ value: state.escalate, onValueChanged: onEscalateChanged }}
          />
        </Toolbar>
      </div>
    );
  }

  function donutRender(pieChart) {
    var cost = pieChart
      .getAllSeries()[0]
      .getVisiblePoints()
      .reduce((s, p) => s + p.originalValue, 0);

    if (!cost) return null;
    return (
      <svg>
        <text textAnchor="middle" x="0" y="50" style={{ fontSize: 20, fill: "# #adadad" }}>
          <tspan x="100">{dict(state.toolbar.name)}</tspan>
        </text>
      </svg>
    );
  }

  function loadingRender() {
    return (
      <div className={styles.overlay}>
        <LoadIndicator id="image-indicator" />
      </div>
    );
  }

  function chartRender() {
    return (
      state.data && (
        <div className={styles.chart}>
          <PieChart
            dataSource={state.data}
            type="donut"
            innerRadius={0.65}
            centerRender={donutRender}
            customizePoint={customizePoint}
            onPointClick={onPointClick}
          >
            <Series argumentField="rating" valueField={state.toolbar.valueField}>
              <Label visible={true} position="columns" customizeText={customizeText}>
                <Connector visible={true} width={1} />
              </Label>
            </Series>
            <Legend position="outside" orientation="vertical" verticalAlignment="top" horizontalAlignment="right" />
            <AdaptiveLayout height={500} width={500} />
          </PieChart>
        </div>
      )
    );
  }

  function runButtonRender() {
    return (
      <div className={styles.overlay}>
        <Button text={"Run"} stylingMode="outlined" icon={"spinright"} type={"success"} onClick={onRunClick} />
      </div>
    );
  }

  if (selectedRowsData.length === 0) return null;

  return state.showGrid ? (
    <Asset selectedRowsData={selectedRowsData} filter={filter} year={state.year} rating={state.rating} onBackButtonClick={onBackButtonClick} />
  ) : (
    <div className={styles.main}>
      <div className={`${styles.header}`}>
        {sliderRender()}
        {toolbarRender()}
      </div>
      {isButton && runButtonRender()}
      {state.loading && loadingRender()}
      {state.data && chartRender()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    firstYear: state.scenario.firstYear,
    lastYear: state.scenario.firstYear + state.scenario.planningHorizon,
  };
};

export default connect(mapStateToProps, null)(Component);

const Asset = withMemo(
  (props) => {
    //
    // store

    const nodeStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          return request({
            url: `${url}/engine/output/condition-nodes`,
            params: {
              ids: props.selectedRowsData.map((i) => i.id),
              year: props.year,
              rating: props.rating,
              filterExpression: props.filter,
            },
            loadOptions: loadOptions,
          });
        },
      }),
    };

    return <AssetList title={`Asset Condition: ${props.rating}`} dataSource={nodeStore} onBackButtonClick={props.onBackButtonClick} />;
  },
  ["selectedRowsData", "filter", "rating"]
);
