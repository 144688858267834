import React, { useRef, useEffect } from "react";
import TreeView from "devextreme-react/tree-view";
import "./layout.scss";
import styles from "./layout.module.scss";
import * as events from "devextreme/events";
import Drawer from "devextreme-react/drawer";
import { Template } from "devextreme-react/core/template";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
import Header from "./header/header";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";

function Component(props) {
  // hooks

  const treeView = useRef();
  const div = useRef();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  // event handlers

  function toggleMenu() {
    if (props.menuOpened) treeView.current.collapseAll();
    props.toggleMenu();
  }

  function selectedItem() {
    let path2 = location.pathname.split("/")[2];
    let path3 = location.pathname.split("/")[3];

    if (path2 === undefined) return "/home";

    let item = null;

    props.navigation.forEach((obj) => {
      if (obj.items && obj.items.length >= 0 && obj.items.some((item) => item.path.includes(`/${path2}`))) {
        item = obj.items.find((item) => item.path.includes(`/${path3}`)) || obj.items[0];
      }
    });
    return (item && item.path) || "/home";
  }

  function onItemClick(e) {
    if (props.menuOpened && e.itemData.path) {
      history.push(`${match.path}${e.itemData.path}`);
    }
  }

  function onMenu(e) {
    if (!props.menuOpened) {
      props.toggleMenu();
    }
  }

  // functions

  function onSelectionChanged(e) {
    const nodeClass = "dx-treeview-node";
    const selectedClass = "dx-state-selected";
    const leafNodeClass = "dx-treeview-node-is-leaf";
    const element = e.element;

    const rootNodes = element.querySelectorAll(`.${nodeClass}:not(.${leafNodeClass})`);

    Array.from(rootNodes).forEach((node) => {
      node.classList.remove(selectedClass);
    });

    let selectedNode = element.querySelector(`.${nodeClass}.${selectedClass}`);

    if (selectedNode) {
      while (selectedNode && selectedNode.parentElement) {
        if (selectedNode.classList.contains(nodeClass)) {
          selectedNode.classList.add(selectedClass);
        }

        selectedNode = selectedNode.parentElement;
      }
    }
    treeView.current.selectItem(selectedItem());

    if (props.menuOpened) {
      //treeView.current.expandItem(selectedItem());
      //console.log("%cEXPAND", "color:red");
    }
  }

  // render

  function envRender() {
    return process.env.REACT_APP_CUSTOM_NODE_ENV === "Production" ? "" : `${process.env.REACT_APP_CUSTOM_NODE_ENV}`;
  }

  function MyTreeView() {
    useEffect(() => {
      events.on(div.current, "dxclick", onMenu);

      return () => {
        events.off(div.current, "dxclick");
      };
    }, []);

    return (
      <div ref={div} className={`klient_layout_side_treeview ${styles.tree}`}>
        <div className={styles.tree_item}>
          <TreeView
            //className={""}
            items={props.navigation}
            selectionMode={"single"}
            keyExpr={"path"}
            searchMode={"contains"}
            //expandAllEnabled={true}
            expandEvent={"click"}
            width={"100%"}
            //expandedExpr="isExpanded"
            //selectByClick
            onSelectionChanged={onSelectionChanged}
            onItemClick={onItemClick}
            onInitialized={(e) => {
              treeView.current = e.component;
              treeView.current.option("deferRendering", false);
            }}
            onContentReady={onSelectionChanged}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <Header menuToggleEnabled toggleMenu={toggleMenu} />
      </div>
      <Drawer
        className={"klient_layout_side"}
        opened={props.menuOpened}
        openedStateMode={"shrink"}
        revealMode={"expand"}
        shading={false}
        minSize={60}
        position={"before"}
        maxSize={200}
        //height={400}
        template={"menu"}
      >
        <div className={styles.container}>{props.children}</div>

        <Template name="menu">
          <MyTreeView></MyTreeView>
        </Template>
      </Drawer>
      <div className={styles.footer}>
        <div className={styles.footer_left}></div>
        <div className={styles.footer_right}>
          IRIS by Kayuga {`(${process.env.REACT_APP_DOC_VERSION})`} {envRender()}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menuOpened: state.ui.menuOpened,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenu: () => dispatch(actions.toggleMenu()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
