import React, { useRef } from "react";
import styles from "./activity.module.scss";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { addFilter } from "helper/global";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import DropDown from "components/grid-dropdown/grid-dropdown";

function Component(props) {
  //
  // useRef

  const exporting = useRef(false);

  // stores

  const scenarioActivityStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (exporting.current) {
          if (!props.selectedRowsData.length === 0) return Promise.resolve({ data: [] });
          return request({
            url: `${url}/scenarionodeactivity/download`,
            loadOptions: loadOptions,
            params: { ids: props.selectedRowsData.map((i) => i.id) },
          });
        } else {
          if (!props.scenarioNodeId) return Promise.resolve({ data: [] });
          return request({
            url: `${url}/scenarionodeactivity`,
            loadOptions: addFilter(loadOptions, "and", ["scenarioNodeId", "=", props.scenarioNodeId]),
          });
        }
      },
      insert: (data) => {
        data.scenarioNodeId = props.scenarioNodeId;
        return request({ url: `${url}/scenarionodeactivity`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/scenarionodeactivity`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/scenarionodeactivity/${id}`, method: "Delete" });
      },
    }),
  };

  const activityStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/activity/node/${props.scenarioNodeId}`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/activity/read/${id}` });
      },
    }),
  };

  // event Handler

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_ACTIVITYOVERRIDE", url: `${url}/scenarionodeactivity/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("node", "visible", true);
    e.component.columnOption("strategy", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("node", "visible", false);
    e.component.columnOption("strategy", "visible", false);
    exporting.current = false;
  }

  // render

  function dropDownRender(data) {
    return (
      <DropDown data={data} showColumnHeaders={true} height={250} width={500}>
        <Column dataField="name"> </Column>
        <Column dataField="type"> </Column>
      </DropDown>
    );
  }

  const activityOverrideRender = () => {
    return (
      <Grid
        title={"Activity"}
        showTitle={false}
        dataSource={scenarioActivityStore}
        onUploaded={onUploaded}
        onExported={onExported}
        onExporting={onExporting}
      >
        <Column caption={"Id"} visible={false} dataField={"id"} sortOrder={"desc"} width={100} formItem={{ visible: false }} />
        <Column
          caption={"Operation"}
          name={"operation"}
          calculateCellValue={() => "Update"}
          width={100}
          visible={false}
          formItem={{ visible: false }}
        />
        <Column caption={"AssetId"} dataField={"node"} visible={false} allowFiltering={true} width={200} formItem={{ visible: false }} />
        <Column caption={"Strategy"} dataField={"strategy"} visible={false} allowFiltering={true} width={200} formItem={{ visible: false }} />
        <Column caption={"Name"} dataField={"name"} allowSorting={false} allowFiltering={true} allowEditing={false} formItem={{ visible: false }} />
        <Column
          caption={"Type"}
          dataField={"activityId"}
          calculateDisplayValue="type"
          allowFiltering={true}
          allowSorting={false}
          width={200}
          editCellRender={dropDownRender}
        >
          <Lookup dataSource={activityStore} valueExpr={"id"} displayExpr="type" />
        </Column>
      </Grid>
    );
  };

  return (
    <ProgressPanel name={"UPLOAD_ACTIVITYOVERRIDE"}>
      <div className={styles.main}>{activityOverrideRender()}</div>
    </ProgressPanel>
  );
}

// redux

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(
  React.memo(Component, (prev, next) => {
    return prev.scenarioNodeId === next.scenarioNodeId;
  })
);
