import React from "react";
import styles from "./scenario.module.scss";
import Form from "./form/form";
import { connect } from "react-redux";

function Component(props) {
  // render

  return (
    <div className={styles.main}>
      <Form scenarioId={props.scenarioId} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    scenarioId: state.scenario.id,
  };
};

export default connect(mapStateToProps, null)(Component);
