import React, { useEffect, useState } from "react";
import ".././../app.scss";
import { Redirect } from "react-router-dom";

function Start(props) {
  // useState

  const [state, setState] = useState(2);

  // useEffect

  useEffect(() => {
    //checkUserStatus();
    // setTimeout(() => {
    // 	setState(2);
    // 	sleep();
    // }, 1000);
    //setState(2);
    sleep();
  }, []);

  useEffect(() => {}, []);

  // functions

  async function sleep() {
    await new Promise((resolve) => setTimeout(resolve, 3000));
    setState(3);
  }

  let animationStyles = {
    animation: "fade-in 10000ms cubic-bezier(0.19, 1, 0.22, 1) forwards",
  };

  function render() {
    switch (state) {
      case 1:
        return null;
      case 2:
        return (
          <div className={"app_start_main"}>
            <div className={"app_start_logo"}>
              <img src={require("assets/icons/logogray.png")} width="75" height="48" alt="" />
            </div>
            <div className={"app_start_text"} style={animationStyles}>
              IRIS by Kayuga
            </div>
          </div>
        );
      case 3:
        return <Redirect to="/" />;
      default:
        break;
    }
  }

  return render();
}

export default Start;

// function mapsDispatchToProps(dispatch) {
// 	return {
// 		checkUserStatus : () => dispatch(actions.check())
// 	};
// }

// const mapStateToProps = (state) => {
// 	return {
// 		//isAuthenticated : state.auth.userId !== null
// 	};
// };

// export default connect(mapStateToProps, mapsDispatchToProps)(Opening);
