import React, { useRef } from "react";
import styles from "./risk-rating.module.scss";
import Grid from "components/data-grid/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";

function Component(props) {
  // stores

  const riskratingStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return exporting.current
          ? request({ url: `${url}/riskrating/download`, loadOptions: loadOptions })
          : request({ url: `${url}/riskrating`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/riskrating`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/riskrating`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/riskrating/${id}`, method: "Delete" });
      },
    }),
  };

  // hooks

  const exporting = useRef(false);

  // event handlers

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_RISKRATING", url: `${url}/riskrating/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    exporting.current = false;
  }

  // render

  function cellRender(data) {
    return (
      <svg width={18} height={18}>
        <rect width={18} height={18} fill={data.value}></rect>
      </svg>
    );
  }

  return (
    <ProgressPanel name={"UPLOAD_RISKRATING"}>
      <div className={styles.main}>
        <Grid
          title={"RiskRating"}
          showTitle={false}
          dataSource={riskratingStore}
          onUploaded={onUploaded}
          onExported={onExported}
          onExporting={onExporting}
        >
          <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
          <Column caption={"Operation"} name={"operation"} calculateCellValue={() => "Update"} visible={false} formItem={{ visible: false }} />
          <Column caption={"Name"} dataField={"name"}>
            <RequiredRule />
          </Column>
          <Column caption={"CoF"} dataField={"cof"} dataType={"number"} width={200}>
            <RequiredRule />
          </Column>
          <Column caption={"Min PoF"} dataField={"minPof"} dataType={"number"} width={200} />
          <Column caption={"Max PoF"} dataField={"maxPof"} dataType={"number"} width={200} />
          <Column caption={"Color"} dataField={"color"} width={200} cellRender={cellRender} formItem={{ editorType: "dxColorBox" }} />
        </Grid>
      </div>
    </ProgressPanel>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);
