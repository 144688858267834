import React, { useState, useEffect } from "react";
import styles from "./condition-profile.module.scss";
import Chart from "components/chart/chart";
import { url } from "config.js";
import { connect } from "react-redux";
import { currencyFormat } from "helper/global";
import {
  CommonSeriesSettings,
  Series,
  Pane,
  ValueAxis,
  ArgumentAxis,
  Legend,
  Label,
  Point,
  MinorTick,
  Format,
  Tooltip,
} from "devextreme-react/chart";
import { currency, dict } from "helper/global";
import { request } from "helper/http-client";
import { LoadIndicator } from "devextreme-react/load-indicator";

function Component(props) {
  // hooks

  const [state, setState] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        var results = await request({ url: `${url}/engine/output/condition-profile/${props.data.id}` });
        setState(results);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [props.data]);

  // event handlers

  function customizeText(e) {
    //return { text: "adnan" };
    return { text: `Year: ${e.argument}, Value: ${e.seriesName === "Condition" ? e.value.toFixed(2) : currencyFormat(e.value)}` };
  }

  // render

  function loadingRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }

  function chartRender() {
    return (
      <div className={styles.main}>
        <Chart dataSource={state}>
          <CommonSeriesSettings argumentField="year" />
          <Pane name="topPane" visible={false} />
          <Pane name="bottomPane" />
          <Legend visible={false} />
          <Tooltip enabled={true} customizeTooltip={customizeText} />

          <ArgumentAxis allowDecimals={false} minorTickCount={4} wholeRange={[props.firstYear, props.lastYear]}>
            <MinorTick visible={false} />
            <Label overlappingBehavior={"none"} wordWrap={true} textOverflow="ellipsis">
              <Format type="decimal" />
            </Label>
          </ArgumentAxis>
          <ValueAxis
            name="condition"
            inverted={true}
            minValueMargin={0}
            maxValueMargin={0}
            title={dict("Condition")}
            wholeRange={[props.minCondition, props.maxCondition]}
          />
          <ValueAxis name="cost" minValueMargin={0} maxValueMargin={0} title={`${currency()}`}></ValueAxis>

          <Series valueField="condition" pane="topPane" type="spline" name={"Condition"}>
            <Point visible={false} />
          </Series>
          <Series valueField="cost" pane="bottomPane" type="bar" name={"Cost"} axis="cost" />
        </Chart>
      </div>
    );
  }
  return state ? chartRender() : loadingRender();
}

const mapStateToProps = (state) => {
  return {
    maxCondition: state.scenario.worstCondition,
    minCondition: state.scenario.bestCondition,
    firstYear: state.scenario.firstYear,
    lastYear: state.scenario.firstYear + state.scenario.planningHorizon,
  };
};

export default connect(mapStateToProps, null)(Component);
