import React from "react";
import styles from "./condition.module.scss";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { addFilter } from "helper/global";
import { Column, Lookup } from "devextreme-react/data-grid";
import { NumericRule } from "devextreme-react/validator";

function Component(props) {
  //
  // stores

  const conditionStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/condition`, loadOptions: addFilter(loadOptions, "and", ["assetId", "=", props.data.assetId]) });
      },
      insert: (data) => {
        data.assetId = props.data.assetId;
        return request({ url: `${url}/condition`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/condition`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/condition/${id}`, method: "Delete" });
      },
    }),
  };

  const conditionTypeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/conditionType`, loadOptions: loadOptions });
      },
      byKey: function (key) {
        return request({ url: `${url}/conditionType/${key}` });
      },
    }),
  };

  // render

  return (
    <div className={styles.main}>
      <Grid title={"Conditions"} showTitle={false} dataSource={conditionStore} allowDownloading={false}>
        <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
        <Column visible={false} caption={"AssetId"} dataField={"node"} formItem={{ visible: false }} />
        <Column caption={"Type"} dataField={"typeId"} calculateDisplayValue="type" allowFiltering={true} width={175}>
          <Lookup dataSource={conditionTypeStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
        </Column>
        <Column caption={"Date"} dataField={"dateTime"} dataType={"date"} format="MM/dd/yyyy" allowFiltering={true} width={150} />

        <Column caption={"Value"} dataField={"value"} dataType={"number"} allowFiltering={true} width={150}>
          <NumericRule />
        </Column>
        <Column caption={"Notes"} dataField={"notes"} allowFiltering={true} width={400} />
      </Grid>
    </div>
  );
}

export default Component;
