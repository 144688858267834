import React, { useEffect, useState } from "react";
import styles from "./summary.module.scss";
import { request } from "helper/http-client";
import { url } from "config.js";
import Form, { GroupItem, SimpleItem, Label } from "devextreme-react/form";
import { Popup, Gallery } from "devextreme-react";
import { dict, currency } from "helper/global";

function Component(props) {
  //
  // hooks
  const [images, setImages] = useState([]);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        var results = await request({ url: `${url}/assetimage/asset/${props.data.assetId}`, params: { type: "image" } });
        setImages(results.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [props.data]);

  // event handlers

  function onDoubleClick() {
    setPopup(true);
  }

  // render

  const editorOptions = { stylingMode: "filled", height: "40px", readOnly: true };

  function galleryItemRender(data) {
    return (
      <div className={styles.gallery_box}>
        <img src={data.url} alt={""} />
      </div>
    );
  }

  function popupRender() {
    return (
      <Popup
        className={"gallery"}
        visible={popup}
        onHiding={() => setPopup(false)}
        dragEnabled={true}
        closeOnOutsideClick={false}
        showTitle={true}
        title={props.data.description}
        width={800}
        height={600}
      >
        <div className={`${styles.popup}`}>
          <Gallery
            id={styles.gallery}
            dataSource={images}
            height={475}
            noDataText={"No image to display"}
            loop={false}
            showNavButtons={true}
            showIndicator={false}
            itemRender={galleryItemRender}
          />
        </div>
      </Popup>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.form}>
        <Form formData={props.data} labelLocation={"top"} minColWidth={200} showColonAfterLabel={true} showRequiredMark={false}>
          <GroupItem colCount={1} cssClass={"theme-form"}>
            <GroupItem>
              <SimpleItem dataField="riskRating" colSpan={1} editorOptions={editorOptions}>
                <Label text={dict("Risk Rating")} />
              </SimpleItem>
              <SimpleItem dataField="conditionRating" colSpan={1} editorOptions={editorOptions}>
                <Label text={dict("ConditionRating")} />
              </SimpleItem>
              <SimpleItem
                dataField={dict("replacementCost")}
                colSpan={1}
                editorType={"dxNumberBox"}
                editorOptions={{ ...editorOptions, format: `${currency()}#,###` }}
              >
                <Label text="Replacement Cost" />
              </SimpleItem>
            </GroupItem>
          </GroupItem>
        </Form>
      </div>
      <div className={styles.gallery} onDoubleClick={onDoubleClick}>
        <div className={`${styles.gallery_box} theme-form`}>
          <Gallery
            dataSource={images}
            height={225}
            noDataText={dict("No image to display")}
            loop={false}
            showNavButtons={true}
            showIndicator={false}
            itemRender={galleryItemRender}
            onInitialized={props.onInitialized}
          />
        </div>
      </div>
      {popupRender()}
    </div>
  );
}

export default Component;
