import React, { useState } from "react";
import styles from "./image.module.scss";
import TileView from "components/tileview/tileview";

function Component(props) {
  // const

  const [render, setRender] = useState(0);

  // event handlers

  function onRefresh() {
    setRender((render) => render + 1);
  }

  // render

  return (
    <div className={styles.main}>
      <TileView id={props.data.assetId} title={props.data.description} render={render} refresh={onRefresh}></TileView>
    </div>
  );
}

export default Component;
