import React, { useState, useEffect, useRef } from "react";
import styles from "./endpoint.module.scss";
import CustomStore from "devextreme/data/custom_store";
import List from "components/list/list";
import { request } from "helper/http-client";
import Asset from "./asset/asset";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import { confirm } from "devextreme/ui/dialog";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import { withMemo } from "helper/global";

function Component(props) {
  //
  // hooks

  const endpointRef = useRef(null);

  const initialState = { id: null };
  const [endpoint, setEndpoint] = useState(initialState);
  const [run, setRun] = useState(0);

  useEffect(() => {
    if (run === 0) return;
    (async () => {
      endpointRef.current = endpoint;

      if (!(await confirm("<i>Do you really want to sync?", "Confirm"))) return;
      setEndpoint(initialState);
      props.run({ name: "SYNC_CONNECT", url: `${url}/connectendpoint/sync/${endpointRef.current.id}` });
      setEndpoint(endpointRef.current);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [run]);

  // event handlers

  function onSelectionChanged(e) {
    setEndpoint(e.selectedRowsData[0] || initialState);
  }

  async function onSync() {
    setRun((state) => state + 1);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.endpoints}>
        <EndPoint onSync={onSync} onSelectionChanged={onSelectionChanged} />{" "}
      </div>
      <div className={styles.assets}>
        <ProgressPanel name={"SYNC_CONNECT"}>{endpoint.id && <Asset endpointId={endpoint.id} />}</ProgressPanel>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    run: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);

const EndPoint = withMemo((props) => {
  // stores
  const endpointStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/connectendpoint`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/connectendpoint`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/connectendpoint`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/connectendpoint/${id}`, method: "Delete" });
      },
    }),
  };

  const aliasTypeStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/aliastype`, loadOptions: loadOptions });
      },
      byKey: function (key) {
        return request({ url: `${url}/aliastype/${key}` });
      },
    }),
  };

  // render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: "before",
      widget: "dxButton",

      options: {
        hint: "Sync",
        icon: "fad fa-sync",
        onClick: props.onSync,
      },
    });
  }

  return (
    <List
      title={"Endpoint"}
      dataSource={endpointStore}
      selection={{ mode: "single" }}
      onSelectionChanged={props.onSelectionChanged}
      allowDownloading={false}
      onToolbarPreparing={onToolbarPreparing}
    >
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column caption={"Name"} dataField={"name"}>
        <RequiredRule />
      </Column>
      <Column caption={"Description"} dataField={"description"} visible={false}></Column>
      <Column caption={"Url"} dataField={"url"} formItem={{ colSpan: 2 }} visible={false}>
        <RequiredRule />
      </Column>
      <Column caption={"UserName"} dataField={"userName"} visible={false}>
        <RequiredRule />
      </Column>
      <Column caption={"Password"} dataField={"password"} visible={false} formItem={{ editorOptions: { mode: "password" } }}>
        <RequiredRule />
      </Column>
      <Column caption={"Type"} dataField={"type"} allowFiltering={true} visible={false}>
        <Lookup dataSource={["Cityworks"]} />
        <RequiredRule />
      </Column>
      <Column caption={"Alias"} dataField={"aliasTypeId"} calculateDisplayValue="class" allowFiltering={true} visible={false}>
        <Lookup dataSource={aliasTypeStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
      </Column>
      <Column caption={"Last Updated"} dataField={"lastUpdated"} dataType={"date"} width={100} visible={false} />
      <Column visible={false} formItem={{ itemType: "empty" }} />
      <Column
        caption={"Ignore"}
        dataField={"ignore"}
        visible={false}
        allowFiltering={true}
        width={400}
        formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 150 } }}
      />
      <Column
        caption={"Notes"}
        dataField={"notes"}
        visible={false}
        allowFiltering={true}
        width={400}
        formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
      />
    </List>
  );
}, []);
