import React, { useState, useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import styles from "./output.module.scss";
import { selectedTabItem } from "helper/global";
import Fund from "./fund/fund";
import Cost from "./cost/cost";
import { withMemo } from "helper/global";
import { dict } from "helper/global";
import List from "components/list/list.js";
import { Column } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { confirm } from "devextreme/ui/dialog";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import ProgressPanel from "components/progress-panel/progress-panel";

function Component(props) {
  // tabs

  const tabItems = [
    {
      text: dict("Fund"),
      icon: "fad fa-inbox-in",
      path: "/fund",
    },
    {
      text: dict("Cost"),
      icon: "fad fa-inbox-out",
      path: "/cost",
    },
  ];

  // hooks

  const initialState = { id: null };
  const [scenario, setScenario] = useState(initialState);

  // functions

  function changeView(e) {
    props.history.push(`/klient/finance/output${e.path}`);
  }

  function onFinanceScenarioSelectionChanged(e) {
    setScenario(e.selectedRowsData[0] || initialState);
  }

  async function onRun(e) {
    if (!(await confirm("<i>Do you really want to run the selected scenario?", "Confirm"))) return;
    props.run({ name: "RUN_FINANCING", url: `${url}/tool/financing/run/${e}` });
  }

  async function onClear() {
    var result = await confirm("<i>Do you really want to delete the results?</i>", "Confirm");
    if (result) props.run({ name: "RUN_FINANCING", url: `${url}/tool/financing/clear` });
  }

  // render

  function fundRender() {
    return <Fund scenarioId={scenario.id}></Fund>;
  }

  function costRender() {
    return <Cost scenarioId={scenario.id}></Cost>;
  }

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView} />
      </div>
      <div className={styles.container1}>
        <div className={styles.scenario}>
          <FinanceScenario onSelectionChanged={onFinanceScenarioSelectionChanged} onRun={onRun} onClear={onClear} />
        </div>
        <div className={styles.container2}>
          <ProgressPanel name={"RUN_FINANCING"}>
            {scenario.id && (
              <Switch>
                <Route exact path={["/klient/finance", "/klient/finance/output"]} render={fundRender} />
                <Route exact path="/klient/finance/output/fund" render={fundRender} />
                <Route exact path="/klient/finance/output/cost" render={costRender} />
                <Redirect to="/" />
              </Switch>
            )}
          </ProgressPanel>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    run: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);

const FinanceScenario = withMemo((props) => {
  //
  //
  const financeScenarioStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/financeScenario`, loadOptions: loadOptions });
      },
    }),
  };

  // hooks

  const dataGrid = useRef(null);

  // event handlers

  function onRun(e) {
    var id = dataGrid.current.option("selectedRowKeys")[0];
    props.onRun(id);
  }

  function onInitialized(e) {
    dataGrid.current = e.component;
  }

  // render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push(
      {
        location: "before",
        widget: "dxButton",

        options: {
          hint: "Run",
          icon: "fas fa-play",
          onClick: onRun,
        },
      },
      {
        location: "before",
        widget: "dxButton",

        options: {
          hint: "Delete Results",
          icon: "fas fa-times",
          onClick: props.onClear,
        },
      }
    );
  }

  return (
    <List
      title={"Scenario"}
      dataSource={financeScenarioStore}
      selection={{ mode: "single" }}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowDownloading={false}
      allowFiltering={false}
      onSelectionChanged={props.onSelectionChanged}
      onToolbarPreparing={onToolbarPreparing}
      onInitialized={onInitialized}
    >
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column caption={"Name"} dataField={"name"} allowFiltering={true}></Column>
    </List>
  );
}, []);
