import React, { useEffect, useRef, useContext, useMemo } from "react";
import "./map.scss";
import { request } from "helper/http-client";
import { url } from "config.js";
import * as gis from "helper/gis";
import HierarchyContext from "components/hierarchy/context";
import { LoadIndicator } from "devextreme-react/load-indicator";
import styles from "./map.module.scss";
import { useImmerReducer } from "use-immer";
import { Slider } from "devextreme-react/slider";
import { connect } from "react-redux";
import { Button } from "devextreme-react/button";
import AssetGrid from "components/asset-grid/asset-grid";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Editor from "components/editor/editor";
import { Popup as DxPopup } from "devextreme-react";

function reducer(draft, action) {
  switch (action.type) {
    case "loaded":
      draft.data = action.payload;
      draft.firstYearLoaded = draft.year === draft.firstYear;
      draft.otherYearLoaded = draft.year !== draft.firstYear;
      draft.loading = false;
      return;
    case "loading":
      draft.loading = true;
      draft.firstYearLoaded = false;
      draft.otherYearLoaded = false;
      return;
    case "unload":
      draft.firstYearLoaded = false;
      draft.otherYearLoaded = false;
      return;
    case "showGrid":
      draft.data = action.payload;
      draft.showGrid = true;
      return;
    case "hideGrid":
      draft.data = null;
      draft.showGrid = false;
      if (!draft.firstYearLoaded && !draft.otherYearLoaded) {
        render(draft);
      }
      return;
    case "showEditor":
      draft.showEditor = true;
      draft.nodeId = action.payload.nodeId;
      draft.assetId = action.payload.assetId;
      return;
    case "hideEditor":
      draft.showEditor = false;
      return;
    case "year":
      draft.year = action.payload;
      draft.otherYearLoaded = false;
      draft.data = null;
      draft.showGrid = false;
      if (action.payload === draft.firstYear && !draft.firstYearLoaded) {
        render(draft);
      }
      return;
    case "escalate":
      draft.escalate = action.payload;
      draft.otherYearLoaded = false;
      //render(draft);
      return;
    case "run":
      render(draft);
      return;
    default:
      return;
  }

  function render(draft) {
    draft.render++;
    draft.loading = true;
    draft.firstYearLoaded = false;
    draft.otherYearLoaded = false;
  }
}

function Component(props) {
  //
  // constants
  const fields = [
    {
      name: "id",
      alias: "Id",
      type: "integer",
    },
    {
      name: "assetId",
      alias: "AssetId",
      type: "integer",
    },
    {
      name: "name",
      alias: "Asset Id",
      type: "string",
    },
    {
      name: "description",
      alias: "Description",
      type: "string",
    },
    {
      name: "status",
      alias: "Status",
      type: "string",
    },

    {
      name: "class",
      alias: "Class",
      type: "string",
    },
    {
      name: "type",
      alias: "Type",
      type: "string",
    },
    {
      name: "size",
      alias: "Size",
      type: "string",
    },
    {
      name: "material",
      alias: "Material",
      type: "string",
    },
    {
      name: "manufacturer",
      alias: "Manufacturer",
      type: "string",
    },
    {
      name: "model",
      alias: "Model",
      type: "string",
    },
    {
      name: "count",
      alias: "Count",
      type: "integer",
    },
    {
      name: "installYear",
      alias: "Install Year",
      type: "integer",
    },
    {
      name: "usefulLife",
      alias: "Useful Life",
      type: "integer",
    },
    {
      name: "replacementCost",
      alias: "Replacement Cost",
      type: "double",
    },
    {
      name: "consumed",
      alias: "Consumed",
      type: "double",
    },
    {
      name: "condition",
      alias: "Condition",
      type: "double",
    },
    {
      name: "pof",
      alias: "Pof",
      type: "double",
    },
    {
      name: "cof",
      alias: "Cof",
      type: "double",
    },
    {
      name: "r",
      alias: "R",
      type: "double",
    },
    {
      name: "standby",
      alias: "Standby",
      type: "double",
    },
    {
      name: "bre",
      alias: "Bre",
      type: "double",
    },
    {
      name: "gisId",
      alias: "Gis Id",
      type: "string",
    },
    {
      name: "riskRating",
      alias: "Risk Rating",
      type: "string",
    },
    {
      name: "conditionRating",
      alias: "Condition Rating",
      type: "string",
    },
    {
      name: "visible",
      alias: "Visible",
      type: "integer",
    },
  ];

  // hooks

  const initialState = {
    data: null,
    year: props.firstYear,
    loading: false,
    firstYear: props.firstYear,
    firstYearLoaded: true,
    otherYearLoaded: false,
    render: 0,
    showGrid: false,
    escalate: false,
    showEditor: false,
    nodeId: null,
    assetId: null,
  };

  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const context = useContext(HierarchyContext);
  const filter = context.filter;
  const selectedRowsData = context.selectedRowsData;

  const isFirstYear = state.year === props.firstYear;
  const isButton = !isFirstYear && !state.otherYearLoaded && !state.loading;

  const mapRef = useRef();
  const firstRender = useRef(true);

  // useEffect

  useEffect(
    function () {
      (async () => {
        if (firstRender.current) {
          let p1 = request({ url: `${url}/conditionrating` });
          let p2 = request({ url: `${url}/riskrating/lookup` });
          let [conditionRatings, breRatings] = await Promise.all([p1, p2]);

          let ratings = [
            {
              field: "conditionRating",
              ratings: conditionRatings.data,
              selected: false,
            },
            {
              field: "riskRating",
              ratings: breRatings.data,
              selected: true,
            },
          ];
          await gis.initialize(mapRef, fields, ratings, "riskRating", onSelection, onClearSelection, onClick, true);
          firstRender.current = false;
        }
        if (state.showGrid) {
          dispatch({ type: "unload" });
          return;
        }

        await getData();
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRowsData, filter]
  );

  useEffect(() => {
    return function () {
      gis.dispose();
    };
  }, []);

  // functions

  async function getData() {
    try {
      dispatch({ type: "loading" });
      gis.clear();
      let p1 = request({ url: `${url}/gislayer/node`, params: { ids: selectedRowsData.map((i) => i.id) } });
      let p2 = request({
        url: `${url}/engine/output/gis-nodes`,
        params: { ids: selectedRowsData.map((i) => i.id), year: state.year, filterExpression: filter },
      });
      let [layerInfo, data] = await Promise.all([p1, p2]);
      await gis.setLayers(layerInfo, data);
    } catch (error) {
      console.log("getData: ", error);
    } finally {
      dispatch({ type: "loaded" });
    }
  }

  // event handler

  function onSliderYearChanged(e) {
    dispatch({ type: "year", payload: e.value });
  }

  function onRunClick() {
    dispatch({ type: "run" });
  }

  function onSelection(e) {
    dispatch({ type: "showGrid", payload: e });
  }

  function onClearSelection() {
    dispatch({ type: "hideGrid" });
  }

  function onSelectionChanged(e) {
    gis.selectFeatureFromId(e.gisId);
  }

  function onEscalateChanged(e) {
    dispatch({ type: "escalate", payload: e.value });
  }

  function onClick(e) {
    console.log("%conClick", "color:red", e);
    if (e && e.id) {
      dispatch({ type: "showEditor", payload: { nodeId: e.id, assetId: e.assetId } });
    }
  }

  function hideEditor(e) {
    dispatch({ type: "hideEditor" });
  }

  // render
  function toolbarRender() {
    return (
      <div className={`theme-toolbar ${styles.toolbar}`}>
        <Toolbar>
          <Item location="after" locateInMenu="never">
            <div className={styles.toolbar_item}>Escalate:</div>
          </Item>
          <Item location="after" locateInMenu="auto" widget="dxCheckBox" options={{ value: state.escalate, onValueChanged: onEscalateChanged }} />
        </Toolbar>
      </div>
    );
  }

  function sliderRender() {
    return (
      <div className={styles.slider_container}>
        <div className={styles.slider_container_title}>Year:</div>
        <div className={styles.slider_container_slider}>
          <Slider
            min={props.firstYear}
            max={props.lastYear}
            defaultValue={state.year}
            value={state.year}
            step={1}
            showRange={true}
            onValueChanged={onSliderYearChanged}
            tooltip={{
              enabled: true,
              showMode: "always",
              position: "top",
            }}
          />
        </div>
      </div>
    );
  }

  function runButtonRender() {
    return (
      <div className={styles.overlay}>
        <Button text={"Run"} stylingMode="outlined" icon={"spinright"} type={"success"} onClick={onRunClick} />
      </div>
    );
  }

  const gridRender = (() => {
    return (
      <div className={styles.grid}>
        <AssetGrid
          title={`Selected Assets`}
          dataSource={state.data}
          editingMode={"none"}
          rowNumbering={true}
          selection={{ mode: "single" }}
          remoteOperations={false}
          onSelectionChanged={onSelectionChanged}
        />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })();

  // const Map = withMemo(() => {
  //   return (
  //     <div className={styles.map} ref={mapRef}>
  //       <div id={"gis_indicator"} className={styles.loading} hidden>
  //         <LoadIndicator />
  //       </div>
  //     </div>
  //   );
  // }, ["selectedRowsData", "filter", "render"]);

  const mapRender = useMemo(() => {
    return (
      <div className={styles.map} ref={mapRef}>
        <div id={"gis_indicator"} className={styles.loading} hidden>
          <LoadIndicator />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsData, state.render, filter]);

  return (
    selectedRowsData.length > 0 && (
      <div className={styles.main}>
        <DxPopup
          visible={state.showEditor}
          onHiding={hideEditor}
          showCloseButton={true}
          dragEnabled={true}
          closeOnOutsideClick={false}
          showTitle={true}
          title={"Asset"}
          width={700}
          height={700}
        >
          <Editor nodeId={state.nodeId} hideEditor={hideEditor} />
        </DxPopup>
        <div className={styles.header}>
          {sliderRender()}
          {!isFirstYear && toolbarRender()}
        </div>
        {isButton && runButtonRender()}
        <div className={styles.container}>
          {mapRender}
          {state.showGrid && gridRender}
        </div>
      </div>
    )
  );
}

const mapStateToProps = (state) => {
  return {
    firstYear: state.scenario.firstYear,
    lastYear: state.scenario.firstYear + state.scenario.planningHorizon,
  };
};

export default connect(mapStateToProps, null)(Component);
