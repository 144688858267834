import React from "react";
import Grid from "components/data-grid/data-grid.js";
import { currency } from "helper/global";
import { Column, FilterRow } from "devextreme-react/data-grid";

function Component(props) {
  // render

  return (
    <Grid
      dataSource={props.dataSource}
      title={props.title}
      allowEditing={false}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      onToolbarPreparing={props.onToolbarPreparing}
    >
      <FilterRow visible={true} />
      <Column caption={"AssetId"} dataField={"name"} width={200} />
      <Column caption={"Asset Description"} dataField={"description"} width={400} />
      <Column caption={"Year"} dataField={"year"} width={75} />
      <Column caption={"Activity Name"} dataField={"activityName"} width={200} />
      <Column caption={"Activity Type"} dataField={"activityType"} width={200} />
      <Column caption={"Activity Description"} dataField={"activityDescription"} width={200} visible={false} />
      <Column caption={"Activity Cost"} dataField={"activityCost"} width={200} format={`${currency()}#,###`} />
      <Column caption={"Escalated Activity Cost"} dataField={"escalatedActivityCost"} width={200} format={`${currency()}#,###`} />
      <Column caption={"CostType"} dataField={"costType"} width={150} />
      <Column caption={"Fund"} dataField={"fund"} width={150} />
      {props.showPhase && <Column caption={"Phase"} dataField={"phase"} width={150} />}
      <Column caption={"Frequency"} dataField={"frequency"} width={150} />
      <Column caption={"Strategy"} dataField={"strategy"} width={150} />
      <Column caption={"Location"} dataField={"location"} width={200} visible={false} />
      <Column caption={"Ownership"} dataField={"ownership"} width={150} />
      <Column caption={"Status"} dataField={"status"} width={150} />
      <Column caption={"Class"} dataField={"class"} width={150} />
      <Column caption={"Type"} dataField={"type"} width={150} visible={true} />
      <Column caption={"Size"} dataField={"size"} width={150} visible={true} />
      <Column caption={"Material"} dataField={"material"} width={150} visible={true} />
      <Column caption={"Manufacturer"} dataField={"manufacturer"} width={150} visible={true} />
      <Column caption={"Model"} dataField={"model"} width={150} visible={true} />
      <Column caption={"Count"} dataField={"count"} dataType="number" width={150} />
      <Column caption={"Install Year"} dataType="number" dataField={"installYear"} width={150} />
      <Column caption={"Useful Life"} dataField={"usefulLife"} dataType="number" width={150} />
      <Column caption={"Consumed"} dataField={"consumed"} dataType="number" width={150} format={"0.##"} />
      <Column caption={"Condition"} dataField={"condition"} dataType="number" width={150} format={"0.##"} />
      <Column caption={"PoF"} dataField={"pof"} dataType="number" width={150} format={"0.00"} />
      <Column caption={"CoF"} dataField={"cof"} dataType="number" width={150} format={"0.00"} />
      <Column caption={"R"} dataField={"r"} dataType="number" width={150} format={"0.##"} />
      <Column caption={"Standby"} dataField={"standby"} dataType="number" width={150} format={"0.##"} />
      <Column caption={"Bre"} dataField={"bre"} dataType="number" width={150} format={"0.##"} />
      <Column caption={"Condition Rating"} dataField={"conditionRating"} width={150} />
      <Column caption={"Risk  Rating"} dataField={"riskRating"} width={150} />
      {props.showFunded && <Column caption={"Funded"} dataField={"funded"} width={150} />}
    </Grid>
  );
}

export default Component;
