import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "helper/global";

const initialState = {
  name: null,
  avatarUrl: null,
  theme: null,
  accessOrganization: null,
  accessResource: false,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_LOAD:
      return updateObject(state, {
        ...action,
        loading: false,
        error: null,
      });
    case actionTypes.USER_UPDATE:
      return updateObject(state, {
        ...action,
        loading: false,
        error: null,
      });
    default:
      return state;
  }
};

export default reducer;

// import * as actionTypes from "../actions/actionTypes";
// import { updateObject } from "helper/global";

// const initialState = {
//   name: null,
//   avatarUrl: null,
//   theme: null,
//   accessOrganization: null,
//   accessResource: false,
//   loading: false,
//   error: null,
// };

// const reducer = (state = initialState, action) => {
//   switch (action.type) {
//     case actionTypes.USER_LOAD:
//       return updateObject(state, {
//         name: action.name,
//         avatarUrl: action.avatarUrl,
//         theme: action.theme,
//         accessOrganization: action.accessOrganization,
//         accessResource: action.accessResource,
//         loading: false,
//         error: null,
//       });
//     case actionTypes.USER_UPDATE:
//       return updateObject(state, {
//         ...state,
//         name: action.name,
//         avatarUrl: action.avatarUrl,
//         theme: action.theme,
//         loading: false,
//         error: null,
//       });
//     default:
//       return state;
//   }
// };

// export default reducer;
