import React, { useRef } from "react";
import styles from "./custom-field.module.scss";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";

function Component(props) {
  //
  // stores

  const measurementStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return exporting.current
          ? request({ url: `${url}/assetcustomfield/download`, loadOptions: loadOptions })
          : request({ url: `${url}/assetcustomfield`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/assetcustomfield`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/assetcustomfield`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/assetcustomfield/${id}`, method: "Delete" });
      },
    }),
  };

  const unitStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/unit`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/unit/${id}` });
      },
    }),
  };

  const classStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/class`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/class/${id}` });
      },
    }),
  };

  // hooks

  const exporting = useRef(false);

  // event handlers

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    exporting.current = false;
  }

  // async function onUploaded(fileId) {
  //   props.upload({ name: "UPLOAD_ASSETCUSTOMFIELD", url: `${url}/assetcustomfield/upload/file/${fileId}` });
  // }

  // render

  return (
    <ProgressPanel name={"UPLOAD_ASSETCUSTOMFIELD"}>
      <div className={styles.main}>
        <Grid
          title={"Field"}
          showTitle={false}
          dataSource={measurementStore}
          //onUploaded={onUploaded}
          allowDownloading={false}
          onExported={onExported}
          onExporting={onExporting}
        >
          <Column visible={false} dataField={"id"} sortOrder={"desc"} allowEditing={false} formItem={{ visible: false }} />
          <Column
            caption={"Operation"}
            name={"operation"}
            calculateCellValue={() => "Update"}
            width={100}
            visible={false}
            allowEditing={false}
            formItem={{ visible: false }}
          />
          <Column caption={"Name"} dataField={"name"} width={200}>
            <RequiredRule />
          </Column>
          <Column caption={"Description"} dataField={"description"} width={400} />
          <Column caption={"Class"} dataField={"classId"} calculateDisplayValue="class" width={200} formItem={{ editorOptions: { readOnly: false } }}>
            <Lookup dataSource={classStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
          </Column>
          <Column
            caption={"Unit"}
            dataField={"unitId"}
            calculateDisplayValue="unit"
            allowFiltering={true}
            width={200}
            //editCellRender={unitDropDownRender}
          >
            <Lookup dataSource={unitStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
          </Column>
          <Column
            caption={"Notes"}
            dataField={"notes"}
            allowFiltering={true}
            formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
          />
        </Grid>
      </div>
    </ProgressPanel>
  );
}

// redux

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);
