import React, { useState, useRef } from "react";
import styles from "./project.module.scss";
import CustomStore from "devextreme/data/custom_store";
import List from "components/list/list";
import { request } from "helper/http-client";
import Task from "./task/task";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import DropDown from "components/grid-dropdown/grid-dropdown";
import { withMemo } from "helper/global";

function Component(props) {
  // useState

  const initialState = { id: null };
  const [project, setProject] = useState(initialState);

  // event handlers

  function onSelectionChanged(e) {
    setProject(e.selectedRowsData[0] || initialState);
  }

  // render

  return (
    <ProgressPanel name={"UPLOAD_PROJECT"} onComplete={props.clearHome}>
      <div className={styles.main}>
        <div className={styles.project}>
          <Project onSelectionChanged={onSelectionChanged} />{" "}
        </div>
        <div className={styles.container}>{<Task projectId={project.id} />}</div>
      </div>
    </ProgressPanel>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
    clearHome: () => dispatch(actions.clearHome()),
  };
};

export default connect(null, mapDispatchToProps)(Component);

const Project = connect(
  null,
  mapDispatchToProps
)(
  withMemo((props) => {
    //
    // stores

    const projectStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          return exporting.current
            ? request({ url: `${url}/project/download`, loadOptions: loadOptions })
            : request({ url: `${url}/project`, loadOptions: loadOptions });
        },
        insert: (data) => {
          return request({ url: `${url}/project`, method: "Post", data: data });
        },
        update: (_, data) => {
          return request({ url: `${url}/project`, method: "Put", data: data });
        },
        remove: (id) => {
          return request({ url: `${url}/project/${id}`, method: "Delete" });
        },
        onModified: () => {
          props.clearHome();
        },
      }),
    };

    const unitStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          return request({ url: `${url}/unit`, loadOptions: loadOptions });
        },
        byKey: function (id) {
          return request({ url: `${url}/unit/${id}` });
        },
      }),
    };

    // hooks

    const exporting = useRef(false);

    // event handlers

    async function onUploaded(fileId) {
      props.upload({ name: "UPLOAD_PROJECT", url: `${url}/project/upload/file/${fileId}` });
    }

    function onExporting(e) {
      e.component.columnOption("id", "visible", true);
      e.component.columnOption("id", "sortOrder", "asc");
      e.component.columnOption("operation", "visible", true);
      e.component.columnOption("description", "visible", true);
      e.component.columnOption("notes", "visible", true);
      exporting.current = true;
    }

    function onExported(e) {
      e.component.columnOption("id", "visible", false);
      e.component.columnOption("id", "sortOrder", "desc");
      e.component.columnOption("operation", "visible", false);
      e.component.columnOption("description", "visible", false);
      e.component.columnOption("notes", "visible", false);
      exporting.current = false;
    }

    // render

    function unitDropDownRender(data) {
      return (
        <DropDown keyExpr={"UnitDropDown"} data={data} showColumnHeaders={true} height={250} width={500}>
          <Column dataField="quantity"> </Column>
          <Column dataField="name"> </Column>
          <Column dataField="symbol"> </Column>
        </DropDown>
      );
    }

    return (
      <List
        title={"Projects"}
        formTitle={"Project"}
        dataSource={projectStore}
        selection={{ mode: "single" }}
        onUploaded={onUploaded}
        onSelectionChanged={props.onSelectionChanged}
        onExported={onExported}
        onExporting={onExporting}
      >
        <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
        <Column
          caption={"Operation"}
          name={"operation"}
          calculateCellValue={() => "Update"}
          width={100}
          visible={false}
          formItem={{ visible: false }}
        />
        <Column caption={"Name"} dataField={"name"} />
        <Column caption={"Description"} dataField={"description"} width={200} visible={false} />
        <Column
          caption={"Unit"}
          dataField={"unitId"}
          calculateDisplayValue="unit"
          allowFiltering={true}
          width={100}
          editCellRender={unitDropDownRender}
        >
          <Lookup dataSource={unitStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
        </Column>
        <Column caption={"Value"} dataField={"value"} dataType={"number"} width={100} />
        <Column
          caption={"Notes"}
          dataField={"notes"}
          visible={false}
          allowFiltering={true}
          width={400}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
        />
      </List>
    );
  }, [])
);
