import * as actionTypes from "./actionTypes";

export const toggleMenu = () => {
  return {
    type: actionTypes.TOGGLE_MENU,
  };
};

export const changePath = (path) => {
  return {
    type: actionTypes.CHANGE_PATH,
    path: path,
  };
};
