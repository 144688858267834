/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from "react";
import styles from "./budget-profile.module.scss";
import { request } from "helper/http-client";
import { url } from "config.js";
import Chart from "components/chart/chart";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { currencyFormat, withMemo } from "helper/global";
import HierarchyContext from "components/hierarchy/context";
import {
  CommonSeriesSettings,
  Series,
  ValueAxis,
  ArgumentAxis,
  Legend,
  Label,
  MinorTick,
  Format,
  Tooltip,
  ConstantLine,
  ZoomAndPan,
} from "devextreme-react/chart";

function Component(props) {
  //
  // hooks

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  // render

  return (
    selectedRowsData.length > 0 && (
      <div className={styles.main}>
        <Profile selectedRowsData={selectedRowsData} filter={filter} budgetId={props.budgetId} onPointClick={props.onPointClick} />
      </div>
    )
  );
}

export default Component;

const Profile = withMemo(
  (props) => {
    //
    // stores

    const [data, setData] = useState(null);

    useEffect(() => {
      response();
      async function response() {
        try {
          setData(null);
          var result = await request({
            url: `${url}/tool/budgeting/${props.budgetId}/distribution-profile`,
            params: { ids: props.selectedRowsData.map((i) => i.id), filterExpression: props.filter },
          });
          setData(result);
        } catch (error) {
          console.log(error);
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.budgetId, props.selectedRowsData, props.filter]);

    function onPointClick(e) {
      var year = e.target.originalArgument;
      props.onPointClick && props.onPointClick(year);
    }

    function getColor(seriesName) {
      if (!seriesName) return;
      var color = "#909090";

      if (seriesName === "UndefinedFunded" || seriesName === "UndefinedNotFunded") color = "#909090";
      else {
        data.ratings.forEach((object) => {
          if (seriesName.includes(object.name)) color = object.color;
        });
      }

      return color;
    }

    function customizePoint(e) {
      return {
        color: e.series.name && getColor(e.series.name),
      };
    }

    function customizeText(e) {
      return { text: `Year: ${e.argument}, Value: ${currencyFormat(e.value)}` };
    }

    function markerTemplate(item) {
      var color = getColor(item.series.name);
      return (
        <svg>
          <rect x={0} y={0} width={18} height={18} fill={color}></rect>
        </svg>
      );
    }

    function customizeLegendText(e) {
      return e.seriesName.replace("Funded", " Risk");
    }

    // render

    function seriesRender() {
      var series = [];
      data.ratings.forEach((object) => {
        var funded = `${object.name}Funded`;
        var notFunded = `${object.name}NotFunded`;
        series.push(<Series key={funded} valueField={funded} name={funded} />);
        series.push(<Series key={notFunded} valueField={notFunded} name={notFunded} showInLegend={false} />);
      });
      series.push(<Series key={"UndefinedFunded"} valueField={"UndefinedFunded"} name={"UndefinedFunded"} />);
      series.push(<Series key={"UndefinedNotFunded"} valueField={"UndefinedNotFunded"} name={"UndefinedNotFunded"} showInLegend={false} />);
      return series;
    }

    return data ? (
      <Chart dataSource={data.profile} customizePoint={customizePoint} onPointClick={onPointClick}>
        <CommonSeriesSettings argumentField="year" type="stackedBar" />
        <Legend
          visible={true}
          verticalAlignment="top"
          horizontalAlignment="center"
          markerRender={markerTemplate}
          customizeText={customizeLegendText}
        />
        <Tooltip enabled={true} customizeTooltip={customizeText} />
        <ArgumentAxis name="" allowDecimals={false} minorTickCount={4}>
          <MinorTick visible={true} />
          <Label overlappingBehavior={"none"} wordWrap={true} textOverflow="ellipsis">
            <Format type="decimal" />
          </Label>
        </ArgumentAxis>
        <ValueAxis name="amount" title={""}>
          <ConstantLine width={2} value={0} />
        </ValueAxis>
        <ZoomAndPan argumentAxis="both" valueAxis="both" />
        {seriesRender()}
      </Chart>
    ) : (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  },
  ["selectedRowsData", "budgetId", "filter"]
);
