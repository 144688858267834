import React, { useState, useContext, useRef, useMemo } from "react";
import styles from "./asset-summary.module.scss";
import ConditionProfile from "./condition-profile/condition-profile";
import HierarchyContext from "components/hierarchy/context";
import Attributes from "./attributes/attributes";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import ProgressPanel from "components/progress-panel/progress-panel";
import Asset from "./asset/asset";
import { url } from "config.js";
import { confirm } from "devextreme/ui/dialog";
import { Popup } from "devextreme-react";
import Editor from "components/editor/editor";

function Component(props) {
  // hooks

  const [state, setState] = useState({ id: null, showEditor: null });
  const dataGrid = useRef(null);

  // event handlers

  function onEdit(e) {
    setState((state) => {
      return { id: e, showEditor: true };
    });
  }

  function hideEditor(e) {
    setState((state) => {
      return { ...state, showEditor: false };
    });
  }

  function refresh() {
    dataGrid.current.refresh();
  }

  function onInitialized(e) {
    dataGrid.current = e.component;
  }

  // render

  return (
    <React.Fragment>
      <Popup
        visible={state.showEditor}
        onHiding={hideEditor}
        showCloseButton={true}
        dragEnabled={true}
        closeOnOutsideClick={false}
        showTitle={true}
        title={"Asset"}
        width={700}
        height={700}
      >
        <Editor nodeId={state.id} refresh={refresh} hideEditor={hideEditor} />
      </Popup>
      <Content onEdit={onEdit} onInitialized={onInitialized} />
    </React.Fragment>
  );
}

export default Component;

const mapDispatchToProps = (dispatch) => {
  return {
    run: (options) => dispatch(actions.progress(options)),
  };
};

const Content = connect(
  null,
  mapDispatchToProps
)((props) => {
  //
  // hooks

  const context = useContext(HierarchyContext);
  const filter = context.filter;
  const selectedRowsData = context.selectedRowsData;

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  const selected = useRef(null);

  // event handlers

  function onSelectionChanged(e) {
    selected.current = e.selectedRowsData[0] || initialState;
    setState(e.selectedRowsData[0] || initialState);
  }

  async function onRun() {
    var result = await confirm("<i>Do you really want to run the selected node?</i>", "Confirm");
    if (result) props.run({ name: "RUN_NODE", url: `${url}/engine/run/${selected.current.id}` });
  }

  function onEdit(e) {
    props.onEdit && props.onEdit(selected.current && selected.current.id);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.list}>
        <Asset
          selectedRowsData={selectedRowsData}
          filter={filter}
          onSelectionChanged={onSelectionChanged}
          onRun={onRun}
          onEdit={onEdit}
          onInitialized={props.onInitialized}
        />
      </div>
      <div className={styles.progress}>
        <ProgressPanel name={"RUN_NODE"}>
          <div className={styles.container}>
            <div className={styles.container_attributes}>{state.id && <Attributes nodeId={state.id} nodeTypeId={state.nodeTypeId} />}</div>
            <div className={styles.container_chart}>{state.id && <ConditionProfile nodeId={state.id} nodeTypeId={state.nodeTypeId} />}</div>
          </div>
        </ProgressPanel>
      </div>
    </div>
  );
});
