import React, { useState, useRef } from "react";
import styles from "./fund.module.scss";
import Grid from "components/list/list.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import FundProfile from "./fund-profile/fund-profile";
import Profile from "./profile/profile";
import { withMemo } from "helper/global";

function Component(props) {
  // hooks
  const initialState = { id: null };
  const [fund, setFund] = useState(initialState);

  // event handlers

  function onFundSelectionChanged(e) {
    setFund(e.selectedRowsData[0] || initialState);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.fund}>
        <Fund onSelectionChanged={onFundSelectionChanged} />
      </div>
      <div className={styles.container}>
        <div className={styles.chart1}>{props.scenarioId && fund.id && <FundProfile scenarioId={props.scenarioId} fundId={fund.id} />}</div>
        <div className={styles.chart2}>{props.scenarioId && fund.id && <Profile scenarioId={props.scenarioId} />}</div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
    run: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);

const Fund = withMemo((props) => {
  //
  // store

  const fundStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/fund`, loadOptions: loadOptions });
      },
    }),
  };

  // render

  return (
    <Grid
      title={"Fund"}
      dataSource={fundStore}
      selection={{ mode: "single" }}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowDownloading={false}
      allowFiltering={false}
      onSelectionChanged={props.onSelectionChanged}
    >
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column caption={"Name"} dataField={"name"} allowFiltering={false}></Column>
    </Grid>
  );
}, []);
