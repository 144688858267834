import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import styles from "./tools.module.scss";
import { selectedTabItem } from "helper/global";
import Budgeting from "./budgeting/budgeting";
import Smoothing from "./smoothing/smoothing";
import { dict } from "helper/global";

function Tools(props) {
  //
  // tabs

  const tabItems = [
    {
      text: dict("Budgeting"),
      icon: "fad fa-sack-dollar",
      path: "/budgeting",
    },
    {
      text: dict("Smoothing"),
      icon: "fad fa-chart-area",
      path: "/smoothing",
    },
  ];

  // event handlers

  function changeView(e) {
    props.history.push(`/klient/analyze/tools${e.path}`);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView} />
      </div>
      {/* <div className={styles.container}>
        <Hierarchy title={"Hierarchy"} dataSource={nodeStore} allowEditing={false}>
          <Switch>
            <Route exact path={["/klient/kalculate/tools", "/klient/kalculate/tools/budgeting"]} component={Budgeting} />
            <Route exact path="/klient/kalculate/tools/budgeting" component={Budgeting} />
            <Route exact path="/klient/kalculate/tools/smoothing" component={Smoothing} />
            <Redirect to="/" />
          </Switch>
        </Hierarchy>
      </div> */}
      <div className={styles.container}>
        <Switch>
          <Route exact path={["/klient/analyze/tools", "/klient/analyze/tools/budgeting"]} component={Budgeting} />
          <Route exact path="/klient/analyze/tools/budgeting" component={Budgeting} />
          <Route exact path="/klient/analyze/tools/smoothing" component={Smoothing} />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

export default Tools;
