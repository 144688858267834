import en from "./en";
import nl from "./nl";

export default (dict) => {
  switch (dict) {
    case "nl":
      return nl;
    default:
      return en;
  }
};
