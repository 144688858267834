import { dict } from "helper/global";

export const navigation = [
  {
    text: dict("Home"),
    icon: "fad fa-home",
    path: "/home",
  },
  {
    text: dict("Catalog"),
    icon: "fad fa-book-open",
    items: [
      {
        text: "Classification",
        path: "/catalog/classification",
        icon: "fad fa-mortar-pestle",
      },
      // {
      //   text: "Manufacturers",
      //   path: "/catalog/manufacturer",
      //   icon: "fad fa-industry",
      // },
    ],
  },
  {
    text: "Data",
    icon: "fad fa-database",
    items: [
      {
        text: "Lookup",
        path: "/data/lookup",
        icon: "fad fa-caret-square-down",
      },
      {
        text: "Asset",
        path: "/data/register",
        icon: "fad fa-truck-pickup",
      },
      {
        text: "Map",
        path: "/data/gis",
        icon: "fad fa-map-marked-alt",
      },
      {
        text: "Files",
        path: "/data/file",
        icon: "fad fa-file",
      },
    ],
  },
  {
    text: "Connect",
    icon: "fad fa-link",
    path: "/connect",
  },
  {
    text: "Manage",
    icon: "fad fa-digging",
    visible: true,
    items: [
      {
        text: "Monitor",
        path: "/manage/monitor",
        icon: "fad fa-monitor-heart-rate",
      },
    ],
  },
  {
    text: "Analyze",
    icon: "fad fa-analytics",
    items: [
      {
        text: "Lookup",
        path: "/analyze/lookup",
        icon: "fad fa-caret-square-down",
      },
      {
        text: "Input",
        icon: "fad fa-keyboard",
        path: "/analyze/input",
      },
      {
        text: "Output",
        icon: "fad fa-chart-bar",
        path: "/analyze/output",
      },
      {
        text: "Tools",
        icon: "fad fa-tools",
        path: "/analyze/tools",
        visible: true,
      },
      {
        text: "Reports",
        icon: "fad fa-file-pdf",
        path: "/analyze/report",
        visible: false,
      },
      {
        text: "Options",
        path: "/analyze/option",
        icon: "fad fa-cogs",
      },
    ],
  },
  {
    text: "Finance",
    icon: "fad fa-cash-register",
    items: [
      {
        text: "Lookup",
        path: "/finance/lookup",
        icon: "fad fa-caret-square-down",
      },
      {
        text: "Input",
        icon: "fad fa-keyboard",
        path: "/finance/input",
      },
      {
        text: "Output",
        icon: "fad fa-chart-bar",
        path: "/finance/output",
      },
    ],
  },
  {
    text: "Report",
    icon: "fad fa-book-open",
    visible: true,
    items: [
      {
        text: "Query",
        path: "/report/query",
        icon: "fad fa-table",
      },
      // {
      //   text: "Dashboards",
      //   path: "/report/dashboard",
      //   icon: "fad fa-tachometer-alt",
      // },
    ],
  },
  {
    text: "Admin",
    icon: "fad fa-users-crown",
    items: [
      {
        text: "Access",
        icon: "fad fa-user-lock",
        path: "/admin/access",
      },
      {
        text: "Settings",
        icon: "fad fa-cog",
        path: "/admin/setting",
      },
    ],
  },
  // {
  //   text: "Development",
  //   icon: "fad fa-computer-classic",
  //   items: [
  //     {
  //       text: "Testing",
  //       icon: "fad fa-tachometer-alt-fastest",
  //       path: "/development/testing",
  //     },
  //   ],
  // },
];
