import React, { useEffect, useRef } from "react";
import { useImmerReducer } from "use-immer";
import TreeList from "./treelist/treelist";
import FileUploader from "components/uploader/uploader";
import HierarchyContext from "./context";
import styles from "./hierarchy.module.scss";
import { connect } from "react-redux";
import { request } from "helper/http-client";
import { commaFormat, dict } from "helper/global";
import { Popup as DxPopup } from "devextreme-react";
import Filter from "components/filter/filter";
import { url } from "config.js";

// reducer

function reducer(draft, action) {
  switch (action.type) {
    case "field":
      draft[action.fieldName] = action.payload;
      return;
    case "selected":
      draft.selectedRowsData = action.payload;
      return;
    case "filterBuilder":
      draft.filterBuilder = !draft.filterBuilder;
      return;
    case "showBuilder":
      draft.filterBuilder = true;
      return;
    case "hideBuilder":
      draft.filterBuilder = false;
      return;
    default:
      return;
  }
}

// initial state

const initialState = {
  filterBuilder: false,
  fileLoader: false,
  count: 0,
  filterExpression: null,
  selectedRowsData: null,
};

function Component(props) {
  // hooks

  const [state, disptach] = useImmerReducer(reducer, initialState);
  const treeList = useRef();
  const filterExpression = useRef(null);

  useEffect(() => {
    if (!state.selectedRowsData) return;
    if (props.countUrl) {
      count();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterExpression.current, state.selectedRowsData]);

  function count() {
    (async () => {
      //let filterUrl = props.showFilter ? `&filterExpression=${filterExpression.current}` : "";
      const options = {
        url: `${url}/node/count`,
        method: "Get",
        params: { filterExpression: props.showFilter ? filterExpression.current : null, ids: state.selectedRowsData.map((i) => i.id) },
      };
      let count = await request(options);
      disptach({ type: "field", fieldName: "count", payload: count });
    })();
  }

  // events handlers and callbacks

  function onFileLoader() {
    disptach({ type: "fileloader" });
  }

  function onUploaded(e) {
    disptach({ type: "fileUploader" });
    props.onUploaded && props.onUploaded(e);
  }

  function onFilterBuilder() {
    disptach({ type: "filterBuilder" });
  }

  function onFilterOk(e) {
    filterExpression.current = e;
    disptach({ type: "filterBuilder" });
  }

  function onSelectionChanged(e) {
    disptach({ type: "selected", payload: e.selectedRowsData });
    props.onSelectionChanged && props.onSelectionChanged(e);
  }

  function onInitialized(e) {
    treeList.current = e.component;
  }

  function refresh(e) {
    switch (e) {
      case "count":
        count();
        break;
      case "hierarchy":
        treeList.current.refresh();
        break;
      default:
        break;
    }
  }

  //render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push(
      {
        location: "after",
        widget: "dxButton",
        visible: !!props.onUploaded,
        options: {
          hint: "Upload",
          icon: "fas fa-cloud-upload",
          onClick: onFileLoader,
        },
      },
      {
        location: "after",
        widget: "dxButton",
        visible: !!props.showFilter,
        options: {
          hint: "Filter",
          icon: "filter",
          stylingMode: "contained",
          onClick: onFilterBuilder,
        },
      }
    );

    props.onToolbarPreparing && props.onToolbarPreparing(e);
  }

  return (
    <div className={styles.main}>
      <DxPopup
        visible={state.filterBuilder}
        onHiding={onFilterBuilder}
        showCloseButton={false}
        dragEnabled={true}
        closeOnOutsideClick={false}
        showTitle={true}
        title={"Filter Builder"}
        width={700}
        height={700}
      >
        <div className={styles.filter_builder}>
          <Filter fields={props.filterFields || []} filterType={props.filterType} onOk={onFilterOk}></Filter>
        </div>
      </DxPopup>
      <FileUploader fileLoaderVisible={state.fileLoader} hideFileLoader={onFileLoader} uploadUrl={props.uploadUrl} onUploaded={onUploaded} />
      <div className={styles.tree}>
        <div className={styles.row}>
          <div className={`${styles.col} theme-treelist`}>
            <TreeList
              render={state.render}
              allowEditing={props.allowEditing}
              allowDragging={props.allowDragging}
              multiSelect={props.multiSelect}
              onToolbarPreparing={onToolbarPreparing}
              onContextMenuPreparing={props.onContextMenuPreparing}
              onSelectionChanged={onSelectionChanged}
              onInitialized={onInitialized}
            />
          </div>
          {(props.showFilter || props.showCount) && (
            <div className={styles.footer}>
              <div className={styles.footer_filter}>{JSON.parse(filterExpression.current) === null ? "" : "Filter On"}</div>
              <div style={JSON.parse(filterExpression.current) && { color: "#bb86fc" }} className={styles.footer_count}>{`${dict(
                "Total Number of Assets"
              )}: ${commaFormat(state.count || 0)}`}</div>
            </div>
          )}
        </div>
      </div>
      {state.selectedRowsData && (
        <div className={styles.container}>
          <HierarchyContext.Provider
            value={{ data: state.selectedRowData, selectedRowsData: state.selectedRowsData, refresh: refresh, filter: filterExpression.current }}
          >
            {props.children}
          </HierarchyContext.Provider>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    scenarioName: state.scenario.name,
  };
};

export default connect(mapStateToProps, null)(Component);
