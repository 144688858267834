import React, { useState, useEffect, useRef } from "react";
import styles from "./editor.module.scss";
import Form from "./form/form";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import { dict, withMemo, logError } from "helper/global";
import Summary from "./summary/summary";
import Measurement from "./measurement/measurement";
import Condition from "./condition/condition";
import ConditionProfile from "./condition-profile/condition-profile";
import Field from "./field/field";
import Alias from "./alias/alias";
import Group from "./group/group";
import Tag from "./tag/tag";
import Image from "./image/image";
import Document from "./file/file";
import { request } from "helper/http-client";
import { url } from "config.js";
import { LoadIndicator } from "devextreme-react/load-indicator";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import { Button } from "devextreme-react";

function Component(props) {
  //
  // hooks
  const [data, setData] = useState(null);
  const fieldChanged = useRef(false);
  const saveData = useRef(null);

  useEffect(() => {
    if (!props.nodeId) {
      return;
    }
    setData(null);
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nodeId]);

  // event handlers

  function onFieldDataChanged(e) {
    fieldChanged.current = true;
  }

  function onUploadComplete() {
    load();
  }

  // funcitons

  async function saveEditor() {
    try {
      if (fieldChanged.current) {
        await request({ url: `${url}/scenarionode/node`, method: "Put", data: data });
        fieldChanged.current = false;
        props.refresh && props.refresh();
      }
    } catch (error) {
      logError(error);
    } finally {
      props.hideEditor();
    }
  }

  function hideEditor() {
    setData(JSON.parse(JSON.stringify(saveData.current)));
    props.hideEditor();
  }

  // functions

  function load() {
    (async () => {
      var results = await request({ url: `${url}/scenarionode/node/${props.nodeId}` });
      saveData.current = JSON.parse(JSON.stringify(results));
      setData(results);
    })();
  }

  // render

  function loadingIndicatorRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }

  function contentRender() {
    return (
      <React.Fragment>
        <Content data={data} onFieldDataChanged={onFieldDataChanged} hideEditor={hideEditor} saveEditor={saveEditor} />
      </React.Fragment>
    );
  }

  return (
    <div id="context" className={styles.main}>
      <ProgressPanel name={"EDITOR_RUN_NODE"} onComplete={onUploadComplete}>
        <div className={styles.main}>{data ? contentRender() : loadingIndicatorRender()}</div>
      </ProgressPanel>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    run: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);

const Content = withMemo(
  (props) => {
    // render

    function summaryRender() {
      return <Summary data={props.data} />;
    }

    function conditionProfileRender() {
      return <ConditionProfile data={props.data} />;
    }

    function measurementRender() {
      return <Measurement data={props.data} />;
    }

    function conditionRender() {
      return <Condition data={props.data} />;
    }

    function fieldRender() {
      return <Field data={props.data} />;
    }

    function aliasRender() {
      return <Alias data={props.data} />;
    }

    function groupRender() {
      return <Group data={props.data} />;
    }

    function tagRender() {
      return <Tag data={props.data} />;
    }

    function imageRender() {
      return <Image data={props.data} />;
    }

    function documentRender() {
      return <Document data={props.data} />;
    }

    function tabsRender() {
      return (
        <TabPanel height={"100%"} deferRendering={true} animationEnabled={true} showNavButtons={true}>
          <Item title={dict("Summary")} render={summaryRender} />
          <Item title={dict("Profile")} render={conditionProfileRender} />
          <Item title={dict("Measurement")} render={measurementRender} />
          <Item title={dict("Condition")} render={conditionRender} />
          <Item title={dict("Alias")} render={aliasRender} />
          <Item title={dict("Group")} render={groupRender} />
          <Item title={dict("Tag")} render={tagRender} />
          <Item title={dict("Field")} render={fieldRender} />
          <Item title={dict("Image")} render={imageRender} />
          <Item title={dict("File")} render={documentRender} />
        </TabPanel>
      );
    }
    return (
      <React.Fragment>
        <div className={styles.form}>
          <Form data={props.data} onFieldDataChanged={props.onFieldDataChanged} />
        </div>
        <div className={styles.tabs}>{tabsRender()}</div>
        <div className={styles.buttons}>
          <Button text={dict("Save")} type={"success"} stylingMode="text" onClick={props.saveEditor} />
          <Button className={styles.cancelbutton} text={dict("Cancel")} type={"success"} stylingMode="text" onClick={props.hideEditor} />
        </div>
      </React.Fragment>
    );
  },
  ["data"]
);
