import React, { useState } from "react";
import styles from "./profile.module.scss";
import Form, { SimpleItem, Label } from "devextreme-react/form";
import "./profile.scss";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import { Button } from "devextreme-react";
import { dict, notify } from "helper/global";

function Component(props) {
  //
  // useState

  const [data, setData] = useState({ ...props.data });

  function onSave() {
    props.saveUser(data);
    notify(dict("Profile updated successfully"), "success");
  }

  function onCancel() {
    setData(props.data);
  }

  // render

  function buttonRender() {
    return (
      <div>
        <Button text={"Save"} type={"success"} stylingMode="outlined" width={100} onClick={onSave} />
        <Button className={styles.cancelbutton} text={"Cancel"} type={"normal"} stylingMode="outlined" width={100} onClick={onCancel} />
      </div>
    );
  }

  function formRender() {
    return (
      <div>
        <div className={`${styles.form} dx-card`}>
          <div className={styles.form_fields}>
            <Form
              id={"form"}
              //ref={form}
              formData={data}
              width={600}
              showColonAfterLabel={true}
              showRequiredMark={false}
              labelLocation={"top"}
            >
              <SimpleItem dataField="name">
                <Label text={dict("Header Title")} />
              </SimpleItem>
              <SimpleItem dataField="logoUrl">
                <Label text={`${dict("Header Logo")} Url`} />
              </SimpleItem>
              <SimpleItem dataField="logoHeight">
                <Label text={dict("Header Logo Height")} />
              </SimpleItem>
              <SimpleItem dataField="bannerUrl">
                <Label text={`${dict("Banner")} Url`} />
              </SimpleItem>
              <SimpleItem dataField="bannerName">
                <Label text={dict("Banner Title")} />
              </SimpleItem>
              <SimpleItem dataField="bannerLogoUrl">
                <Label text={`${dict("Banner Logo")} Url`} />
              </SimpleItem>
              <SimpleItem dataField="bannerLogoHeight">
                <Label text={dict("Banner Logo Height")} />
              </SimpleItem>
              <SimpleItem />
              <SimpleItem render={buttonRender} />
            </Form>
          </div>
        </div>
      </div>
    );
  }
  return <div className={styles.main}>{formRender()}</div>;
}

function mapsDispatchToProps(dispatch) {
  return {
    saveUser: (data) => dispatch(actions.saveOrg(data)),
  };
}

function mapStateToProps(state) {
  return {
    data: {
      // name: state.org.name || "",
      // logoUrl: state.org.logoUrl,
      // logoHeight: state.org.logoHeight,
      // bannerUrl: state.org.bannerUrl,
      // bannerName: state.org.bannerName,
      // bannerLogoUrl: state.org.bannerLogoUrl,
      // bannerLogoHeight: state.org.bannerLogoHeight,
      ...state.org,
    },
  };
}

export default connect(mapStateToProps, mapsDispatchToProps)(Component);
