import React, { useState, useContext, useRef } from "react";
import styles from "./asset.module.scss";
import HierarchyContext from "components/hierarchy/context";
import ActivityOverride from "../activity-override/activity-override";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import Activity from "../activity/activity";
import Summary from "../summary/summary";
import { dict, currency, withMemo } from "helper/global";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid.js";

function Component(props) {
  // variables

  // useStore

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // useContext

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  // event handlers

  function onSelectionChanged(e) {
    console.log("OnSelectionChanged", "color: red", e);
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  function activityOverrideRender() {
    return state.id && <ActivityOverride scenarioNodeId={state.id} selectedRowsData={selectedRowsData} />;
  }

  function activityRender() {
    return state.id && <Activity scenarioNodeId={state.id} selectedRowsData={selectedRowsData} />;
  }

  function summaryRender() {
    return state.id && <Summary scenarioNodeId={state.id} nodeTypeId={state.nodeTypeId} />;
  }

  function detailsRender() {
    return (
      <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
        <Item title={dict("Summary")} render={summaryRender} />
        <Item title={dict("Activity")} visible={state.nodeType === 2} render={activityRender} />
        <Item title={dict("Activity Override")} render={activityOverrideRender} />
      </TabPanel>
    );
  }

  return (
    selectedRowsData.length > 0 && (
      <div className={styles.main}>
        <div className={styles.item1}>
          <Asset selectedRowsData={selectedRowsData} filter={filter} onSelectionChanged={onSelectionChanged} />
        </div>
        <div className={styles.item2}>{state && detailsRender()}</div>
      </div>
    )
  );
}

// redux

export default Component;

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

const Asset = connect(
  null,
  mapDispatchToProps
)(
  withMemo(
    (props) => {
      // variables

      var disabledForProgram = ["type", "size", "material", "manufacturer", "model", "count", "installYear", "value", "usefulLife", "installYear"];

      //
      // stores

      const assetStore = {
        store: new CustomStore({
          key: "id",
          load: (loadOptions) => {
            return exporting.current
              ? request({
                  url: `${url}/scenarionode/download`,
                  loadOptions: loadOptions,
                  params: { ids: props.selectedRowsData.map((i) => i.id), filterExpression: props.filter, types: ["Asset", "SuperAsset", "Program"] },
                })
              : request({
                  url: `${url}/scenarionode`,
                  loadOptions: loadOptions,
                  params: { ids: props.selectedRowsData.map((i) => i.id), filterExpression: props.filter, types: ["Asset", "SuperAsset", "Program"] },
                });
          },
          update: (_, data) => {
            return request({ url: `${url}/scenarionode`, method: "Put", data: data });
          },
        }),
      };

      const strategyStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/strategy`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/strategy/${id}` });
          },
        }),
      };

      const costStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/cost`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/cost/${id}` });
          },
        }),
      };

      // hooks

      const exporting = useRef(false);

      // event handlers

      function onExporting(e) {
        e.component.columnOption("id", "visible", true);
        e.component.columnOption("parent", "visible", true);
        e.component.columnOption("operation", "visible", true);
        exporting.current = true;
      }

      function onExported(e) {
        e.component.columnOption("id", "visible", false);
        e.component.columnOption("parent", "visible", false);
        e.component.columnOption("operation", "visible", false);
        exporting.current = false;
      }

      function onSelectionChanged(e) {
        props.onSelectionChanged && props.onSelectionChanged(e);
      }

      async function onUploaded(fileId) {
        props.upload({ name: "UPLOAD_SCENARIONODE", url: `${url}/scenarionode/upload/file/${fileId}` });
      }

      let nodeType = null;

      function onEditingStart(e) {
        nodeType = e.data.nodeType;
      }

      function customizeItem(e) {
        if (nodeType === "Folder") {
          if (["cof", "r", "usefulLife"].includes(e.dataField)) {
            e.visible = false;
          }
        }
      }

      function onInitNewRow(e) {
        e.data.cof = 1;
        e.data.r = 1;
      }

      function onCellPrepared(e) {
        if (e.rowType === "data" && disabledForProgram.some((i) => i === e.column.dataField) && e.data.nodeTypeId === 3) {
          e.cellElement.style.background = "#44570E";
          e.cellElement.style.color = "#292929";
        }
      }

      function onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
          if (disabledForProgram.some((i) => i === e.dataField)) {
            if (e.row.data.nodeTypeId === 3) {
              e.editorOptions.disabled = true;
            }
          }
        }
      }

      // render

      return (
        <ProgressPanel name={"UPLOAD_SCENARIONODE"}>
          <div className={styles.main}>
            <Grid
              title={"Asset"}
              dataSource={assetStore}
              selection={{ mode: "single" }}
              allowAdding={false}
              allowDeleting={false}
              onUploaded={onUploaded}
              onSelectionChanged={onSelectionChanged}
              onExported={onExported}
              onExporting={onExporting}
              onEditingStart={onEditingStart}
              onInitNewRow={onInitNewRow}
              customizeItem={customizeItem}
              onCellPrepared={onCellPrepared}
              onEditorPreparing={onEditorPreparing}
            >
              <Column caption={"Id"} visible={false} dataField={"id"} width={100} formItem={{ visible: false }} />
              <Column caption={"Operation"} name={"operation"} calculateCellValue={() => "Update"} visible={false} formItem={{ visible: false }} />
              <Column
                caption={"Parent"}
                dataField={"parent"}
                allowEditing={false}
                allowFiltering={true}
                width={200}
                visible={false}
                formItem={{ visible: false }}
              />
              <Column caption={"Node Type"} dataField={"nodeTypeId"} visible={false} formItem={{ visible: false }} />
              <Column caption={"AssetId"} dataField={"name"} allowEditing={false} allowFiltering={true} width={200} />
              <Column caption={"Description"} dataField={"description"} allowEditing={false} allowFiltering={true} width={400} />
              <Column caption={"Location"} dataField={"location"} allowEditing={false} allowFiltering={true} width={200} />
              <Column caption={"Ownership"} dataField={"ownership"} width={200} allowEditing={false} />
              <Column caption={"Class"} dataField={"class"} allowEditing={false} allowFiltering={true} width={200} />
              <Column caption={"Type"} dataField={"type"} allowEditing={false} allowFiltering={true} width={200} />
              <Column caption={"Size"} dataField={"size"} allowEditing={false} allowFiltering={true} width={200} />
              <Column caption={"Material"} dataField={"material"} allowEditing={false} allowFiltering={true} width={200} />
              <Column caption={"Manufacturer"} dataField={"manufacturer"} allowEditing={false} allowFiltering={true} width={200} />
              <Column caption={"Model"} dataField={"model"} allowEditing={false} allowFiltering={true} width={200} />
              <Column caption={"Count"} dataField={"count"} allowEditing={false} allowFiltering={true} width={200} />
              <Column caption={"Install Year"} dataField={"installYear"} allowEditing={false} allowFiltering={true} width={200} />
              <Column
                caption={"Useful Life"}
                dataField={"usefulLife"}
                dataType={"number"}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              />
              <Column caption={"Strategy"} dataField={"strategyId"} calculateDisplayValue="strategy" allowFiltering={true} width={200}>
                <Lookup dataSource={strategyStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
              </Column>

              <Column caption={"Cost"} dataField={"costId"} calculateDisplayValue="cost" allowFiltering={true} width={200}>
                <Lookup dataSource={costStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
              </Column>
              <Column
                caption={"Cost Override"}
                dataField={"costOverride"}
                dataType={"number"}
                width={100}
                format={`${currency()}#,###`}
                formItem={{ editorOptions: { format: `${currency()}#,###`, showClearButton: true } }}
                // customizeText={(e) => {
                //   if (e.value === "null") return null;
                //   console.log("EEEEEEEEE", e);
                // }}
              >
                {/* <NumericRule /> */}
              </Column>

              <Column
                caption={"Pof Override"}
                dataField={"pofOverride"}
                dataType={"number"}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              />
              <Column caption={"Cof"} dataField={"cof"} dataType={"number"} width={100} formItem={{ editorOptions: { showClearButton: true } }} />
              <Column
                caption={"R"}
                dataField={"r"}
                dataType={"number"}
                allowFiltering={true}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              />
              <Column
                caption={"Standby"}
                dataField={"standby"}
                dataType={"number"}
                allowFiltering={true}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              />
              <Column
                caption={"Last Year"}
                dataField={"lastYear"}
                dataType={"number"}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              />
              <Column caption={"Override Activity Cost"} dataField={"overrideActivityCost"} dataType={"boolean"} allowFiltering={true} width={100} />
              {/* <Column visible={false} formItem={{ itemType: "empty" }} /> */}
              <Column
                caption={"Notes"}
                dataField={"notes"}
                width={400}
                allowEditing={false}
                formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
              />
            </Grid>
          </div>
        </ProgressPanel>
      );
    },
    ["selectedRowsData", "filter"]
  )
);
