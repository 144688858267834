import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "helper/global";

const initialState = {
  menuOpened: false,
  //path: "",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_MENU:
      return updateObject(state, {
        menuOpened: !state.menuOpened,
      });
    case actionTypes.CHANGE_PATH:
      return updateObject(state, {
        path: action.path,
      });

    default:
      return state;
  }
};

export default reducer;
