export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_CHECK_START = "AUTH_CHECK_START";
export const AUTH_CHECK_SUCCESS = "AUTH_CHECK_SUCCESS";
export const AUTH_CHECK_FAIL = "AUTH_CHECK_FAIL";

export const ORG_LOAD = "ORG_LOAD";
export const ORG_SAVE = "ORG_SAVE";
export const ORG_UPDATE = "ORG_UPDATE";

export const USER_LOAD = "USER_LOAD";
export const USER_SAVE = "USER_SAVE";
export const USER_UPDATE = "USER_UPDATE";

export const PROCESS_START = "PROCESS_START";
// export const PROCESS_SUCCESS = 'PROCESS_SUCCESS';
// export const PROCESS_FAIL = 'PROCESS_FAIL';
export const PROCESS_CANCEL = "PROCESS_CANCEL";
export const PROCESS_END = "PROCESS_END";

export const PROGRESS_START = "PROGRESS_START";
export const PROGRESS_CANCEL = "PROGRESS_CANCEL";
export const PROGRESS_END = "PROGRESS_END";

export const TOGGLE_MENU = "TOGGLE_MENU";
export const CHANGE_PATH = "CHANGE_PATH";

export const SCENARIO_LOAD = "SCENARIO_LOAD";

export const HOME_LOAD = "HOME_LOAD";
export const HOME_CLEAR = "HOME_CLEAR";

export const DICTIONARY_LOAD = "DICTIONARY_LOAD";
export const DICTIONARY_SAVE = "DICTIONARY_SAVE";
export const DICTIONARY_UPDATE = "DICTIONARY_UPDATE";

export const APP_LOAD = "APP_LOAD";
