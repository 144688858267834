import React, { useRef, useEffect } from "react";
import styles from "./filter.module.scss";
import FilterBuilder, { Field, Lookup } from "devextreme-react/filter-builder";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Button } from "devextreme-react";
import { withMemo, useRefState } from "helper/global";
import List from "components/list/list";
import { Column } from "devextreme-react/data-grid";
import { loadMessages } from "devextreme/localization";

function Component(props) {
  //
  // stores
  const filterStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/filter/type/${props.filterType}`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/filter`, method: "Post", data: data });
      },
      update: (_, data1) => {
        return request({ url: `${url}/filter`, method: "Put", data: { ...data1, expression: state.current.expression } });
      },
      remove: (id) => {
        return request({ url: `${url}/filter/${id}`, method: "Delete" });
      },
      onRemoved: () => {
        setState(initialState);
        filterBuilder.current.option("value", null);
      },
    }),
  };

  // hooks
  const initialState = { id: null, expression: null };
  const [state, setState] = useRefState(initialState);
  const filterBuilder = useRef(null);
  const dataGrid = useRef(null);

  useEffect(() => {
    loadMessages({
      en: {
        "dxFilterBuilder-addCondition": "Add Expresssion",
        "dxFilterBuilder-addGroup": "Add Operator",
      },
    });
  });

  // event handlers

  function onInitialized(e) {
    dataGrid.current = e.component;
  }

  function onFilterInitialized(e) {
    filterBuilder.current = e.component;
  }

  async function onSelectionChanged(e) {
    await update();
    if (e.selectedRowsData[0]) {
      await load(e.selectedRowsData[0].id);
    }
  }

  async function onClear() {
    await update(null);
    await load(state.current.id);
  }

  async function onOk() {
    await update();
    await load(state.current.id);
    props.onOk && props.onOk(state.current.expression);
  }

  function onCancel() {
    props.onOk && props.onOk(null);
  }

  // functions

  async function load(id) {
    if (!id) return;
    //setState(initialState);
    var results = await request({ url: `${url}/filter/${id}` });
    var e = results.expression;
    filterBuilder.current.option("value", e ? JSON.parse(e) : null);
    setState(results);
  }

  async function update(expression) {
    var data = state.current;
    if (!data.id) return;
    var expArr = filterBuilder.current.getFilterExpression();
    var e = expression === undefined && expArr ? JSON.stringify(expArr) : expression;
    await request({ url: `${url}/filter`, method: "Put", data: { ...data, expression: e } });
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.list}>
        <FilterList dataSource={filterStore} filterType={props.filterType} onInitialized={onInitialized} onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.container}>
        <Filter data={state.current} fields={props.fields} onInitialized={onFilterInitialized} onOk={onOk} onCancel={onCancel} onClear={onClear} />
      </div>
    </div>
  );
}

export default Component;

const Filter = withMemo(
  (props) => {
    //
    // stores
    const classStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          return request({ url: `${url}/class`, loadOptions: loadOptions });
        },
        byKey: function (id) {
          return request({ url: `${url}/class/${id}` });
        },
      }),
    };

    const typeStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          return request({ url: `${url}/type`, loadOptions: loadOptions });
        },
        byKey: function (id) {
          return request({ url: `${url}/type/${id}` });
        },
      }),
    };

    const sizeStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          return request({ url: `${url}/size`, loadOptions: loadOptions });
        },
        byKey: function (id) {
          return request({ url: `${url}/size/${id}` });
        },
      }),
    };

    const materialStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          return request({ url: `${url}/material`, loadOptions: loadOptions });
        },
        byKey: function (id) {
          return request({ url: `${url}/material/${id}` });
        },
      }),
    };

    const manufacturerStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          return request({ url: `${url}/manufacturer`, loadOptions: loadOptions });
        },
        byKey: function (id) {
          return request({ url: `${url}/manufacturer/${id}` });
        },
      }),
    };

    const nodeStatusStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          return request({ url: `${url}/nodestatus`, loadOptions: loadOptions });
        },
        byKey: function (id) {
          return request({ url: `${url}/nodestatus/${id}` });
        },
      }),
    };

    const groupStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          return request({ url: `${url}/group`, loadOptions: loadOptions });
        },
        byKey: function (id) {
          return request({ url: `${url}/group/${id}` });
        },
      }),
    };

    const tagStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          return request({ url: `${url}/tag`, loadOptions: loadOptions });
        },
        byKey: function (id) {
          return request({ url: `${url}/tag/${id}` });
        },
      }),
    };

    const riskRatingStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          return request({ url: `${url}/riskrating/lookup`, loadOptions: loadOptions });
        },
        byKey: function (name) {
          return Promise.resolve({ name: name });
        },
      }),
    };

    const conditionRatingStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          return request({ url: `${url}/conditionrating`, loadOptions: loadOptions });
        },
        byKey: function (id) {
          return request({ url: `${url}/conditionrating/${id}` });
        },
      }),
    };

    // render

    return (
      <React.Fragment>
        <div className={styles.expression}>
          <FilterBuilder
            disabled={!props.data.id}
            //value={props.data.expression ? JSON.parse(props.data.expression) : null}
            onInitialized={props.onInitialized}
            groupOperations={["and", "or"]}
          >
            {props.fields.includes("Class") && (
              <Field dataField="Class" filterOperations={["="]}>
                <Lookup dataSource={classStore} valueExpr="id" displayExpr="name" />
              </Field>
            )}
            {props.fields.includes("Type") && (
              <Field dataField="Type" filterOperations={["="]}>
                <Lookup dataSource={typeStore} valueExpr="id" displayExpr="name" />
              </Field>
            )}
            {props.fields.includes("Size") && (
              <Field dataField="Size" filterOperations={["="]}>
                <Lookup dataSource={sizeStore} valueExpr="id" displayExpr="name" />
              </Field>
            )}
            {props.fields.includes("Material") && (
              <Field dataField="Material" filterOperations={["="]}>
                <Lookup dataSource={materialStore} valueExpr="id" displayExpr="name" />
              </Field>
            )}
            {props.fields.includes("Manufacturer") && (
              <Field dataField="Manufacturer" filterOperations={["="]}>
                <Lookup dataSource={manufacturerStore} valueExpr="id" displayExpr="name" />
              </Field>
            )}
            {props.fields.includes("Status") && (
              <Field dataField="Status" filterOperations={["="]}>
                <Lookup dataSource={nodeStatusStore} valueExpr="id" displayExpr="name" />
              </Field>
            )}
            {props.fields.includes("Group") && (
              <Field dataField="Group" filterOperations={["="]}>
                <Lookup dataSource={groupStore} valueExpr="id" displayExpr="name" />
              </Field>
            )}
            {props.fields.includes("Tag") && (
              <Field dataField="Tag" filterOperations={["="]}>
                <Lookup dataSource={tagStore} valueExpr="id" displayExpr="name" />
              </Field>
            )}
            {props.fields.includes("Condition") && (
              <Field dataField="Condition" filterOperations={["="]}>
                <Lookup dataSource={conditionRatingStore} valueExpr="id" displayExpr="name" />
              </Field>
            )}
            {props.fields.includes("Risk") && (
              <Field dataField="Risk" filterOperations={["="]}>
                <Lookup dataSource={riskRatingStore} valueExpr="name" displayExpr="name" />
              </Field>
            )}
            {props.fields.includes("CoF") && <Field dataField="CoF" dataType={"number"} filterOperations={["=", ">=", ">", "<=", "<"]}></Field>}
          </FilterBuilder>
        </div>
        <div className={styles.buttons}>
          <div className={styles.buttons_left}>
            <Button text={"Clear"} stylingMode="outlined" width={75} onClick={props.onClear} />
          </div>
          <div className={styles.buttons_right}>
            <div>
              <Button text={"Ok"} type={"success"} stylingMode="outlined" width={75} onClick={props.onOk} />
            </div>

            <div className={styles.buttons_cancel}>
              <Button text={"Cancel"} stylingMode="outlined" width={75} onClick={props.onCancel} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  },
  ["data"]
);

const FilterList = withMemo((props) => {
  // event hanlders

  function onInitNewRow(e) {
    e.data.type = props.filterType;
    e.data.expression = null;
  }

  // render

  return (
    <List
      title={""}
      dataSource={props.dataSource}
      allowDownloading={false}
      showTitle={false}
      showColumnHeaders={false}
      rowNumbering={false}
      allowFiltering={false}
      onInitNewRow={onInitNewRow}
      onInitialized={props.onInitialized}
      onSelectionChanged={props.onSelectionChanged}
    >
      <Column visible={false} dataField={"id"} formItem={{ visible: false }} />
      <Column caption={"Name"} dataField={"name"} />
      <Column caption={"Description"} dataField={"description"} visible={false} />
      <Column caption={"Expression"} dataField={"expression"} visible={false} formItem={{ visible: false }} />
      <Column caption={"Type"} dataField={"type"} visible={false} dataType={"number"} formItem={{ visible: false }} />
      <Column
        caption={"Notes"}
        dataField={"notes"}
        visible={false}
        allowFiltering={true}
        formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
      />
    </List>
  );
}, []);
