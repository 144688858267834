import React, { useState, useEffect, useRef, useMemo } from "react";
import sytles from "./tag.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import TagBox from "components/tag-box/tag-box";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import Grid from "components/data-grid/data-grid.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import DropDown from "components/grid-dropdown/grid-dropdown";
import { RequiredRule } from "devextreme-react/validator";
import * as actions from "redux/actions/index";
import { connect } from "react-redux";
import { withMemo } from "helper/global";

function Component(props) {
  //
  // useRef

  const exporting = useRef(false);

  // stores

  const nodeTagStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (exporting.current) {
          return request({
            url: `${url}/nodetag/download`,
            loadOptions: loadOptions,
            params: { ids: props.selectedHierarchyNodes.map((i) => i.id) },
          });
        } else {
          if (!props.nodeId) return Promise.resolve({ data: [] });
          return request({ url: `${url}/nodetag/node/${props.nodeId}`, loadOptions: loadOptions });
        }
      },
      insert: (data) => {
        return request({ url: `${url}/nodetag`, method: "Post", data: { ...data, nodeId: props.nodeId } });
      },
      remove: (key) => {
        return request({ url: `${url}/nodetag/tag/${key}`, method: "Delete" });
      },
      onModified: () => {
        settagBox((state) => state + 1);
      },
    }),
  };

  const tagStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/tag`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/tag/${id}` });
      },
    }),
  };

  // useState
  const [state, setState] = useState([]);
  const [tagBox, settagBox] = useState(0);
  const [grid, setGrid] = useState(0);

  useEffect(() => {
    request({ url: `${url}/nodetag/node/${props.nodeId}` })
      .then((results) => {
        setState(results.tagIds);
      })
      .catch((error) => {});
  }, [props.nodeId, tagBox]);

  // event handlers

  function onRefresh() {
    settagBox((state) => state + 1);
  }

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_NODE_TAG", url: `${url}/nodetag/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("node", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("node", "visible", false);
    exporting.current = false;
  }

  async function onValueChanged(e) {
    await request({ url: `${url}/nodetag`, method: "Put", data: { nodeId: props.nodeId, tagIds: e } });
    setGrid((state) => state + 1);
  }

  // render

  function dropDonwRender(data) {
    return (
      <DropDown data={data} showColumnHeaders={false} height={250}>
        <Column dataField="name"> </Column>
      </DropDown>
    );
  }

  const tagBoxRender = useMemo(() => {
    return (
      <div>
        <TagBox
          dataSource={tagStore}
          selectedTags={state}
          displayExpr="name"
          valueExpr="id"
          placeholder={"Click to select tags..."}
          onValueChanged={onValueChanged}
        />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagBox, state, props.nodeId]);

  const gridRender = useMemo(() => {
    return (
      <div className={sytles.main}>
        <ProgressPanel name={"UPLOAD_NODE_TAG"} onComplete={onRefresh}>
          <Grid title={"Tags"} showTitle={false} dataSource={nodeTagStore} onUploaded={onUploaded} onExported={onExported} onExporting={onExporting}>
            <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
            <Column caption={"Operation"} name={"operation"} calculateCellValue={() => "Update"} visible={false} formItem={{ visible: false }} />
            <Column
              caption={"Name"}
              dataField={"tagId"}
              calculateDisplayValue="name"
              allowFiltering={true}
              width={400}
              editCellRender={dropDonwRender}
              formItem={{ colSpan: 2 }}
            >
              <Lookup dataSource={tagStore} valueExpr={"id"} displayExpr={"name"} calculateDisplayValue={"name"} />
              <RequiredRule />
            </Column>
            <Column visible={false} formItem={{ itemType: "empty" }} />
            <Column visible={false} dataField={"node"} caption={"AssetId"} formItem={{ visible: false }} />
          </Grid>
        </ProgressPanel>
      </div>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grid, props.nodeId]);

  return (
    <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
      <Item title="Tiles" render={() => tagBoxRender} />
      <Item title="Grid" render={() => gridRender} />
    </TabPanel>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(withMemo(Component, ["selectedHierarchyNodes", "nodeId"]));
