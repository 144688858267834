import React, { useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import styles from "./output.module.scss";
import { selectedTabItem } from "helper/global";
import AssetSummary from "./asset-summary/asset-summary";
import Cost from "./cost/cost";
import Risk from "./risk/risk";
import Condition from "./condition/condition";
import Activity from "./activity/activity";
import Asset from "./asset/asset";
import Hierarchy from "components/hierarchy/hierarchy";
import { url } from "config.js";
import Map from "./map/map";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import { confirm } from "devextreme/ui/dialog";
import ProgressPanel from "components/progress-panel/progress-panel";
import Installation from "./installation/installation";
import { notify, dict } from "helper/global";

function Output(props) {
  // hooks
  const selected = useRef(null);

  // tabs

  const tabItems = [
    {
      text: dict("Summary"),
      icon: "fad fa-chart-line",
      path: "/assetsummary",
    },
    {
      text: dict("Cost"),
      icon: "fad fa-sack",
      path: "/cost",
    },
    {
      text: dict("Condition"),
      icon: "fad fa-chart-pie",
      path: "/condition",
    },
    {
      text: dict("Risk"),
      icon: "fad fa-th",
      path: "/risk",
    },
    {
      text: dict("Map"),
      icon: "fad fa-map",
      path: "/map",
    },
    {
      text: dict("Installation"),
      icon: "fad fa-digging",
      path: "/installation",
    },
    {
      text: dict("Asset"),
      icon: "fad fa-fan",
      path: "/asset",
    },
    {
      text: dict("Activity"),
      icon: "fad fa-chart-network",
      path: "/activity",
    },
  ];

  // event handlers

  function onSelectionChanged(e) {
    selected.current = e.selectedRowsData;
  }

  async function onRun() {
    var result = await confirm("<i>Do you really want to run the entire hierarchy?</i>", "Confirm");
    if (result) {
      props.clearHome();
      try {
        props.run({ name: "RUN", url: `${url}/engine/run` });
      } catch (error) {
        notify("error", error);
      }
    }
  }

  async function onNodeRun() {
    var result = await confirm("<i>Do you really want to run the selected hierarchy?</i>", "Confirm");
    if (result) {
      props.clearHome();
      try {
        props.run({ name: "RUN", url: `${url}/engine/run`, params: { ids: selected.current.map((i) => i.id) } });
      } catch (error) {
        notify("error", error);
      }
    }
  }

  async function onClear() {
    var result = await confirm("<i>Do you really want to delete the results?</i>", "Confirm");
    if (result) props.run({ name: "RUN", url: `${url}/engine/clear` });
  }

  // functions

  function changeView(e) {
    props.history.push(`/klient/analyze/output${e.path}`);
  }

  // render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push(
      {
        location: "before",
        widget: "dxButton",

        options: {
          hint: "Run",
          icon: "fas fa-play",
          onClick: onRun,
        },
      },
      {
        location: "before",
        widget: "dxButton",

        options: {
          hint: "Delete Results",
          icon: "fas fa-times",
          onClick: onClear,
        },
      }
    );
  }

  function onContextMenuPreparing(e) {
    e.items.push({
      text: "Run",
      icon: "fas fa-play",
      onItemClick: onNodeRun,
    });
  }

  function routerRender() {
    return (
      <Switch>
        <Route exact path={["/klient/analyze", "/klient/analyze/output"]} render={assetSummaryRender} />
        <Route exact path="/klient/analyze/output/assetsummary" render={assetSummaryRender} />
        <Route exact path="/klient/analyze/output/cost" render={costRender} />
        <Route exact path="/klient/analyze/output/risk" render={riskRender} />
        <Route exact path="/klient/analyze/output/condition" render={conditionRender} />
        <Route exact path="/klient/analyze/output/activity" render={activityRender} />
        <Route exact path="/klient/analyze/output/asset" render={nodeRender} />
        <Route exact path="/klient/analyze/output/map" render={mapRender} />
        <Route exact path="/klient/analyze/output/installation" render={installationRender} />
        <Redirect to="/" />
      </Switch>
    );
  }

  function assetSummaryRender() {
    return <AssetSummary></AssetSummary>;
  }

  function costRender() {
    return <Cost type={2}></Cost>;
  }

  function conditionRender() {
    return <Condition></Condition>;
  }

  function riskRender() {
    return <Risk></Risk>;
  }

  function activityRender() {
    return <Activity></Activity>;
  }

  function nodeRender() {
    return <Asset />;
  }

  function mapRender() {
    return <Map></Map>;
  }

  function installationRender() {
    return <Installation></Installation>;
  }

  function tabRender() {
    return (
      <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView}>
        {/* <Item text="Summary" icon="fad fa-chart-line" path="/assetsummary" />
        <Item text="Cost" icon="fad fa-sack" path="/cost" />
        <Item text="Condition" icon="fad fa-chart-pie" path="/condition" />
        <Item text="Risk" icon="fad fa-th" path="/risk" />
        <Item text="Map" icon="fad fa-map" path="/map" />
        <Item text="Installation" icon="fad fa-digging" path="/installation" />
        <Item text="Assets" icon="fad fa-fan" path="/asset" />
        <Item text="Activities" icon="fad fa-chart-network" path="/activity" /> */}
      </Tabs>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        {/* <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView} /> */}
        {tabRender()}
      </div>
      <div className={styles.progress}>
        <Hierarchy
          addButton={false}
          allowEditing={false}
          allowDragging={false}
          multiSelect={true}
          onSelectionChanged={onSelectionChanged}
          onToolbarPreparing={onToolbarPreparing}
          onContextMenuPreparing={onContextMenuPreparing}
          showScenarioName={true}
          countUrl={`${url}/engine/output/node-count`}
          showFilter={true}
          showCount={true}
          filterFields={["Class", "Type", "Size", "Material", "Manufacturer", "Status", "Ownership", "Group", "Tag", "Risk", "Condition", "CoF"]}
          filterType={1}
        >
          <ProgressPanel name={"RUN"}>{routerRender()}</ProgressPanel>
        </Hierarchy>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    run: (options) => dispatch(actions.progress(options)),
    clearHome: () => dispatch(actions.clearHome()),
  };
};

export default connect(null, mapDispatchToProps)(Output);
