import React, { useState, useEffect } from "react";
import styles from "./profile.module.scss";
import Form, { SimpleItem, Label, EmptyItem, ButtonItem, GroupItem } from "devextreme-react/form";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import { notify, dict } from "helper/global";
import { request } from "helper/http-client";
import { url } from "config.js";
import { RequiredRule, CompareRule } from "devextreme-react/validator";
import { confirm } from "devextreme/ui/dialog";

function Component(props) {
  //
  // const

  const avatarUrl = props.data.avatarUrl || `https://stirisprod001.blob.core.windows.net/avatars/Avatar.jpg`;

  // hooks

  const [data] = useState({ ...props.data });

  const [user, setUser] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const result = await request({ url: `${url}/user/${props.data.userId}/credentials` });
        setUser(result);
      } catch (error) {
        notify(error.message, "error");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // callbacks

  function displaySubmit(e) {
    e.preventDefault();
    try {
      props.saveUser(data);
      notify(dict("Display updated successfully"), "success");
    } catch (error) {
      notify(error.message);
    }
  }

  async function credentialsSubmit(e) {
    e.preventDefault();
    var result = await confirm(`<i>${dict("Do you really want to save the new credentials")}?</i>`, "Confirm");
    if (result) {
      try {
        await request({ url: `${url}/user/credentials`, method: "Put", data: user });
        notify(dict("Credentials updated successfully"), "success");
      } catch (error) {
        notify(error.message);
      }
    }
  }

  async function passwordSubmit(e) {
    e.preventDefault();
    var result = await confirm(`<i>${dict("Do you really want to save the new password")}?</i>`, "Confirm");
    if (result) {
      try {
        await request({ url: `${url}/user/password`, method: "Put", data: user });
        notify(dict("Password updated successfully"), "success");
      } catch (error) {
        notify(error.message);
      }
    }
  }

  function passwordComparison() {
    return user.password;
  }

  // render

  function displayRender() {
    return (
      <div className={styles.display_container}>
        <div className={styles.title}>{dict("Display")}</div>
        <form onSubmit={displaySubmit}>
          <div className={styles.display_form_container}>
            <div className={styles.display_form}>
              <Form id={"form"} formData={data} width={600} showColonAfterLabel={true} showRequiredMark={false} labelLocation={"top"}>
                <SimpleItem dataField="name">
                  <Label text={dict("Name")} />
                </SimpleItem>
                <SimpleItem dataField="avatarUrl">
                  <Label text={`${"Avatar"} Url`} />
                </SimpleItem>
                <EmptyItem />
                <ButtonItem
                  // horizontalAlignment="left"
                  buttonOptions={{ text: dict("Save"), type: "success", stylingMode: "outlined", width: 100, useSubmitBehavior: true }}
                />
              </Form>
            </div>
            <div className={styles.display_avatar}>
              <img className="image" alt="/" src={avatarUrl} />
            </div>
          </div>
        </form>
      </div>
    );
  }

  function credentialsRender() {
    return (
      <div className={styles.credential_container}>
        <div className={styles.title}>{dict("Credentials")}</div>
        <div className={styles.credential_form}>
          <form onSubmit={credentialsSubmit}>
            <Form id={"form"} formData={user} width={600} showColonAfterLabel={true} showRequiredMark={false} labelLocation={"top"}>
              <GroupItem colCount={2}>
                <SimpleItem dataField="firstName">
                  <Label text={dict("First Name")} />
                </SimpleItem>
                <SimpleItem dataField="lastName">
                  <Label text={dict("Last Name")} />
                </SimpleItem>
                <SimpleItem dataField="emailAddress">
                  <Label text={dict("Email")} />
                </SimpleItem>
                <SimpleItem dataField="role" editorOptions={{ readOnly: true }}>
                  <Label text={dict("Role")} />
                </SimpleItem>
              </GroupItem>
              <EmptyItem />
              <ButtonItem buttonOptions={{ text: dict("Save"), type: "success", stylingMode: "outlined", width: 100, useSubmitBehavior: true }} />
            </Form>
          </form>
        </div>
      </div>
    );
  }

  function passwordRender() {
    return (
      <div className={styles.password_container}>
        <div className={styles.title}>{dict("Password")}</div>
        <div className={styles.password_form}>
          <form onSubmit={passwordSubmit}>
            <Form id={"form"} formData={user} width={600} showColonAfterLabel={true} showRequiredMark={false} labelLocation={"top"}>
              <SimpleItem dataField="userName" editorOptions={{ readOnly: true }}>
                <Label text={dict("UserName")} />
              </SimpleItem>
              <GroupItem colCount={2}>
                <SimpleItem dataField="password" editorOptions={{ mode: "password" }}>
                  <Label text={dict("Password")} />
                  <RequiredRule />
                </SimpleItem>
                <SimpleItem editorType={"dxTextBox"} editorOptions={{ mode: "password" }}>
                  <Label text={dict("Confirm Password")} />
                  <CompareRule message="Password and Confirm Password do not match" comparisonTarget={passwordComparison} />
                  <RequiredRule />
                </SimpleItem>
              </GroupItem>
              <EmptyItem />
              <ButtonItem buttonOptions={{ text: dict("Save"), type: "success", stylingMode: "outlined", width: 100, useSubmitBehavior: true }} />
            </Form>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      {user && displayRender()}
      {user && credentialsRender()}
      {user && passwordRender()}
    </div>
  );
}

// redux

function mapsDispatchToProps(dispatch) {
  return {
    saveUser: (data) => dispatch(actions.saveUser(data)),
  };
}

function mapStateToProps(state) {
  return {
    data: {
      name: state.user.name || "",
      avatarUrl: state.user.avatarUrl,
      userId: state.user.id,
    },
  };
}

export default connect(mapStateToProps, mapsDispatchToProps)(Component);
