import React, { useState, useEffect, useRef } from "react";
import styles from "./budgeting.module.scss";
import List from "components/list/list";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import BudgetProfile from "./budget-profile/budget-profile";
import DeficitProfile from "./deficit-profile/deficit-profile";
import { confirm } from "devextreme/ui/dialog";
import Activity from "./activity/activity";
import * as actions from "redux/actions";
import Hierarchy from "components/hierarchy/hierarchy";
import { currency, withMemo } from "helper/global";

function Component(props) {
  // useState

  const [budgetId, setBudget] = useState(null);
  const [run, setRun] = useState(0);
  const [year, setYear] = useState(null);

  // useRef

  const budgetIdRef = useRef(null);

  // useContext

  // useEffect
  useEffect(() => {
    if (run === 0) return;
    (async () => {
      budgetIdRef.current = budgetId;

      if (!(await confirm("<i>Do you really want to run the budgeting tool?", "Confirm"))) return;
      setBudget(null);
      props.run({ name: "RUN_BUDGET_SCENARIO", url: `${url}/tool/budgeting/run/${budgetIdRef.current}` });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [run]);

  // event handlers

  function onPointClick(e) {
    setYear(e);
  }

  function onRunComplete() {
    setBudget(budgetIdRef.current);
  }

  function onRun() {
    setRun((state) => state + 1);
  }

  function onSelectionChanged(e) {
    console.log("%cSELECTED", "color:red", e);
    setBudget(e.selectedRowsData[0] && e.selectedRowsData[0].id);
  }

  function onBackButtonClick() {
    setYear(null);
  }

  const chartRender = () => {
    return (
      <div className={styles.container2}>
        <div className={styles.item1}>
          <BudgetProfile budgetId={budgetId} onPointClick={onPointClick} />
        </div>
        <div className={styles.item2}>
          <DeficitProfile budgetId={budgetId} />
        </div>
      </div>
    );
  };

  function activityRender() {
    return <Activity budgetId={budgetId} year={year} onBackButtonClick={onBackButtonClick}></Activity>;
  }

  return (
    <Hierarchy
      title={"Hierarchy"}
      allowEditing={false}
      allowDragging={false}
      multiSelect={true}
      showCount={true}
      countUrl={`${url}/engine/output/node-count`}
      showFilter={true}
      filterFields={["Class", "Type", "Size", "Material", "Manufacturer", "Status", "Ownership", "Group", "Tag", "Risk", "Condition", "CoF"]}
      filterType={1}
    >
      <div className={styles.main}>
        <div className={styles.list}>
          <Budget onSelectionChanged={onSelectionChanged} onRun={onRun} />
        </div>
        <div className={styles.container}>
          <ProgressPanel name={"RUN_BUDGET_SCENARIO"} onComplete={onRunComplete}>
            {budgetId && (year ? activityRender() : chartRender())}
          </ProgressPanel>
        </div>
      </div>
    </Hierarchy>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    run: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);

const Budget = withMemo((props) => {
  //
  // stores

  const dataSource = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/budgetscenario`, loadOptions: loadOptions });
      },
      insert: (data) => {
        console.log(data);
        return request({ url: `${url}/budgetscenario`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/budgetscenario`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/budgetscenario/${id}`, method: "Delete" });
      },
    }),
  };

  // event handlers

  function onInitNewRow(e) {
    e.data.years = 10;
  }

  // render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: "before",
      widget: "dxButton",

      options: {
        hint: "Run",
        icon: "fas fa-play",
        onClick: props.onRun,
      },
    });
  }

  return (
    <List
      title={"Budget"}
      dataSource={dataSource}
      selection={{ mode: "single" }}
      allowDownloading={false}
      onInitNewRow={onInitNewRow}
      onSelectionChanged={props.onSelectionChanged}
      onToolbarPreparing={onToolbarPreparing}
    >
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column
        caption={"Value"}
        dataField={"value"}
        dataType={"number"}
        format={`${currency()}#,##0.##`}
        formItem={{ editorOptions: { format: `${currency()}#,##0.##`, showClearButton: true } }}
      ></Column>
      <Column caption={"Years"} dataField={"years"} dataType={"number"} width={75}></Column>
    </List>
  );
}, []);
