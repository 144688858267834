import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Map from "./map/map";
import Hierarchy from "components/hierarchy/hierarchy";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";

function Component(props) {
  //
  // store
  const nodeStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/node/hierarchy`, loadOptions: loadOptions });
      },
    }),
  };

  // render

  return (
    <Hierarchy
      type={1}
      countUrl={`${url}/node/count`}
      dataSource={nodeStore}
      allowEditing={false}
      allowDragging={false}
      showCount={true}
      showFilter={true}
      filterFields={["Class", "Type", "Size", "Material", "Manufacturer", "Status", "Ownership", "Group", "Tag"]}
      filterType={0}
    >
      <Switch>
        <Route exact path={["/klient/data/gis", "/klient/data/gis/map"]} component={Map} />
        <Redirect to="/" />
      </Switch>
    </Hierarchy>
  );
}

export default Component;
