import React, { useState, useRef } from "react";
import styles from "./strategy.module.scss";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid";
import { request } from "helper/http-client";
import Activity from "../activity/activity";
import { url } from "config.js";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { Column, Lookup } from "devextreme-react/data-grid";
import { addFilter, withMemo } from "helper/global";

function Component(props) {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  return (
    <div className={styles.main}>
      <div className={styles.strategy}>
        <Strategy strategyGroupId={props.strategyGroupId} onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.container}>{<Activity strategyGroupId={props.strategyGroupId} strategyId={state.id} />}</div>
    </div>
  );
}

export default Component;

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

const Strategy = connect(
  null,
  mapDispatchToProps
)(
  withMemo(
    (props) => {
      // stores

      const strategyStore = {
        store: new CustomStore({
          key: "id",
          load: (loadOptions) => {
            if (!props.strategyGroupId) return Promise.resolve({ data: [] });
            return exporting.current
              ? request({ url: `${url}/strategy/strategygroup/${props.strategyGroupId}/download`, loadOptions: loadOptions })
              : request({ url: `${url}/strategy/strategygroup/${props.strategyGroupId}`, loadOptions: loadOptions });
          },
          insert: (data) => {
            return request({ url: `${url}/strategy`, method: "Post", data: data });
          },
          update: (_, data) => {
            return request({ url: `${url}/strategy`, method: "Put", data: data });
          },
          remove: (id) => {
            return request({ url: `${url}/strategy/${id}`, method: "Delete" });
          },
        }),
      };

      const strategyGroupStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/strategygroup`, loadOptions: addFilter(loadOptions, "and", ["internal", "=", false]) });
          },
          byKey: function (id) {
            return request({ url: `${url}/strategygroup/${id}` });
          },
        }),
      };

      // hooks

      const exporting = useRef(false);

      // event handlers

      async function onUploaded(fileId) {
        props.upload({ name: "UPLOAD_STRATEGY", url: `${url}/strategy/upload/file/${fileId}` });
      }

      function onExporting(e) {
        e.component.columnOption("id", "visible", true);
        e.component.columnOption("id", "sortOrder", "asc");
        e.component.columnOption("operation", "visible", true);
        e.component.columnOption("groupId", "visible", true);
        exporting.current = true;
      }

      function onExported(e) {
        e.component.columnOption("id", "visible", false);
        e.component.columnOption("id", "sortOrder", "desc");
        e.component.columnOption("operation", "visible", false);
        e.component.columnOption("groupId", "visible", false);
        exporting.current = false;
      }

      return (
        <ProgressPanel name={"UPLOAD_STRATEGY"}>
          <Grid
            title={"Strategy"}
            dataSource={strategyStore}
            selection={{ mode: "single" }}
            onUploaded={onUploaded}
            onSelectionChanged={props.onSelectionChanged}
            onExported={onExported}
            onExporting={onExporting}
            onInitNewRow={(e) => {
              e.data.groupId = props.strategyGroupId || null;
            }}
          >
            <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
            <Column caption={"Operation"} name={"operation"} calculateCellValue={() => "Update"} visible={false} formItem={{ visible: false }} />
            <Column caption={"Name"} dataField={"name"} width={200} />
            <Column caption={"Description"} dataField={"description"} width={400} />
            <Column caption={"Group"} dataField={"groupId"} visible={false} calculateDisplayValue="group" allowFiltering={true} width={150}>
              <Lookup dataSource={strategyGroupStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
            </Column>
            <Column
              caption={"Useful Life"}
              dataField={"usefulLife"}
              dataType={"number"}
              width={150}
              formItem={{ editorOptions: { showClearButton: true } }}
            ></Column>
            <Column
              caption={"Notes"}
              dataField={"notes"}
              allowFiltering={true}
              formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
            />
          </Grid>
        </ProgressPanel>
      );
    },
    ["strategyGroupId"]
  )
);
