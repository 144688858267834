import React, { useEffect, useState } from "react";
import styles from "./summary.module.scss";
import { request } from "helper/http-client";
import { url } from "config.js";
import Form, { GroupItem, SimpleItem, Label } from "devextreme-react/form";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { currency } from "helper/global";
import { dict } from "helper/global";

function Component(props) {
  //
  // useState
  const [state, setState] = useState(null);

  // useEffect

  useEffect(
    () => {
      if (!props.scenarioNodeId) return;
      (async () => {
        try {
          var p1 = request({ url: `${url}/scenarionode/${props.scenarioNodeId}` });
          const [data] = await Promise.all([p1]);
          setState({ data: data });
        } catch (error) {
          console.log(error);
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.scenarioNodeId]
  );

  // render

  function loadingRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }

  const editorOptions = { stylingMode: "filled", height: "40px", readOnly: true, color: "red" };

  function summaryRender() {
    return (
      <div className={styles.main}>
        <div className={styles.form}>
          <Form formData={state.data} labelLocation={"top"} minColWidth={200} showColonAfterLabel={true} showRequiredMark={false}>
            <GroupItem colCount={5} cssClass={"theme-form"}>
              <GroupItem>
                <SimpleItem dataField="name" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("AssetId")} />
                </SimpleItem>
                <SimpleItem dataField="description" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Description")} />
                </SimpleItem>
                <SimpleItem dataField="location" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Location")} />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem dataField="status" colSpan={1} editorType="dxDropDownBox" editorOptions={editorOptions}>
                  <Label text={dict("Status")} />
                </SimpleItem>
                <SimpleItem dataField="class" colSpan={1} editorType="dxDropDownBox" editorOptions={editorOptions}>
                  <Label text={dict("Class")} />
                </SimpleItem>

                <SimpleItem dataField="material" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Material")} />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem dataField="count" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Count")} />
                </SimpleItem>
                <SimpleItem dataField="installYear" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Install Year")} />
                </SimpleItem>
                <SimpleItem dataField="condition" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Condition")} />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem dataField="usefulLife" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Useful Life")} />
                </SimpleItem>
                <SimpleItem dataField="cof" colSpan={1} editorOptions={editorOptions}>
                  <Label text="CoF" />
                </SimpleItem>
                <SimpleItem dataField="r" colSpan={1} editorOptions={editorOptions}>
                  <Label text="R" />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem dataField="strategy" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Strategy Id")} />
                </SimpleItem>
                <SimpleItem dataField="cost" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Cost Id")} />
                </SimpleItem>
                <SimpleItem
                  dataField="costOverride"
                  colSpan={1}
                  editorType={"dxNumberBox"}
                  editorOptions={{ ...editorOptions, dataType: "number", format: `${currency()}#,###` }}
                >
                  <Label text={dict("Cost Override")} />
                </SimpleItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </div>
      </div>
    );
  }
  return state ? summaryRender() : loadingRender();
}

export default Component;
