import React, { useState, useEffect } from "react";
import DxTagBox from "devextreme-react/tag-box";
import "./tag-box.scss";
import { dict } from "helper/global";

function TagBox(props) {
  // hooks

  const [state, setState] = useState([]);

  useEffect(() => {
    setState(props.selectedTags);
  }, [props.selectedTags]);

  // event hanlder

  function onValueChanged(e) {
    setState(e.value);
    if (e.value.length !== e.previousValue.length) props.onValueChanged && props.onValueChanged(e.value);
  }

  return (
    <div className={"components-tagbox"}>
      <DxTagBox
        dataSource={props.dataSource}
        value={state}
        displayExpr={props.displayExpr}
        valueExpr={props.valueExpr}
        onValueChanged={onValueChanged}
        placeholder={dict(props.placeholder) || "Select..."}
        height={"100%"}
        showSelectionControls={true}
        stylingMode={"filled"}
      />
    </div>
  );
}

export default TagBox;
