import React from "react";
import CustomStore from "devextreme/data/custom_store";
import { url } from "config.js";
import { request } from "helper/http-client";
import Grid from "components/data-grid/data-grid";
import { Column } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";

function Resource(props) {
  //
  // store

  const roleStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/resource/resourcegroup/${props.id}`, loadOptions: loadOptions });
      },
      update: (_, data) => {
        return request({ url: `${url}/resource`, method: "Put", data: data });
      },
    }),
  };

  // render

  return (
    <ProgressPanel processId={UPLOAD_RESOURCE}>
      <Grid
        title={"Resource"}
        dataSource={roleStore}
        editingMode="batch"
        rowNumbering={true}
        allowDownloading={false}
        allowAdding={false}
        allowUpdating={true}
        allowDeleting={false}
      >
        <Column caption="Name" dataField="name" sortOrder={"asc"} sortIndex={1} allowEditing={false} width={400} />
        <Column caption="Description" dataField="description" allowEditing={false} />
        {/* <Column caption="Type" dataField="type" /> */}
        <Column caption="Included" dataField="included" sortOrder={"desc"} sortIndex={0} width={200} allowFiltering={false} />
      </Grid>
    </ProgressPanel>
  );
}

const UPLOAD_RESOURCE = "UPLOAD_RESOURCE";

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (url) => dispatch(actions.process(url, UPLOAD_RESOURCE)),
  };
};

export default connect(null, mapDispatchToProps)(Resource);
