import React from "react";
import PieChart from "components/piechart/piechart";
import { Series, Label, Legend, Font, Margin, Border } from "devextreme-react/chart";
import styles from "./consumption.module.scss";
import { dict } from "helper/global";

function Component(props) {
  function customizeText(e) {
    return `${e.value}%`;
  }

  function chartRender() {
    return (
      <PieChart key={"id"} dataSource={props.data} type="donut" innerRadius={0.65} palette={"Office"}>
        <Series argumentField="rating" valueField="percent">
          <Label format="fixedPoint" visible={true} position="inside" backgroundColor="none" customizeText={customizeText}>
            <Font size={11} />
          </Label>
        </Series>
        <Legend visible={true} orientation="vertical" verticalAlignment="center" horizontalAlignment="left">
          <Margin right={0} left={0}></Margin>
          <Border visible={false}></Border>
        </Legend>
      </PieChart>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.title}>{dict("Consumption")}</div>
      <div className={styles.chart}>{chartRender()}</div>
      <div className={styles.note}>% based on cost</div>
    </div>
  );

  //return chartRender();
}

export default Component;
