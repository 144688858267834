import React, { useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import Layout from "./layout/layout";
import DataLookup from "./data/lookup/lookup";
import DataRegister from "./data/register/register";
import DataGis from "./data/gis/gis";
import DataFile from "./data/file/file";
import Home from "./home/home.js";
import "./klient.scss";
import Connect from "./connect/endpoint";
import { navigation } from "./navigation";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import Query from "./report/query/query";
import Classification from "./catalog/classification/classification";
import Manufacturer from "./catalog/classification/manufacturer/manufacturer";
import KalculateLookup from "./analyze/lookup/lookup";
import KalculateInput from "./analyze/input/input";
import KalculateOption from "./analyze/option/option";
import KalculateOutput from "./analyze/output/output";
import KalculateTools from "./analyze/tools/tools";
import UserSettings from "./layout/settings/user-setting";
import AdminAccess from "./admin/access/access";
import AdminSettings from "./admin/setting/setting";
import MonitorManage from "./manage/monitor/monitor";
import Ticket from "klient/ticket/ticket";
import { dict } from "helper/global";
import FinanceLookup from "./finance/lookup/lookup";
import FinanceInput from "./finance/input/input";
import FinanceOutput from "./finance/output/output";
import Apps from "./apps/apps";

function Klient(props) {
  // constants

  const accessVisible =
    props.user.accessUser || props.user.accessRole || props.user.accessResourceGroup || props.user.accessResource || props.user.accessOrganization;

  const settingsVisible = props.user.accessOrganizationSetting;
  // functions

  function setNavigation() {
    const nav = JSON.parse(JSON.stringify(navigation));

    let admin = nav.find((i) => i.text === "Admin");
    admin.visible = accessVisible || settingsVisible;
    let accessControl = admin.items.find((i) => i.text === "Access");
    let settings = admin.items.find((i) => i.text === "Settings");
    accessControl.visible = accessVisible;
    settings.visible = settingsVisible;
    // let development = nav.find((i) => i.text === "Development");
    // development.visible = process.env.REACT_APP_CUSTOM_NODE_ENV === "Development";
    if (props.app.length > 0) {
      var items = props.app.map((i) => {
        return { text: i.description, path: `/app/${i.name}`, icon: i.icon };
      });
      nav.push({
        text: "Apps",
        icon: "fad fa-rocket-launch",
        items: items,
      });
    }
    nav.forEach((i) => {
      i.text = dict(i.text);
      if (i.items) i.items.forEach((j) => (j.text = dict(j.text)));
    });
    return nav;
  }

  // use Effect
  let location = useLocation();

  useEffect(() => {
    props.changePath(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // render

  return <MemorizedLayout setNavigation={setNavigation} />;
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePath: (path) => dispatch(actions.changePath(path)),
  };
};

function mapStateToProps(state) {
  return {
    user: state.user,
    app: state.app,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Klient);

const MemorizedLayout = React.memo(
  (props) => {
    return (
      <Layout navigation={props.setNavigation()}>
        <Switch>
          <Route exact path={["/", "/klient", "/klient/home"]} component={Home} />
          <Route path={`/klient/data/register`} component={DataRegister} />
          <Route exact path={["/", "/klient", "/klient/home"]} component={Home} />
          <Route exact path={"/klient/catalog"} component={Classification} />
          <Route path={"/klient/catalog/classification"} component={Classification} />
          <Route path={"/klient/catalog/manufacturer"} component={Manufacturer} />
          <Route exact path={"/klient/data"} component={DataLookup} />
          <Route path={`/klient/data/lookup`} component={DataLookup} />
          <Route path={`/klient/data/register`} component={DataRegister} />
          <Route path={`/klient/data/file`} component={DataFile} />
          <Route path={`/klient/data/gis`} component={DataGis} />
          <Route exact path={"/klient/analyze"} component={KalculateLookup} />
          <Route path={"/klient/analyze/lookup"} component={KalculateLookup} />
          <Route path={"/klient/analyze/input"} component={KalculateInput} />
          <Route path={"/klient/analyze/option"} component={KalculateOption} />
          <Route path={"/klient/analyze/output"} component={KalculateOutput} />
          <Route path={"/klient/analyze/tools"} component={KalculateTools} />

          <Route exact path={"/klient/finance"} component={FinanceLookup} />
          <Route path={"/klient/finance/lookup"} component={FinanceLookup} />
          <Route path={"/klient/finance/input"} component={FinanceInput} />
          <Route path={"/klient/finance/output"} component={FinanceOutput} />

          <Route exact path={"/klient/admin"} component={AdminAccess} />
          <Route path={"/klient/admin/access"} component={AdminAccess} />
          <Route path={"/klient/admin/setting"} component={AdminSettings} />

          <Route path={"/klient/user/setting"} component={UserSettings} />

          <Route path={"/klient/manage/monitor"} component={MonitorManage} />

          <Route exact path={"/klient/report"} component={Query} />
          <Route path={"/klient/report/query"} component={Query} />

          <Route path={"/klient/report/dashboard"} component={Home} />
          <Route path={"/klient/connect"} component={Connect} />

          <Route path={"/klient/app"} component={Apps} />

          <Route path={"/klient/ticket"} component={Ticket} />

          <Redirect to="/home" />
        </Switch>
      </Layout>
    );
  },
  () => {
    return true;
  }
);
