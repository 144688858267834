import React, { useContext, useEffect } from "react";
import styles from "./risk.module.scss";
import AssetList from "components/asset-grid/asset-grid";
import Matrix from "./matrix/matrix";
import Dist from "./dist/dist";
import Toolbar, { Item } from "devextreme-react/toolbar";
import HierarchyContext from "components/hierarchy/context";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { Slider } from "devextreme-react/slider";
import { connect } from "react-redux";
import { useImmerReducer } from "use-immer";
import { url } from "config.js";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Button } from "devextreme-react/button";
import { dict } from "helper/global";

function reducer(draft, action) {
  switch (action.type) {
    case "data":
      draft.data = action.payload;
      draft.firstYearLoaded = draft.year === draft.firstYear;
      draft.otherYearLoaded = draft.year !== draft.firstYear;
      draft.loading = false;
      return;
    case "loading":
      draft.loading = true;
      draft.firstYearLoaded = false;
      draft.otherYearLoaded = false;
      return;
    case "unload":
      draft.firstYearLoaded = false;
      draft.otherYearLoaded = false;
      return;
    case "toolbar":
      draft.toolbar = action.payload;
      return;
    case "showGrid":
      draft.showGrid = true;
      draft.gridParams = action.payload;
      return;
    case "hideGrid":
      draft.rating = null;
      draft.showGrid = false;
      if (!draft.firstYearLoaded && !draft.otherYearLoaded) {
        render(draft);
      }
      return;
    case "year":
      draft.year = action.payload;
      draft.otherYearLoaded = false;
      if (action.payload === draft.firstYear && !draft.firstYearLoaded) {
        render(draft);
      }
      return;
    case "escalate":
      draft.escalate = action.payload;
      draft.otherYearLoaded = false;
      //render(draft);
      return;
    case "run":
      render(draft);
      return;
    case "url":
      draft.url = action.payload;
      render();
      return;
    default:
      return;
  }

  function render(draft) {
    draft.render++;
    draft.loading = true;
    draft.firstYearLoaded = false;
    draft.otherYearLoaded = false;
  }
}

function Component(props) {
  //
  // store

  const nodeStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: state.gridParams.url,
          params: {
            ids: selectedRowsData.map((i) => i.id),
            ...state.gridParams.params,
            year: state.year,
            filterExpression: filter,
            escalate: state.escalate,
          },
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // vars

  const items = [
    { id: 0, name: dict("Both"), fieldValue: "Both" },
    { id: 1, name: dict("Count"), fieldValue: "Count" },
    { id: 2, name: dict("Cost"), fieldValue: "Cost" },
  ];

  const initialState = {
    data: null,
    toolbar: items[0],
    year: props.firstYear,
    loading: false,
    firstYear: props.firstYear,
    firstYearLoaded: true,
    otherYearLoaded: false,
    render: 0,
    escalate: false,
    showGrid: false,
    gridParams: null,
    gridTitle: null,
  };

  // hooks

  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  useEffect(() => {
    if (state.showGrid) {
      dispatch({ type: "unload" });
      return;
    }
    if (selectedRowsData.length === 0) {
      dispatch({ type: "data", payload: null });
      return;
    }

    dispatch({ type: "loading" });
    (async () => {
      var p1 = request({
        url: `${url}/engine/output/risk-matrix`,
        params: {
          ids: selectedRowsData.map((i) => i.id),
          year: state.year,
          escalate: state.escalate,
          filterExpression: filter,
        },
      });
      var p2 = request({
        url: `${url}/engine/output/risk-distribution`,
        params: {
          ids: selectedRowsData.map((i) => i.id),
          year: state.year,
          escalate: state.escalate,
          filterExpression: filter,
        },
      });
      const [r1, r2] = await Promise.all([p1, p2]);
      dispatch({ type: "data", payload: { matrix: r1, dist: r2 } });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsData, filter, state.render]);

  // vars

  var isFirstYear = state.year === state.firstYear;
  var isButton = !isFirstYear && !state.otherYearLoaded && !state.loading;
  var isEscalate = !isFirstYear && (state.toolbar.fieldValue === "Cost" || state.toolbar.fieldValue === "Both");

  // event handlers

  function onRunClick() {
    dispatch({ type: "run" });
  }

  function onEscalateChanged(e) {
    dispatch({ type: "escalate", payload: e.value });
  }

  function onBoxClick(e) {
    dispatch({ type: "showGrid", payload: e });
  }

  function onBackButtonClick() {
    dispatch({ type: "hideGrid" });
  }

  function onToolbarValueChanged(e) {
    dispatch({ type: "toolbar", payload: items.find((i) => i.id === e.value) });
  }

  function onSliderYearChanged(e) {
    dispatch({ type: "year", payload: e.value });
  }

  // render

  function toolbarRender() {
    return (
      <div className={`${isEscalate ? styles.toolbar_expanded : styles.toolbar} theme-toolbar`}>
        <Toolbar>
          <Item location="after" locateInMenu="never">
            <div className={styles.item}>{`${dict("Show")}:`}</div>
          </Item>
          <Item
            location="after"
            locateInMenu="auto"
            widget="dxSelectBox"
            options={{
              valueExpr: "id",
              displayExpr: "name",
              value: state.toolbar.id,
              items: items,
              onValueChanged: onToolbarValueChanged,
            }}
          />
          <Item location="after" locateInMenu="never" visible={isEscalate}>
            <div className={styles.toolbar_item}>Escalate:</div>
          </Item>
          <Item
            location="after"
            locateInMenu="auto"
            widget="dxCheckBox"
            visible={isEscalate}
            options={{ value: state.escalate, onValueChanged: onEscalateChanged }}
          />
        </Toolbar>
      </div>
    );
  }

  function sliderRender() {
    return (
      <div className={styles.slider_container}>
        <div className={styles.slider_container_title}>{`${dict("Year")}:`}</div>
        <div className={styles.slider_container_slider}>
          <Slider
            min={props.firstYear}
            max={props.lastYear}
            defaultValue={state.year}
            value={state.year}
            step={1}
            showRange={true}
            onValueChanged={onSliderYearChanged}
            tooltip={{
              enabled: true,
              showMode: "always",
              position: "top",
            }}
          />
        </div>
      </div>
    );
  }

  function assetRender() {
    return <AssetList dataSource={nodeStore} title={state.gridParams.title} onBackButtonClick={onBackButtonClick} />;
  }

  function riskRender() {
    return (
      <>
        <div className={styles.risk}>
          <Matrix
            header={state.data.matrix.header}
            data={state.data.matrix.data}
            selectedRowsData={selectedRowsData}
            onBoxClick={onBoxClick}
            year={state.year}
            escalate={state.escalate}
            firstYear={props.firstYear}
            type={state.toolbar.fieldValue}
            filter={filter}
          />
        </div>
        <div className={styles.dist}>
          <Dist
            data={state.data.dist}
            selectedRowsData={selectedRowsData}
            year={state.year}
            firstYear={props.firstYear}
            escalate={state.escalate}
            onBoxClick={onBoxClick}
            filter={filter}
          />
        </div>
      </>
    );
  }

  function loadingRender() {
    return (
      <div className={styles.overlay}>
        <LoadIndicator />
      </div>
    );
  }

  function runButtonRender() {
    return (
      <div className={styles.overlay}>
        <Button text={"Run"} stylingMode="outlined" icon={"spinright"} type={"success"} onClick={onRunClick} />
      </div>
    );
  }

  if (selectedRowsData.length === 0) return null;

  return state.showGrid ? (
    assetRender()
  ) : (
    <div className={styles.main}>
      <div className={`${styles.header}`}>
        {sliderRender()}
        {toolbarRender()}
      </div>
      {isButton && runButtonRender()}
      {state.loading && loadingRender()}
      {state.data && riskRender()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    firstYear: state.scenario.firstYear,
    lastYear: state.scenario.firstYear + state.scenario.planningHorizon,
  };
};

export default connect(mapStateToProps, null)(Component);
