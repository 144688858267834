import React, { useState, useRef } from "react";
import Grid from "components/data-grid/data-grid.js";
import List from "components/list/list";
import styles from "./group.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { withMemo, addFilter } from "helper/global";

function Component(props) {
  //
  // stores

  const groupStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!state.id) return Promise.resolve([]);
        return exporting.current
          ? request({
              url: `${url}/group/group/${state.id}/download`,
              loadOptions: loadOptions,
            })
          : request({
              url: `${url}/group/group/${state.id}`,
              loadOptions: loadOptions,
            });
      },
      insert: (data) => {
        return request({ url: `${url}/group`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/group`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/group/${id}`, method: "Delete" });
      },
      byKey: function (id) {
        return request({ url: `${url}/group/${id}` });
      },
    }),
  };

  const groupLookupStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/group/hierarchy`, loadOptions: addFilter(loadOptions, "and", ["internal", "=", false]) });
      },
      byKey: function (id) {
        return request({ url: `${url}/group/${id}` });
      },
    }),
  };

  // hooks
  const initialState = { id: null };
  const [state, setState] = useState(initialState);
  const exporting = useRef(false);

  // event handlers

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_GROUP", url: `${url}/group/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("parentId", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("parentId", "visible", false);
    exporting.current = false;
  }

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  function groupRender() {
    return (
      <Grid
        title={"Group"}
        dataSource={groupStore}
        onUploaded={onUploaded}
        onExported={onExported}
        onExporting={onExporting}
        onInitNewRow={(e) => {
          e.data.parentId = state.id || null;
        }}
      >
        <Column visible={false} dataField={"id"} sortOrder={"desc"} allowEditing={false} formItem={{ visible: false }} />
        <Column
          caption={"Operation"}
          name={"operation"}
          calculateCellValue={() => "Update"}
          width={100}
          visible={false}
          allowEditing={false}
          formItem={{ visible: false }}
        />
        <Column caption={"Name"} dataField={"name"} allowFiltering={true} width={400}>
          <RequiredRule />
        </Column>
        <Column caption={"Description"} dataField={"description"} allowFiltering={true} width={400} />
        <Column caption={"Parent"} dataField={"parentId"} calculateDisplayValue="parent" width={200} visible={false}>
          <Lookup dataSource={groupLookupStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
        </Column>
        <Column visible={false} formItem={{ itemType: "empty" }} />
        <Column
          caption={"Notes"}
          dataField={"notes"}
          allowFiltering={true}
          // /width={400}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
        />
      </Grid>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.category}>
        <Hierarchy onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.group}>
        <ProgressPanel name={"UPLOAD_GROUP"}>{groupRender()}</ProgressPanel>
      </div>
    </div>
  );
}

// redux

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);

const Hierarchy = withMemo((props) => {
  const groupHierarchyStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/group/hierarchy`,
          loadOptions: loadOptions,
        });
      },
      insert: (data) => {
        return request({ url: `${url}/group/hierarchy`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/group`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/group/${id}`, method: "Delete" });
      },
    }),
  };

  return (
    <List
      title={"Category"}
      selection={{ mode: "single" }}
      dataSource={groupHierarchyStore}
      allowDownloading={false}
      onSelectionChanged={props.onSelectionChanged}
    >
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column caption={"Name"} dataField={"name"} sortOrder={"asc"} sortIndex={1} />
      <Column caption={"Description"} dataField={"description"} visible={false} allowFiltering={true} width={400} />
      <Column caption={"Internal"} dataField={"internal"} sortOrder={"desc"} sortIndex={0} visible={false} formItem={{ visible: false }} />
      <Column
        caption={"Notes"}
        dataField={"notes"}
        allowFiltering={true}
        visible={false}
        formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
      />
    </List>
  );
}, []);
