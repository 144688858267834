import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./asset.module.scss";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { dict, addFilter, withMemo } from "helper/global";
import ProgressPanel from "components/progress-panel/progress-panel";
import HierarchyContext from "components/hierarchy/context";
import Measurement from "../measurement/measurement";
import Condition from "../condition/condition";
import Group from "../group/group";
import Tag from "../tag/tag";
import Alias from "../alias/alias";
import Image from "../image/image";
import Document from "../file/file";
import Summary from "../summary/summary";
import Field from "../field/field";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid.js";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule, RangeRule, NumericRule } from "devextreme-react/validator";

function Component(props) {
  //
  // hooks

  const initialState = { id: null, nodeId: null, selectedRowsData: null, description: null, nodeselectedRowsData: null, type: null };
  const [state, setState] = useState(initialState);

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;
  const refresh = context.refresh;

  function onSelectionChanged(e) {
    let selected = e.selectedRowsData[0];
    setState(selected ? { id: selected.assetId, description: selected.description, nodeId: selected.id, type: selected.nodeTypeId } : initialState);
  }

  function onUploadComplete() {
    refresh("hierarchy");
  }

  // render

  function summaryRender() {
    return state.id && <Summary assetId={state.id} nodeId={state.nodeId} />;
  }

  function measurementRender() {
    return state.id && <Measurement assetId={state.id} selectedHierarchyNodes={selectedRowsData} />;
  }

  function conditionRender() {
    return state.id && <Condition assetId={state.id} selectedHierarchyNodes={selectedRowsData} />;
  }

  function aliasRender() {
    return state.nodeId && <Alias nodeId={state.nodeId} selectedHierarchyNodes={selectedRowsData} />;
  }

  function groupRender() {
    return state.nodeId && <Group nodeId={state.nodeId} selectedHierarchyNodes={selectedRowsData} />;
  }

  function tagRender() {
    return state.nodeId && <Tag nodeId={state.nodeId} selectedHierarchyNodes={selectedRowsData} />;
  }

  function imageRender() {
    return state.id && <Image assetId={state.id} assetDescription={state.description} selectedHierarchyNodes={selectedRowsData} />;
  }

  function documentRender() {
    return state.id && <Document assetId={state.id} assetDescription={state.description} selectedHierarchyNodes={selectedRowsData} />;
  }

  function fieldRender() {
    return state.id && <Field assetId={state.id} assetDescription={state.description} selectedHierarchyNodes={selectedRowsData} />;
  }

  function detailsRender() {
    return (
      <TabPanel
        height={"100%"}
        //visible={tabVisible}
        //onOptionChanged={onSelectedTabChanged}
        deferRendering={true}
        animationEnabled={true}
        //selectedIndex={tabIndex}
      >
        {state.type !== 3 && <Item title={dict("Summary")} render={summaryRender} />}
        {state.type !== 3 && <Item title={dict("Measurement")} render={measurementRender} />}
        {state.type !== 3 && <Item title={dict("Condition")} render={conditionRender} />}
        <Item title={dict("Alias")} render={aliasRender} />
        <Item title={dict("Group")} render={groupRender} />
        <Item title={dict("Tag")} render={tagRender} />
        {state.type !== 3 && <Item title={dict("Field")} render={fieldRender} visible={state.type !== 3} />}
        {state.type !== 3 && <Item title={dict("Image")} render={imageRender} visible={state.type !== 3} />}
        {state.type !== 3 && <Item title={dict("File")} render={documentRender} visible={state.type !== 3} />}
      </TabPanel>
    );
  }

  return (
    selectedRowsData.length > 0 && (
      <ProgressPanel name={"UPLOAD_NODES"} onComplete={onUploadComplete}>
        <div className={styles.main}>
          <div className={`${styles.item1} klient-data-register-asset`}>
            <Asset selectedRowsData={selectedRowsData} filter={filter} refresh={refresh} onSelectionChanged={onSelectionChanged} />
          </div>
          <div className={styles.item2}>{detailsRender()}</div>
        </div>
      </ProgressPanel>
    )
  );
}

export default Component;

// redux

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

const Asset = connect(
  null,
  mapDispatchToProps
)(
  withMemo(
    (props) => {
      //
      // variables

      let nodeTypes = [
        { id: 0, name: "Asset" },
        { id: 1, name: "Folder" },
        { id: 2, name: "SuperAsset" },
        { id: 3, name: "Program" },
      ];

      let installationStatus = [
        { id: 0, name: "Installed" },
        { id: 1, name: "Not Installed" },
      ];

      var editors = [];
      var nodeTypeId = null;
      var disabledForProgram = ["typeId", "sizeId", "materialId", "manufacturerId", "model", "installYear", "value"];

      // stores

      const assetStore = {
        store: new CustomStore({
          key: "id",
          load: (loadOptions) => {
            var types = exporting.current ? [] : ["Asset", "SuperAsset", "Program"];
            return exporting.current
              ? request({
                  url: `${url}/node/download`,
                  params: { filterExpression: props.filter, ids: props.selectedRowsData.map((i) => i.id), types: types },
                  loadOptions: loadOptions,
                })
              : request({
                  url: `${url}/node`,
                  params: { filterExpression: props.filter, ids: props.selectedRowsData.map((i) => i.id), types: types },
                  loadOptions: loadOptions,
                });
          },
          insert: (data) => {
            return request({ url: `${url}/node`, method: "Post", data: data });
          },
          update: (_, data) => {
            return request({ url: `${url}/node`, method: "Put", data: data });
          },
          remove: (id) => {
            return request({ url: `${url}/node/${id}`, method: "Delete" });
          },
        }),
      };

      const nodeStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/hierarchy`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/hierarchy/${id}` });
          },
        }),
      };

      const nodeStatusStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/nodeStatus`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/nodeStatus/${id}` });
          },
        }),
      };

      const ownershipStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/ownership`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/ownership/${id}` });
          },
        }),
      };

      const classStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/class`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/class/${id}` });
          },
        }),
      };

      const typeStore = (options) => {
        return {
          store: new CustomStore({
            key: "id",
            useDefaultSearch: true,
            load: (loadOption) => {
              let options_ = options.data ? addFilter(loadOption, "and", ["classId", "=", options.data.classId]) : loadOption;
              return request({ url: `${url}/type`, loadOptions: options_ });
            },
            byKey: function (id) {
              return request({ url: `${url}/type/${id}` });
            },
          }),
        };
      };

      const sizeStore = (options) => {
        return {
          store: new CustomStore({
            key: "id",
            useDefaultSearch: true,
            load: (loadOption) => {
              let options_ = options.data ? addFilter(loadOption, "and", ["classId", "=", options.data.classId]) : loadOption;
              return request({ url: `${url}/size`, loadOptions: options_ });
            },
            byKey: function (id) {
              return request({ url: `${url}/size/${id}` });
            },
          }),
        };
      };

      const materialStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/material`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/material/${id}` });
          },
        }),
      };

      const manufacturerStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/manufacturer`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/manufacturer/${id}` });
          },
        }),
      };

      // hooks

      const dataGrid = useRef(null);
      const exporting = useRef(false);

      useEffect(() => {
        console.log("%cUseEffect", "color: red", props.filter);
      });

      // event handlers

      function onRowInserted(e) {
        e.data.nodeTypeId === 2 && props.refresh("hierarchy");
        props.refresh("count");
      }

      function onRowRemoving(e) {
        dataGrid.current.clearSelection();
      }

      function onRowRemoved(e) {
        dataGrid.current.selectRowsByIndexes([0]);
        e.data.nodeTypeId === 2 && props.refresh("hierarchy");
        props.refresh("count");
      }

      function onExporting(e) {
        e.component.columnOption("id", "visible", true);
        e.component.columnOption("parentId", "visible", true);
        e.component.columnOption("operation", "visible", true);
        e.component.columnOption("nodeTypeId", "visible", true);
        exporting.current = true;
      }

      function onExported(e) {
        e.component.columnOption("id", "visible", false);
        e.component.columnOption("parentId", "visible", false);
        e.component.columnOption("operation", "visible", false);
        e.component.columnOption("nodeTypeId", "visible", false);
        exporting.current = false;
      }

      function onInitialized(e) {
        dataGrid.current = e.component;
      }

      function setCellValue(newData, value, oldData) {
        newData.nodeTypeId = nodeTypeId || oldData.nodeTypeId;
        newData.classId = value;
        newData.typeId = null;
        newData.sizeId = null;
      }

      async function onUploaded(fileId) {
        props.upload({ name: "UPLOAD_NODES", url: `${url}/node/upload/file/${fileId}` });
      }

      function onInitNewRow(e) {
        e.data.nodeTypeId = nodeTypeId = 0;
        e.data.parentId = props.selectedRowsData[0].id;
        e.data.count = 1;
      }

      function onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
          if (disabledForProgram.some((i) => i === e.dataField)) {
            if (e.row.data.nodeTypeId === 3) {
              e.editorOptions.disabled = true;
            }
            e.editorOptions.onInitialized = function (e) {
              editors.push(e.component);
            };
          }
        }

        // if (e.parentType === "dataRow") {
        //   if (e.dataField === "installationStatus") {
        //     if (e.row.data.installYear !== new Date().getFullYear()) {
        //       e.editorOptions.value = null;
        //       e.editorOptions.disabled = true;
        //     }
        //     e.editorOptions.onInitialized = function (e) {
        //       installationStatusEditor = e.component;
        //     };
        //   }
        // }

        if (e.dataField === "nodeTypeId") {
          e.editorOptions.dataSource.store._array = [
            { id: 0, name: "Asset" },
            { id: 2, name: "SuperAsset" },
            { id: 3, name: "Program" },
          ];
        }
      }

      function onCellPrepared(e) {
        if (e.rowType === "data" && disabledForProgram.some((i) => i === e.column.dataField) && e.data.nodeTypeId === 3) {
          e.cellElement.style.background = "#44570E";
          e.cellElement.style.color = "#292929";
        }
      }

      function onNodeTypeChanged(e) {
        nodeTypeId = e.value;
        editors.forEach((i) => i.option("disabled", e.value === 3));
      }

      // function onInstallYearChanged(e) {
      //   installationStatusEditor.option("disabled", e.value !== new Date().getFullYear());
      // }

      // rennder

      function gridRender() {
        return (
          <div className={styles.main}>
            <Grid
              title={"Asset"}
              selection={{ mode: "single" }}
              dataSource={assetStore}
              onUploaded={onUploaded}
              onSelectionChanged={props.onSelectionChanged}
              onInitialized={onInitialized}
              resetRowSelectionOnLoad={true}
              onInitNewRow={onInitNewRow}
              onExported={onExported}
              onExporting={onExporting}
              onEditorPreparing={onEditorPreparing}
              onRowRemoving={onRowRemoving}
              onRowInserted={onRowInserted}
              onRowRemoved={onRowRemoved}
              onCellPrepared={onCellPrepared}
            >
              <Column caption={"Id"} dataField={"id"} visible={false} width={100} formItem={{ visible: false }} />
              <Column
                caption={"Operation"}
                name={"operation"}
                calculateCellValue={() => "Update"}
                width={100}
                visible={false}
                formItem={{ visible: false }}
              />
              <Column caption={"AssetId"} dataField={"name"} width={200}>
                <RequiredRule />
              </Column>
              <Column caption={"Description"} dataField={"description"} width={400}>
                <RequiredRule />
              </Column>
              <Column caption={"Parent"} dataField={"parentId"} calculateDisplayValue="parent" width={200} visible={false}>
                <Lookup dataSource={nodeStore} valueExpr={"id"} displayExpr="name" />
              </Column>
              <Column
                caption={"Node Type"}
                dataField={"nodeTypeId"}
                width={100}
                visible={false}
                allowSorting={false}
                formItem={{ visible: true, editorOptions: { readOnly: false, onValueChanged: onNodeTypeChanged } }}
              >
                <Lookup dataSource={nodeTypes} valueExpr={"id"} displayExpr="name" />
              </Column>
              <Column caption={"Location"} dataField={"location"} width={200} />
              <Column caption={"Ownership"} dataField={"ownershipId"} calculateDisplayValue="ownership" width={200}>
                <Lookup dataSource={ownershipStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
              </Column>
              <Column caption={"Status"} dataField={"statusId"} calculateDisplayValue="status" width={200}>
                <Lookup dataSource={nodeStatusStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
              </Column>
              <Column caption={"Class"} dataField={"classId"} calculateDisplayValue="class" width={200} setCellValue={setCellValue} dataType="number">
                <Lookup dataSource={classStore} valueExpr={"id"} displayExpr="name" />
                <RequiredRule />
              </Column>
              <Column caption={"Type"} dataField={"typeId"} calculateDisplayValue="type" width={200}>
                <Lookup dataSource={typeStore} valueExpr={"id"} displayExpr="name" />
                <RequiredRule />
              </Column>
              <Column caption={"Size"} dataField={"sizeId"} calculateDisplayValue="size" width={200}>
                <Lookup dataSource={sizeStore} valueExpr={"id"} displayExpr="name" />
                <RequiredRule />
              </Column>
              <Column caption={"Material"} dataField={"materialId"} calculateDisplayValue="material" width={200}>
                <Lookup dataSource={materialStore} valueExpr={"id"} displayExpr="name" />
                <RequiredRule />
              </Column>
              <Column caption={"Manufacturer"} dataField={"manufacturerId"} calculateDisplayValue="manufacturer" width={200}>
                <Lookup dataSource={manufacturerStore} valueExpr={"id"} displayExpr="name" />
                <RequiredRule />
              </Column>
              <Column caption={"Model"} dataField={"model"} width={200} />
              <Column
                caption={"Install Year"}
                dataField={"installYear"}
                dataType={"number"}
                width={100}
                formItem={{ visible: true, editorOptions: { readOnly: false } }} // onValueChanged: onInstallYearChanged
              >
                <NumericRule />
              </Column>
              <Column
                caption={"Installation Status"}
                dataField={"installationStatus"}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              >
                <Lookup dataSource={installationStatus} valueExpr={"id"} displayExpr="name" />
              </Column>
              {/* <Column
                  caption={"Valuation"}
                  dataField={"value"}
                  dataType={"number"}
                
                  width={200}
                  format={`${currency()}#,###`}
                  formItem={{ editorOptions: { format: `${currency()}#,###` } }}
                >
                  <NumericRule />
                </Column> */}
              <Column caption={"Count"} dataField={"count"} dataType={"number"} width={100}>
                <NumericRule />
                <RangeRule min={1} />
              </Column>
              {/* <Column visible={false} formItem={{ itemType: "empty" }} /> */}
              <Column
                caption={"Notes"}
                dataField={"notes"}
                width={400}
                formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
              />{" "}
            </Grid>
          </div>
        );
      }
      return gridRender();
    },
    ["selectedRowsData", "filter"]
  )
);
