import React, { useState, useRef } from "react";
import styles from "./login.module.scss";
import { TextBox } from "devextreme-react/text-box";
import { Switch } from "devextreme-react/switch";
import { Button } from "devextreme-react";
import { Redirect } from "react-router-dom";
import { LoadIndicator } from "devextreme-react/load-indicator";
import * as actions from "redux/actions/index";
import { Validator, RequiredRule } from "devextreme-react/validator";
import { connect } from "react-redux";
import "./login.scss";

function Auth(props) {
  // styles

  let inlineStyles = {
    height: "16px",
    width: "16 px",
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: "0px",
  };

  // useState

  const [state, setState] = useState({
    userName: null,
    password: null,
    rememberMe: false,
  });

  // useRef

  const form = useRef();

  // event handers

  function onSubmit(e) {
    e.preventDefault();

    props.onSubmit(state);
  }

  return (
    // </div>
    <div className={"app_start_main login"}>
      {props.isAuthenticated ? <Redirect from="/" to="/" /> : null}
      {/* {props.loading ? null : formRender()}
			{props.loading ? loadRender() : null} */}
      <form onSubmit={onSubmit}>
        <div className={`${styles.container} dx-card `}>
          <div className={styles.logo}>
            <img src={require("assets/icons/logogray.png")} width="60" height="38" alt="" />
          </div>
          <div className={styles.title}>User Login</div>

          <div className={styles.textbox}>
            <TextBox
              placeholder={"Username"}
              stylingMode={"filled"}
              onValueChanged={(e) => {
                setState({ ...state, userName: e.value });
              }}
            >
              <Validator>
                <RequiredRule message="Email is required" />
                {/* <EmailRule message="Email is invalid" /> */}
              </Validator>
            </TextBox>
          </div>
          <div className={styles.textbox}>
            <TextBox
              placeholder={"Password"}
              mode={"password"}
              stylingMode={"filled"}
              onValueChanged={(e) => {
                setState({ ...state, password: e.value });
              }}
            >
              <Validator>
                <RequiredRule message="Password is required" />
              </Validator>
            </TextBox>
          </div>
          <div className={styles.footer}>
            <div className={styles.footer_left}>
              Remember Me
              <div className={styles.footer_switch}>
                <Switch
                  onValueChanged={(e) => {
                    setState({ ...state, persistent: e.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.button}>
            <Button
              text="LOGIN"
              type={"success"}
              icon={"fas fa-sign-in-alt"}
              stylingMode={"contained"}
              width={"100%"}
              height={50}
              useSubmitBehavior={true}
            >
              <LoadIndicator className="button-indicator" indicatorSrc={require("assets/gifs/4.gif")} visible={props.loading} style={inlineStyles} />
              <span className="dx-button-text">{props.loading ? "LOGGING IN" : "LOGIN"}</span>
            </Button>
          </div>
          <div className={styles.forget}></div>
          <br />
          {props.error ? <div className={styles.error}>Incorrect username or password!</div> : null}
        </div>
      </form>
    </div>
  );
}

function mapsDispatchToProps(dispatch) {
  return {
    onSubmit: (state) => dispatch(actions.login(state)),
  };
}

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

export default connect(mapStateToProps, mapsDispatchToProps)(Auth);
