import React, { useState, useRef, useEffect } from "react";
import styles from "./form.module.scss";
import { request } from "helper/http-client";
import { LoadIndicator } from "devextreme-react/load-indicator";
import DxForm, { GroupItem, SimpleItem, Label, RequiredRule, NumericRule } from "devextreme-react/form";
import { Button } from "devextreme-react";
import { url } from "config.js";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import { dict, notify } from "helper/global";

function Form(props) {
  //
  // useState

  const [state, setState] = useState(null);

  // useEffect

  useEffect(() => {
    getScenario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scenarioId]);

  // useRef

  const form = useRef();

  // functions

  function getScenario() {
    (async () => {
      let result = await request({ url: `${url}/scenario/${props.scenarioId}` });
      setState(result);
    })();
  }

  // event handlers

  function onCancel() {
    getScenario();
    notify("Cancelled", "warning");
  }

  async function onSave() {
    const data = form.current.instance.option().formData;
    data.active && props.saveScenario(data);
    try {
      await request({ url: `${url}/scenario`, method: "Put", data: data });
    } catch (error) {
      console.log("error");
    } finally {
      notify("Save completed", "success");
    }
  }

  // render

  function buttonRender() {
    return (
      <div>
        <Button text={dict("Save")} type={"success"} stylingMode="outlined" width={100} onClick={onSave} />
        <Button className={styles.cancelbutton} text={dict("Cancel")} type={"normal"} stylingMode="outlined" width={100} onClick={onCancel} />
      </div>
    );
  }

  function formRender() {
    return (
      <div className={styles.main}>
        <form>
          <DxForm
            ref={form}
            formData={state || {}}
            labelLocation={"top"}
            minColWidth={200}
            width={750}
            showColonAfterLabel={true}
            showRequiredMark={false}
          >
            <GroupItem caption={dict("General")}>
              <GroupItem colCount={4}>
                <SimpleItem dataField="description" colSpan={3}>
                  <Label text={dict("Description")} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
              </GroupItem>
            </GroupItem>
            <GroupItem colCount={4}>
              <GroupItem caption={dict("Projection")}>
                <SimpleItem dataField="firstYear">
                  <Label text={dict("First Year")} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
                <SimpleItem dataField="planningHorizon">
                  <Label text={dict("Planning Horizon")} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
                <SimpleItem dataField="backlog">
                  <Label text={dict("Backlog")} />
                </SimpleItem>
                <SimpleItem dataField="includeFutureRisk">
                  <Label text={`${dict("Include Future Risk")}`} />
                </SimpleItem>
              </GroupItem>
              <GroupItem caption={dict("Condition")}>
                <SimpleItem dataField="worstCondition">
                  <Label text={`${dict("Worst")} ${dict("Condition")}`} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
                <SimpleItem dataField="bestCondition">
                  <Label text={`${dict("Best")} ${dict("Condition")}`} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
                <SimpleItem dataField="shapeFactor">
                  <Label text={dict("Shape Factor")} />
                  <RequiredRule message="Required field" />
                  <NumericRule message="Must be a real number" />
                </SimpleItem>
              </GroupItem>
              <GroupItem caption={dict("Risk")}>
                <SimpleItem dataField="maxPof">
                  <Label text={`${dict("Maximum")} PoF`} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
                <SimpleItem dataField="maxCof">
                  <Label text={`${dict("Maximum")} CoF`} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
                <SimpleItem dataField="pofInterval">
                  <Label text={`PoF ${dict("Interval")}`} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
                <SimpleItem dataField="cofInterval">
                  <Label text={`CoF ${dict("Interval")}`} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
                <SimpleItem dataField="standbyFactor">
                  <Label text={`${dict("Standyby Factor")}`} />
                  <RequiredRule message="Required field" />
                  <NumericRule message="Must be a real number" />
                </SimpleItem>
              </GroupItem>

              <GroupItem caption={dict("Financial")}>
                <SimpleItem dataField="escalation">
                  <Label text={dict("Escalation")} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
                <SimpleItem dataField="standardDeviation">
                  <Label text={`${dict("Standard Deviation")} (${dict("Smoothing")})`} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
              </GroupItem>
            </GroupItem>
            <SimpleItem render={buttonRender} />
          </DxForm>
        </form>
      </div>
    );
  }

  function loadingRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }
  return state ? formRender() : loadingRender();
}

// redux

function mapDispatchToProps(dispatch) {
  return {
    saveScenario: (data) => dispatch(actions.saveScenario(data)),
  };
}

export default connect(null, mapDispatchToProps)(Form);
