import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import styles from "./option.module.scss";
import Scenario from "./scenario/scenario";
import Tabs from "components/tabs/tabs";
import { selectedTabItem } from "helper/global";
import IgnoreStatus from "./ignore-status/ignore-status";
import { dict } from "helper/global";

function Component(props) {
  // tabItems

  const tabItems = [
    {
      text: dict("Scenario"),
      icon: "fas fa-tools",
      path: "/scenario",
    },
    {
      text: dict("Ignore"),
      icon: "fad fa-bullhorn",
      path: "/ignorestatus",
    },
  ];

  // event handlers

  function changeView(e) {
    props.history.push(`/klient/analyze/option${e.path}`);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView} />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route exact path={["/klient/analyze", "/klient/analyze/option"]} component={Scenario} />
          <Route exact path="/klient/analyze/option/scenario" component={Scenario} />
          <Route exact path="/klient/analyze/option/ignorestatus" component={IgnoreStatus} />

          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

export default Component;
