import * as actionTypes from "./actionTypes";
import { request } from "helper/http-client";
import { url } from "config.js";
import { notify } from "helper/global";

export const _loadDictionary = (result) => {
  return {
    type: actionTypes.DICTIONARY_LOAD,
    ...result,
  };
};

function _updateDictionary(data) {
  return {
    type: actionTypes.DICTIONARY_UPDATE,
    ...data,
  };
}

export const loadDictionary = () => {
  return async (dispatch) => {
    try {
      let result = await request({ url: `${url}/dictionary/keyvalue` });
      dispatch(_loadDictionary(result));
    } catch (error) {
      notify("Unable to load dictionary", "error");
    }
    return "done";
  };
};

export function saveDictionary(data) {
  return async (dispatch) => {
    try {
      await request({ url: `${url}/dictionary/keyvalue`, method: "Put", data: data });
      dispatch(_updateDictionary(data));
    } catch (error) {}
  };
}
