import * as actionTypes from "./actionTypes";
import { request } from "helper/http-client";
import { url } from "config.js";
import { loadUser } from "./user";
import { loadOrg } from "./org";
import { loadScenario } from "./scenario";
import { loadDictionary } from "./dictionary";
import { clearHome } from "./home";
import { loadApp } from "./app";

const _authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

const _authSuccess = (info) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    isAuthenticated: true,
  };
};

const _authFail = (error) => {
  localStorage.removeItem("userId");

  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

const _authLogout = () => {
  localStorage.removeItem("userId");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

const _authCheckStart = () => {
  return {
    type: actionTypes.AUTH_CHECK_START,
  };
};

const _authCheckSuccess = () => {
  return {
    type: actionTypes.AUTH_CHECK_SUCCESS,
    isAuthenticated: true,
  };
};

// public funcitons

export const login = (cred) => {
  return async (dispatch) => {
    dispatch(_authStart());
    try {
      var auth = await request({ url: `${url}/auth/login`, method: "Post", data: cred });
      await Promise.all([dispatch(loadUser()), dispatch(loadOrg()), dispatch(loadScenario()), dispatch(loadDictionary()), dispatch(loadApp())]); //, dispatch(loadHome())
      dispatch(_authSuccess(auth));
    } catch (error) {
      dispatch(_authFail(error));
    }
    return "done";
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch(_authStart());
    try {
      await request({ url: `${url}/auth/logout`, method: "Post" });
      dispatch(clearHome());
      dispatch(_authLogout());
    } catch (error) {
      dispatch(_authFail(error));
    }
    return "done";
  };
};

export const check = () => {
  return async (dispatch) => {
    dispatch(_authCheckStart());
    try {
      var auth = await request({ url: `${url}/auth/session`, method: "Post" });
      await Promise.all([dispatch(loadUser()), dispatch(loadOrg()), dispatch(loadScenario()), dispatch(loadDictionary()), dispatch(loadApp())]); //, dispatch(loadHome())
      dispatch(_authCheckSuccess(auth));
    } catch (error) {
      dispatch(_authLogout());
    }
    return "done";
  };
};

export const expire = () => {
  return _authLogout();
};
