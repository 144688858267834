import React, { useEffect, useState } from "react";
import styles from "./summary.module.scss";
import { request } from "helper/http-client";
import { url } from "config.js";
import Form, { GroupItem, SimpleItem, Label } from "devextreme-react/form";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Popup, Gallery } from "devextreme-react";
import { dict } from "helper/global";

function Component(props) {
  // useState
  const [state, setState] = useState(null);
  const [popup, setPopup] = useState(false);
  // useEffect
  useEffect(
    () => {
      if (!props.assetId) return;
      (async () => {
        try {
          var p1 = request({ url: `${url}/node/${props.nodeId}` });
          var p2 = request({ url: `${url}/assetimage/asset/${props.assetId}` });
          const [data, images] = await Promise.all([p1, p2]);
          setState({ data: data, images: images.data });
        } catch (error) {
          console.log(error);
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.assetId]
  );

  // event handlers

  function onDoubleClick() {
    setPopup(true);
  }

  // render

  const editorOptions = { stylingMode: "filled", height: "40px", readOnly: true };

  function galleryItemRender(data) {
    return (
      <div className={styles.gallery_box}>
        <img src={data.url} alt={""} />
      </div>
    );
  }

  function loadingRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }

  function popupRender() {
    return (
      <Popup
        className={"gallery"}
        visible={popup}
        onHiding={() => setPopup(false)}
        dragEnabled={true}
        closeOnOutsideClick={false}
        showTitle={true}
        title={state.data.description}
        width={800}
        height={600}
      >
        <div className={`${styles.popup}`}>
          <Gallery
            id={styles.gallery}
            dataSource={state.images}
            height={475}
            noDataText={"No image to display"}
            loop={false}
            showNavButtons={true}
            showIndicator={false}
            itemRender={galleryItemRender}
          />
        </div>
      </Popup>
    );
  }

  function summaryRender() {
    return (
      <div className={styles.main}>
        <div className={styles.form}>
          <Form formData={state.data} labelLocation={"top"} minColWidth={200} showColonAfterLabel={true} showRequiredMark={false}>
            <GroupItem colCount={3} cssClass={"theme-form"}>
              <GroupItem>
                <SimpleItem dataField="name" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("AssetId")} />
                </SimpleItem>
                <SimpleItem dataField="description" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Description")} />
                </SimpleItem>
                <SimpleItem dataField={dict("location")} colSpan={1} editorOptions={editorOptions}>
                  <Label text="Location" />
                </SimpleItem>
                <SimpleItem dataField="status" colSpan={1} editorType="dxDropDownBox" editorOptions={editorOptions}>
                  <Label text={dict("Status")} />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem dataField="class" colSpan={1} editorType="dxDropDownBox" editorOptions={editorOptions}>
                  <Label text={dict("Class")} />
                </SimpleItem>
                <SimpleItem dataField="type" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Type")} />
                </SimpleItem>
                <SimpleItem dataField="size" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Size")} />
                </SimpleItem>
                <SimpleItem dataField="material" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Material")} />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem dataField="manufacturer" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Manufacturer")} />
                </SimpleItem>
                <SimpleItem dataField="count" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Count")} />
                </SimpleItem>
                <SimpleItem dataField="installYear" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Install Year")} />
                </SimpleItem>
                <SimpleItem dataField="condition" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Condition")} />
                </SimpleItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </div>
        <div className={styles.gallery} onDoubleClick={onDoubleClick}>
          <div className={`${styles.gallery_box} theme-form`}>
            <Gallery
              dataSource={state.images}
              height={250}
              noDataText={dict("No image to display")}
              loop={false}
              showNavButtons={true}
              showIndicator={false}
              itemRender={galleryItemRender}
            />
          </div>
        </div>
        {popupRender()}
      </div>
    );
  }
  return state ? summaryRender() : loadingRender();
}

export default Component;
