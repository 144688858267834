import React, { useState } from "react";
import styles from "./preference.module.scss";
import Form, { SimpleItem, Label } from "devextreme-react/form";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import { Button } from "devextreme-react";
import { dict, notify } from "helper/global";

function Component(props) {
  //
  const dictionary = [
    { id: 0, name: "en", description: "English" },
    { id: 1, name: "nl", description: "Dutch" },
  ];

  //
  // useState

  const [data, setData] = useState({ ...props.data });

  function onSave() {
    props.saveUser(data);
    notify(dict("Preference updated successfully. Refresh the brower"), "success");
  }

  function onCancel() {
    setData(props.data);
  }

  // render

  function buttonRender() {
    return (
      <div>
        <Button text={"Save"} type={"success"} stylingMode="outlined" width={100} onClick={onSave} />
        <Button className={styles.cancelbutton} text={"Cancel"} type={"normal"} stylingMode="outlined" width={100} onClick={onCancel} />
      </div>
    );
  }

  function formRender() {
    return (
      <div>
        <div className={`${styles.form} dx-card`}>
          <div className={styles.form_fields}>
            <Form
              id={"form"}
              //ref={form}
              formData={data}
              width={600}
              showColonAfterLabel={true}
              showRequiredMark={false}
              labelLocation={"top"}
            >
              <SimpleItem
                dataField="dictionary"
                editorType="dxSelectBox"
                editorOptions={{ items: dictionary, valueExpr: "name", displayExpr: "description", showClearButton: true }}
              >
                <Label text={dict("Dictionary")} />
              </SimpleItem>

              <SimpleItem />
              <SimpleItem render={buttonRender} />
            </Form>
          </div>
        </div>
      </div>
    );
  }
  return <div className={styles.main}>{formRender()}</div>;
}

function mapsDispatchToProps(dispatch) {
  return {
    saveUser: (data) => dispatch(actions.saveUser(data)),
  };
}

function mapStateToProps(state) {
  return {
    data: {
      //dictionary: state.org.dictionary,
      ...state.user,
    },
  };
}

export default connect(mapStateToProps, mapsDispatchToProps)(Component);
