import React, { useEffect, useReducer, useRef } from "react";
import TileView from "devextreme-react/tile-view";
import Toolbar, { Item } from "devextreme-react/toolbar";
import styles from "./doc-tileview.module.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "./doc-tileview.scss";
import FileUploader from "components/uploader/uploader";
import { request } from "helper/http-client";
import { notify } from "helper/global";
import { confirm } from "devextreme/ui/dialog";
// import { connect } from "react-redux";
// import * as actions from "redux/actions/index";
// import ProgressPanel from "components/progress-panel/progress-panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getIcon } from "helper/global";
import { url } from "config.js";

function reducer(state, action) {
  switch (action.type) {
    case "showAdd":
      return { ...state, add: true };
    case "hideAdd":
      return { ...state, data: null, add: false, render: state.render + 1 };
    case "select":
      return { ...state, selected: action.selected };
    case "delete":
      return { ...state, selected: null, data: null, render: state.render + 1 };
    case "data":
      return { ...state, data: action.data };
    case "render":
      return { ...state, data: null, render: state.render + 1 };
    default:
      return state;
  }
}

const initialState = {
  data: null,
  render: 0,
  upload: false,
  add: false,
  selected: null,
};

function Component(props) {
  //
  // useReducer

  const [state, dispatch] = useReducer(reducer, initialState);

  // hooks
  const selectedUrl = useRef(null);

  useEffect(() => {
    request({ url: `${url}/assetfile/asset/${props.id}` })
      .then((result) => {
        dispatch({ type: "data", data: result.data });
      })
      .catch(() => {
        notify("Error", "error");
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, props.render, state.render]);

  // event handlers

  function onAdd() {
    dispatch({ type: "showAdd" });
  }

  function hideAdd() {
    dispatch({ type: "hideAdd" });
  }

  function onItemClick(e) {
    selectedUrl.current = e.itemData.url;
    dispatch({ type: "select", selected: e.itemData });
  }

  async function onDelete() {
    var result = await confirm("<i>Do you really want to delete the selected document?</i>", "Confirm");
    if (result) {
      try {
        await request({ url: `${url}/assetfile/asset/${props.id}/file/${state.selected.id}`, method: "Delete" });
        dispatch({ type: "delete" });
        props.refresh && props.refresh();
      } catch (error) {
        notify(error, "error");
      }
    }
  }

  // function onUploadComplete() {
  //   dispatch({ type: "render" });
  //   props.refresh && props.refresh();
  // }

  function onDoubleClick() {
    if (selectedUrl.current) {
      var win = window.open(selectedUrl.current, "_blank");
      win && win.focus();
    }
  }

  // render

  function tileRender(data) {
    return (
      <div className={styles.image} onDoubleClick={onDoubleClick}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={getIcon(data.name)} />
        </div>
        <div className={styles.text}>{data.name}</div>
      </div>
    );
  }

  function loadIndicatorRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }

  // function toolbarRender() {
  //   return (
  //     <div className={`components_doctileview`}>
  //       <Toolbar>
  //         <Item location="before" widget="dxButton" options={{ icon: "fas fa-plus", onClick: onAdd }} />
  //         <Item location="before" widget="dxButton" options={{ icon: "fas fa-minus", onClick: onDelete }} />
  //       </Toolbar>
  //     </div>
  //   );
  // }

  function tileViewRender() {
    return (
      <div className={`components_doctileview`}>
        <TileView
          height={"100%"}
          showScrollbar={true}
          direction="vertical"
          items={state.data}
          itemRender={tileRender}
          onItemClick={onItemClick}
        ></TileView>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <FileUploader
        multiple={true}
        allowedFileExtensions={[]}
        fileLoaderVisible={state.add}
        hideFileLoader={hideAdd}
        uploadUrl={`${url}/assetfile/blob/asset/${props.id}`}
      />
      {/* {toolbarRender()} */}
      {/* <ProgressPanel processId={UPLOAD_DOCUMENT} onComplete={onUploadComplete}> */}
      {state.data ? tileViewRender() : loadIndicatorRender()}
      {/* </ProgressPanel> */}
    </div>
  );
}

// const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";

// const mapDispatchToProps = (dispatch) => {
//   return {
//     upload: (url) => dispatch(actions.process(url, UPLOAD_DOCUMENT)),
//   };
// };

// export default connect(null, mapDispatchToProps)(Component);

export default Component;
