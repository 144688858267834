import React from "react";
import { url } from "config.js";
import styles from "./matrix.module.scss";
import { currency, toMillion } from "helper/global";
import { dict } from "helper/global";

export default function Component(props) {
  //
  //state
  // const initialHeader = {
  //   intX: 5,
  //   intY: 0.2,
  //   maxX: 30,
  //   maxY: 1,
  // };

  const header = {
    intX: props.header.cofInterval,
    intY: props.header.pofInterval,
    maxX: props.header.maxCof,
    maxY: props.header.maxPof,
  };

  // const [header, setHeader] = useState(initialHeader);
  // const [props.data, setData] = useState();

  // constants

  const rows = header.maxY / header.intY;
  const cols = header.maxX / header.intX;
  const gridGap = "10px";

  // styles

  const main = {
    height: "100%",
    width: "100%",
    //margin              : '10px',
    display: "grid",
    gridTemplateColumns: "50px 50px 1fr",
    gridTemplateRows: "1fr 25px 50px",
    padding: "0px 10px 0px 0px",
  };

  const matrix_container = {
    gridColumnStart: "3",
    gridColumnEnd: "4",
    gridRowStart: "1",
    gridRowEnd: `2`,
  };

  const matrix = {
    height: "100%",
    //margin              : '10px',
    display: "grid",
    gridGap: gridGap,
    gridTemplateColumns: `repeat(${cols}, 1fr)`,
    gridTemplateRows: `repeat(${rows}, 1fr)`,
  };

  const xaxis = {
    gridColumnStart: "3",
    gridColumnEnd: `4`,
    gridRowStart: "2",
    gridRowEnd: `3`,
    //alignSelf           : `center`,
    display: "grid",
    gridTemplateColumns: `repeat(${cols}, 1fr)`,
    gridGap: gridGap,
  };

  const xaxis_text = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const xaxis_title = {
    gridColumnStart: "3",
    gridColumnEnd: `4`,
    gridRowStart: "3",
    gridRowEnd: `4`,
    alignSelf: `center`,
  };

  const xaxis_title_text = {
    fontSize: "18px",
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const yaxis = {
    gridColumnStart: "2",
    gridColumnEnd: `3`,
    gridRowStart: "1",
    gridRowEnd: `2`,
    display: "grid",
    gridTemplateRows: `repeat(${rows}, 1fr)`,
    gridGap: gridGap,
  };

  const yaxis_text = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const yaxis_title = {
    gridColumnStart: "1",
    gridColumnEnd: "2",
    gridRowStart: "1",
    gridRowEnd: `2`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const yaxis_title_text = {
    transform: "rotate(-90deg)",
    fontSize: "18px",
    whiteSpace: "nowrap",
  };

  const box = (color) => ({
    backgroundColor: `${color}`,
    //border: none
  });

  // hooks

  // useEffect(() => {
  //   if (props.year !== props.firstYear) setData(null);
  //   getData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.selectedHierarchyScenarioNodes, props.filter, props.year]);

  // // functions

  // function getData() {
  //   if (props.selectedHierarchyScenarioNodes.length === 0) setData([]);
  //   var riskMatrix = request({
  //     url: `${url}/engine/output/risk-matrix`,
  //     params: { ids: props.selectedHierarchyScenarioNodes.map((i) => i.id), year: props.year, filterExpression: props.filter },
  //   });
  //   riskMatrix
  //     .then((result) => {
  //       const { cofInterval, pofInterval, maxCof, maxPof } = result.header;
  //       setHeader({ intX: cofInterval, intY: pofInterval, maxX: maxCof, maxY: maxPof });
  //       setData(result.props.data);
  //     })
  //     .catch((error) => {
  //       throw error.message;
  //     });
  // }

  // event handlers

  function onBoxClick(x, y) {
    props.onBoxClick &&
      props.onBoxClick({
        url: `${url}/engine/output/risk-matrix-nodes`,
        params: {
          pof: y,
          cof: x,
        },
        title: `Assets with COF Rating: ${x} and POF Rating: ${y}`,
      });
  }

  // functions

  function getCellCost(x, y) {
    if (props.data == null || props.type.includes("Count")) return;

    var value = props.data.find((i) => i.cof === x && i.pof === y);

    return value || false ? `${currency()}${toMillion(value.cost)}` : `${currency()}0`;
  }

  function getCellCount(x, y) {
    if (props.data == null || props.type.includes("Cost")) return;

    var value = props.data.find((i) => i.cof === x && i.pof === y);

    return value || false ? value.count : "0";
  }

  function getCellColor(x, y) {
    if (props.data == null) return;

    var value = props.data.find((i) => i.cof === x && i.pof === y);

    return !!value ? value.color : "#4a4a4a";
  }

  // render

  function Box(n) {
    let x = parseFloat(((n % cols === 0 ? cols : n % cols) * header.intX).toFixed(2));
    let y = parseFloat(((rows - Math.ceil(n / cols) + 1) * header.intY).toFixed(2));
    return (
      <div
        className={styles.box}
        style={box(getCellColor(x, y))}
        key={n}
        onClick={() => {
          onBoxClick(x, y);
        }}
      >
        {getCellCost(x, y)}
        <br />
        {getCellCount(x, y)}
      </div>
    );
  }

  function Matrix() {
    var n = rows * cols;

    var boxes = new Array(n);

    for (var i = 0; i < n; i++) {
      boxes[i] = Box(i + 1);
    }
    return boxes;
  }

  function XAxis() {
    let boxes = new Array(cols);
    for (var i = 0; i < cols; i++) {
      boxes[i] = (
        <div key={i} style={xaxis_text}>
          {(i + 1) * header.intX}
        </div>
      );
    }
    return boxes;
  }

  function YAxis() {
    let boxes = new Array(rows);
    for (var i = 0; i < rows; i++) {
      boxes[i] = (
        <div key={i} style={yaxis_text}>
          {((rows - i) * header.intY).toFixed(1)}
        </div>
      );
    }
    return boxes;
  }

  return (
    <div style={main}>
      <div style={yaxis_title}>
        <div style={yaxis_title_text}>{`${dict("Probability of Failure")}`}</div>
      </div>
      <div style={yaxis}>
        <YAxis />
      </div>
      <div style={matrix_container}>
        <div style={matrix}>
          <Matrix />
        </div>
      </div>
      <div style={xaxis_title}>
        <div style={xaxis_title_text}>{`${dict("Consequence of Failure")}`}</div>
      </div>
      <div style={xaxis}>
        <XAxis />
      </div>
    </div>
  );
}
