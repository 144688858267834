import React, { useState, useRef } from "react";
import styles from "./file.module.scss";
import FileUploader from "components/uploader/uploader";
import { url } from "config.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { Column } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getIcon, withMemo } from "helper/global";
import ProgressPanel from "components/progress-panel/progress-panel";
import Grid from "components/data-grid/data-grid.js";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";

function Component(props) {
  // hooks

  const [state, setState] = useState(false);
  const [render, setRender] = useState(0);

  // event handlers

  function onHiding() {
    setState({ showPopup: false, url: null, name: null });
  }

  function showPopup() {
    setState(true);
  }

  function onUploaded() {
    setRender((state) => state + 1);
  }

  // render

  return (
    <div className={styles.main}>
      <FileUploader
        multiple={true}
        allowedFileExtensions={[]}
        fileLoaderVisible={state}
        hideFileLoader={onHiding}
        uploadUrl={`${url}/file/upload/blob`}
        onUploaded={onUploaded}
      />
      <FileGrid showPopup={showPopup} render={render} />
    </div>
  );
}

// redux

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default Component;

const FileGrid = connect(
  null,
  mapDispatchToProps
)(
  withMemo(
    (props) => {
      //
      // store

      const fileStore = {
        store: new CustomStore({
          key: "id",
          load: (loadOptions) => {
            return exporting.current
              ? request({ url: `${url}/file/download`, loadOptions: loadOptions })
              : request({ url: `${url}/file`, loadOptions: loadOptions });
          },
          insert: (data) => {
            return request({ url: `${url}/file`, method: "Post", data: data });
          },
          update: (_, data) => {
            return request({ url: `${url}/file`, method: "Put", data: data });
          },
          remove: (id) => {
            return request({ url: `${url}/file/${id}`, method: "Delete" });
          },
        }),
      };

      // hooks

      const exporting = useRef(false);

      // event handlers

      async function onUploaded(fileId) {
        props.upload({ name: "UPLOAD_FILE", url: `${url}/file/upload/file/${fileId}` });
      }

      function onExporting(e) {
        e.component.columnOption("id", "visible", true);
        e.component.columnOption("id", "sortOrder", "asc");
        e.component.columnOption("operation", "visible", true);
        e.component.columnOption("url", "visible", false);
        exporting.current = true;
      }

      function onExported(e) {
        e.component.columnOption("id", "visible", false);
        e.component.columnOption("id", "sortOrder", "desc");
        e.component.columnOption("operation", "visible", false);
        e.component.columnOption("url", "visible", true);
        exporting.current = false;
      }

      function onCellDblClick(e) {
        if (e.columnIndex === 1) {
          var win = window.open(e.data.url, "_blank");
          win && win.focus();
        }
      }

      // render

      function onToolbarPreparing(e) {
        e.toolbarOptions.items.push({
          location: "before",
          widget: "dxButton",
          visible: true,
          options: {
            hint: "File Upload",
            icon: "fas fa-file-upload",
            onClick: props.showPopup,
          },
        });
      }

      function imageRender(e) {
        if (e.data.type === "File")
          return (
            <div className={styles.icon}>
              <FontAwesomeIcon icon={getIcon(e.data.name)} />
            </div>
          );
        else
          return (
            <div>
              <img src={e.value} height={50} alt={""} />
            </div>
          );
      }

      return (
        <ProgressPanel name={"UPLOAD_FILE"}>
          <Grid
            title={"Files"}
            showTitle={false}
            dataSource={fileStore}
            onUploaded={onUploaded}
            onExported={onExported}
            onExporting={onExporting}
            onToolbarPreparing={onToolbarPreparing}
            onCellDblClick={onCellDblClick}
          >
            <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
            <Column caption={"Operation"} name={"operation"} calculateCellValue={() => "Update"} visible={false} formItem={{ visible: false }} />
            <Column
              dataField="url"
              caption={""}
              width={100}
              allowSorting={false}
              allowFiltering={false}
              cellRender={imageRender}
              formItem={{ visible: false }}
            />
            <Column caption={"Name"} dataField={"name"} allowFiltering={true} width={200}>
              <RequiredRule />
            </Column>
            <Column caption={"Description"} dataField={"description"} allowFiltering={true} width={400} formItem={{ colSpan: 2 }} />
            <Column caption={"Url"} dataField={"url"} allowFiltering={true} width={600} formItem={{ colSpan: 2 }}>
              <RequiredRule />
            </Column>
            <Column
              caption={"Notes"}
              dataField={"notes"}
              allowFiltering={true}
              width={400}
              formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
            />
          </Grid>
        </ProgressPanel>
      );
    },
    ["render"]
  )
);
