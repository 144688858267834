import React, { useRef } from "react";
import styles from "./grid.module.scss";
import { FilterRow, Scrolling } from "devextreme-react/data-grid";
import { DataGrid as DxGrid } from "devextreme-react";
import { Template } from "devextreme-react/core/template";
import { confirm } from "devextreme/ui/dialog";
import { exportDataGrid } from "devextreme/excel_exporter";
import saveAs from "file-saver";
import ExcelJS from "exceljs";
import { now, dict, withMemo } from "helper/global";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";

function Component(props) {
  // stores

  const queryStore = {
    store: new CustomStore({
      key: "Id",
      load: (loadOptions) => {
        if (!props.query.id) return Promise.resolve([]);
        return exporting.current
          ? request({ url: `${url}/query/result/${props.query.id}/download`, loadOptions: loadOptions })
          : request({ url: `${url}/query/result/${props.query.id}`, loadOptions: loadOptions });
      },
    }),
  };

  // hooks

  const dataGrid = useRef(null);
  const exporting = useRef(false);

  // event handlers

  function onInitialized(e) {
    dataGrid.current = e.component;
  }

  async function onDownloadClick() {
    var result = await confirm("<i>Do you really want to download the data?</i>", "Confirm");
    if (result) dataGrid.current.exportToExcel(false);
  }

  function onExporting(e) {
    e.component.columnOption("#", "visible", false);
    props.onExporting && props.onExporting(e);
    var workbook = new ExcelJS.Workbook();
    var worksheet = workbook.addWorksheet("Main sheet");

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
    }).then(function () {
      workbook.csv.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], { type: "application/octet-stream" }), `IRIS Query ${now()}.csv`);
        onExported(e);
      });
    });
    e.cancel = true;
    exporting.current = true;
  }

  function onExported(e) {
    props.onExported && props.onExported(e);
    e.component.columnOption("#", "visible", true);
    exporting.current = false;
  }

  function titleRender() {
    return (
      <div className="theme-grid-title">
        <div>{dict("Result")}</div>
      </div>
    );
  }

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push(
      {
        location: "before",
        template: "titleRender",
      },
      {
        location: "after",
        widget: "dxButton",
        visible: props.allowDownload && true,
        options: {
          hint: "Download",
          icon: "fas fa-cloud-download",
          onClick: onDownloadClick,
        },
      }
    );
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <DxGrid
          dataSource={queryStore}
          height={"100%"}
          selection={{ mode: "single" }}
          remoteOperations={true}
          showRowLines={true}
          showColumnLines={true}
          showBorders={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          wordWrapEnabled={true}
          onToolbarPreparing={onToolbarPreparing}
          onInitialized={onInitialized}
          onExporting={onExporting}
          onExported={onExported}
          onContentReady={(e) => {
            e.component.columnOption("Id", "visible", false);
          }}
        >
          <FilterRow visible={true} />
          <Scrolling mode="infinite" />
          <Template name="titleRender" render={titleRender} />
        </DxGrid>
      </div>
    </div>
  );
}

export default withMemo(Component, ["query", "render"]);
