import React, { useContext } from "react";
import styles from "./activity.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { url } from "config.js";
import Grid from "components/activity-grid/activity-grid.js";
import { request } from "helper/http-client";
import HierarchyContext from "components/hierarchy/context";

function Activity(props) {
  // stores

  const assetStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/tool/budgeting/${props.budgetId}/activities`,
          params: {
            ids: selectedRowsData.map((i) => i.id),
            year: props.year,
            filterExpression: filter,
          },
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // useContext

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  // event handlers

  function onBackButtonClick() {
    props.onBackButtonClick && props.onBackButtonClick();
  }

  // render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      visible: !!props.onBackButtonClick,
      options: {
        hint: "Run Scenario",
        type: "back",
        stylingMode: "contained",
        onClick: onBackButtonClick,
      },
    });
  }

  return (
    selectedRowsData.length > 0 && (
      <div className={styles.main}>
        <Grid title={`Year: ${props.year}`} dataSource={assetStore} showFunded={true} onToolbarPreparing={onToolbarPreparing}></Grid>
      </div>
    )
  );
}

export default Activity;
