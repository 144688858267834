import React, { useState } from "react";
import styles from "./file.module.scss";
import TileView from "components/doc-tileview/doc-tileview";

function Component(props) {
  // useState
  const [render, setRender] = useState(0);

  function onRefresh() {
    setRender((render) => render + 1);
  }

  // render

  return (
    <div className={styles.main}>
      <TileView id={props.data.assetId} title={props.data.description} render={render} refresh={onRefresh}></TileView>
    </div>
  );
}

export default Component;
