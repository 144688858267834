import { loadModules } from "esri-loader";
import { request } from "helper/http-client";
import { apiCode, url } from "config.js";
import React from "react";
import ReactDOM from "react-dom";
import "./gis.scss";
import { store } from "index.js";
import sort from "fast-sort";

// organization settings

// variables

let _initialized = false;
let _map = null;
let _view = null;
let _esriId = null;
let _graphicLayer = null;
let _fields = [];
let _layerInfo = [];
let _search = null;
let _sketchViewModel = null;
let _highlight = [];
let _onSelection = null;
let _onClearSelection = null;
let _onClick = null;
let _activeButton = null;
let _activeWidget = null;
let _ratingsInfo = null;
let _renderColor = "white";
let _selectedRating = null;
let _selectedRisk = "All";
let _selectedCondition = "All";
let _selectedSpatial = "intersects";
let _filterExpression = null;
let _useRenderer = false;

// esri modules

let MapView = null;
let Map = null;
let FeatureLayer = null;
let Graphic = null;
let SketchViewModel = null;
let GraphicsLayer = null;
let Search = null;
let Expand = null;
let Print = null;
let Distance = null;
let Area = null;
let WatchUtils = null;
let BasemapGallery = null;

// private functions

async function _loadModules() {
  try {
    if (_initialized) return;
    const results = await loadModules(
      [
        "esri/views/MapView",
        "esri/Map",
        "esri/layers/FeatureLayer",
        "esri/Graphic",
        "esri/widgets/Sketch",
        "esri/widgets/Sketch/SketchViewModel",
        "esri/layers/GraphicsLayer",
        "esri/views/layers/support/FeatureFilter",
        "esri/widgets/Search",
        "esri/widgets/Expand",
        "esri/widgets/Print",
        "esri/widgets/DistanceMeasurement2D",
        "esri/widgets/AreaMeasurement2D",
        "esri/core/watchUtils",
        "esri/widgets/BasemapGallery",
        "esri/identity/IdentityManager",
      ],
      { css: true }
    );
    MapView = results[0];
    Map = results[1];
    FeatureLayer = results[2];
    Graphic = results[3];
    //Sketch = results[4];
    SketchViewModel = results[5];
    GraphicsLayer = results[6];
    //FeatureFilter = results[7];
    Search = results[8];
    Expand = results[9];
    Print = results[10];
    Distance = results[11];
    Area = results[12];
    WatchUtils = results[13];
    BasemapGallery = results[14];
    _esriId = results[15];
    _initialized = true;
  } catch (error) {
    console.log("_loadModules: ", error);
  }
}

//

async function _getToken() {
  try {
    var result = await request({ url: `${url}/gis/token/${apiCode}` });

    const properties = {
      server: "https://www.arcgis.com/sharing/rest/",
      token: result.token,
    };
    _esriId.registerToken(properties);
  } catch (error) {
    console.log("_getToken: ", error);
  }
}

//

function _getSymbol(geometry, color) {
  switch (geometry) {
    case "point":
      return {
        type: "simple-marker",
        //type: geometry.symbol.type,
        color: color,
        size: 6,
        outline: {
          width: 0,
          color: color,
        },
      };
    default:
      return {
        type: "simple-line",
        color: color,
        width: 1,
        style: "solid",
      };
  }
}

//

function _getRating(selectedRating = _selectedRating) {
  let ratings = _ratingsInfo.find((item) => item.field === selectedRating).ratings;
  var uniqueRatings = Array.from(new Set(ratings.map((i) => i.name))).map((name) => {
    return ratings.find((i) => i.name === name);
  });

  return uniqueRatings;
}

//

function _filterLayer(layer) {
  _view.whenLayerView(layer).then(function (featureLayerView) {
    if (_filterExpression === null) {
      featureLayerView.effect = {
        filter: null,
        excludedEffect: null,
      };
      return;
    }
    featureLayerView.effect = {
      filter: {
        where: _filterExpression,
        spatialRelationship: _selectedSpatial,
      },
      excludedEffect: "opacity(10%)",
    };
  });
}

//

function _filterAll() {
  _map.allLayers.filter((layer) => layer.type === "feature").forEach((layer) => _filterLayer(layer));
}

//

function _getRenderer(geometry) {
  if (_selectedRating) {
    return {
      type: "unique-value",
      field: _selectedRating,
      uniqueValueInfos: _getRating().map((rating) => {
        return {
          value: rating.name,
          symbol: _getSymbol(geometry, rating.color),
        };
      }),
    };
  } else {
    return {
      type: "simple",
      symbol: _getSymbol(geometry, _renderColor),
    };
  }
}

//

async function _createGraphics(features, data, gisId) {
  try {
    //console.log("%cCount", "color: red", features.length);
    sort(data).asc((i) => i.gisId);
    var newFeatures = [];
    var dataIndex = 0;
    var featureIndex = 0;
    while (isNullOrWhitespace(features[featureIndex].attributes[gisId])) featureIndex++;
    while (featureIndex < features.length && dataIndex < data.length) {
      var attributes;
      if (!isNullOrWhitespace(features[featureIndex].attributes[gisId])) {
        if (features[featureIndex].attributes[gisId] > data[dataIndex].gisId) {
          dataIndex++;
          continue;
        }
        if (features[featureIndex].attributes[gisId] === data[dataIndex].gisId) {
          attributes = data[dataIndex];
          dataIndex++;
          newFeatures.push(
            new Graphic({
              geometry: features[featureIndex].geometry,
              attributes: attributes,
            })
          );
        }
      }
      featureIndex++;
    }
    return newFeatures;
  } catch (error) {
    console.log("_createGraphics: ", error);
  }
}

function isNullOrWhitespace(input) {
  if (typeof input === "undefined" || input == null) return true;

  return input.replace(/\s/g, "").length < 1;
}

//

function _getPopupTemplate() {
  return {
    content: [
      {
        type: "fields",
        fieldInfos: _fields.map((field) => {
          return {
            fieldName: field.name,
            label: field.alias,
          };
        }),
      },
    ],
  };
}

//

function _createLayerInfo(layers) {
  var newLayers = layers.filter((layer) => _layerInfo.every((info) => info.url !== layer.url));
  newLayers.forEach((layer) => _layerInfo.push({ id: 0, url: layer.url, gisId: layer.gisId }));
  return _layerInfo.filter((info) => layers.some((layer) => layer.url === info.url));
}

//

function _setUpSketchViewModel() {
  _graphicLayer = new GraphicsLayer();
  _map.add(_graphicLayer);

  const node = document.createElement("div");
  _view.ui.add(node, "top-left");
  ReactDOM.render(<SketchButton view={_view} />, node);

  var selectButton = document.getElementById("select-by-polygon");

  selectButton.addEventListener("click", function () {
    if (_activeButton && _activeButton.id === "select-by-polygon") {
      return;
    } else {
      _setActiveButton(selectButton);
      _sketchViewModel.create("polygon");
    }
  });

  document.getElementById("clear-selection").addEventListener("click", function () {
    _clearSelection();
    _graphicLayer.removeAll();
    _highlight.forEach((i) => i.remove());
    _onClearSelection && _onClearSelection();
  });

  _sketchViewModel = new SketchViewModel({
    view: _view,
    layer: _graphicLayer,
    pointSymbol: {
      type: "simple-marker",
      color: [255, 255, 255, 0],
      size: "1px",
      outline: {
        color: "gray",
        width: 0,
      },
    },
  });

  _sketchViewModel.on("create", async function (event) {
    if (event.state === "complete") {
      var selectedFeatures = [];
      const query = {
        geometry: event.graphic.geometry,
        outFields: ["*"],
        where: _filterExpression,
        spatialRelationship: _selectedSpatial,
      };

      for (let i = 0; i < _map.allLayers.length; i++) {
        let layer = _map.allLayers.items[i];
        if (layer.type !== "feature" || !layer.visible) continue;

        var layerView = await _view.whenLayerView(layer);

        var results = await layer.queryFeatures(query);
        const graphics = results.features;
        if (graphics.length > 0) {
          _highlight.push(layerView.highlight(graphics));
          graphics.forEach((graphic) => selectedFeatures.push(graphic.attributes));
        }
      }
      _onSelection && _onSelection(selectedFeatures);
    }
  });
}

//

function _setActiveButton(selectedButton) {
  _view.focus();
  _activeButton = selectedButton;
  if (selectedButton) selectedButton.childNodes.forEach((i) => i.classList.add("helper_gis_active-Button"));
}

//

function _clearSelection() {
  var elements = document.getElementsByClassName("helper_gis_active-Button");
  for (var i = 0; i < elements.length; i++) {
    elements[i].classList.remove("helper_gis_active-Button");
  }
  _activeButton = null;
  _sketchViewModel && _sketchViewModel.cancel();
  _view && _view.graphics.removeAll();

  _view && _view.popup.close();

  if (_activeWidget) {
    _view && _view.ui.remove(_activeWidget);
    _activeWidget.destroy();
    _activeWidget = null;
  }
}

//

function _setMeasurement() {
  const node = document.createElement("div");
  _view.ui.add(node, "top-left");
  ReactDOM.render(<Measurement view={_view} />, node);

  document.getElementById("distanceButton").addEventListener("click", function () {
    if (_activeButton && _activeButton.id === "distanceButton") {
      _clearSelection();
      return;
    }
    _clearSelection();
    _setActiveButton(document.getElementById("distanceButton"));
    _activeWidget = new Distance({
      view: _view,
    });
    _activeWidget.viewModel.newMeasurement();
    _view.ui.add(_activeWidget, "top-right");
  });

  document.getElementById("areaButton").addEventListener("click", function () {
    if (_activeButton && _activeButton.id === "areaButton") {
      _clearSelection();
      return;
    }
    _clearSelection();
    _activeWidget = new Area({
      view: _view,
    });
    _activeWidget.viewModel.newMeasurement();
    _view.ui.add(_activeWidget, "top-right");
    _setActiveButton(document.getElementById("areaButton"));
  });
}

//

async function _setFilter() {
  const node = document.createElement("div");
  ReactDOM.render(<FilterMenu view={_view} />, node);
  _view.ui.add(node, "top-left");

  var expand = new Expand({
    view: _view,
    content: node,
    expandIconClass: "esri-icon-filter",
    expandTooltip: "Risk Filter",
  });

  _view.ui.add(expand, "top-left");

  document.getElementById("helper_gis_rating").addEventListener("change", function (event) {
    var select = event.target;
    _selectedRating = select.options[select.selectedIndex].value;
    _filterExpression = null;
    _filterAll();
    _map.allLayers.filter((layer) => layer.type === "feature").forEach((layer) => (layer.renderer = _getRenderer(layer.geometryType)));
    var riskSelect = document.getElementById("helper_gis_risk");
    riskSelect.value = "All";
    var conditionSelect = document.getElementById("helper_gis_condition");
    conditionSelect.value = "All";
    ratingTitle();
  });

  document.getElementById("helper_gis_risk").addEventListener("change", function (event) {
    var select = event.target;
    _selectedRisk = select.options[select.selectedIndex].value;
    _filterExpression = _selectedRisk === "All" ? null : `riskRating = '${_selectedRisk}'`;
    _filterAll();
    ratingTitle();
  });

  document.getElementById("helper_gis_condition").addEventListener("change", function (event) {
    var select = event.target;
    _selectedCondition = select.options[select.selectedIndex].value;
    _filterExpression = _selectedCondition === "All" ? null : `conditionRating = '${_selectedCondition}'`;
    _filterAll();
    ratingTitle();
  });

  document.getElementById("helper_gis_spatial").addEventListener("change", function (event) {
    var select = event.target;
    _selectedSpatial = select.options[select.selectedIndex].value;
  });
  const title = document.createElement("div");
  _view.ui.add(title, "bottom-left");
  ReactDOM.render(<RatingTitle view={_view} />, title);

  ratingTitle();

  function ratingTitle() {
    var filter = _selectedRating === "riskRating" ? _selectedRisk : _selectedCondition;
    filter = filter === "All" ? "" : `(${filter})`;
    const element = document.getElementById("helper_gis_rating-title");
    element.textContent = `${_selectedRating === "riskRating" ? "Risk" : "Condition"} Map ${filter}`;
    document.getElementById("helper_gis_risk").disabled = _selectedRating !== "riskRating";
    document.getElementById("helper_gis_condition").disabled = _selectedRating !== "conditionRating";
  }
}

//

function _setPrintWidget() {
  var print = new Print({
    view: _view,
    printServiceUrl: "https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task",
  });

  var expand = new Expand({
    view: _view,
    content: print,
    label: "Print",
  });

  _view.ui.add(expand, "top-left");
}

function _setSearchWidget() {
  _search = new Search({
    view: _view,
    allPlaceholder: "Asset Name / Id",
    includeDefaultSources: false,
    activeMenu: "none",
  });

  _view.ui.add(_search, "top-right");
}

function _setBasemapWidget() {
  var basemapGallery = new BasemapGallery({
    view: _view,
    container: document.createElement("div"),
  });

  var bgExpand = new Expand({
    view: _view,
    content: basemapGallery,
  });

  // close the expand whenever a basemap is selected
  basemapGallery.watch("activeBasemap", function () {
    var mobileSize = _view.heightBreakpoint === "xsmall" || _view.widthBreakpoint === "xsmall";

    if (mobileSize) {
      bgExpand.collapse();
    }
  });

  _view.ui.add(bgExpand, "bottom-right");
}

// public functions

export async function initialize(mapRef, fields, ratings, selectedRating, onSelection, onClearSelection, onClick, useRenderer) {
  try {
    _fields = fields;
    _ratingsInfo = ratings;
    _selectedRating = selectedRating;
    _onSelection = onSelection;
    _onClearSelection = onClearSelection;
    _onClick = onClick;

    _selectedRisk = "All";
    _selectedCondition = "All";
    _selectedSpatial = "intersects";
    _filterExpression = null;
    _useRenderer = useRenderer;

    await _loadModules();
    await _getToken();

    let org = store.getState().org;
    let { gisBasemap, gisZoom, gisCenterLatitude, gisCenterLongitude, gisRenderColor } = org;
    _renderColor = gisRenderColor;

    _map = new Map({
      basemap: gisBasemap,
    });

    _view = new MapView({
      container: mapRef.current,
      map: _map,
      center: [gisCenterLongitude, gisCenterLatitude],
      zoom: gisZoom,
    });

    _view.on("click", function (event) {
      _view.hitTest(event).then(function (response) {
        if (response.results.length) {
          if (response.results[0].graphic.geometry) {
            _onClick && _onClick(response.results[0].graphic.attributes);
          }
        }
      });
    });

    WatchUtils.whenTrue(_view, "updating", function (evt) {});

    WatchUtils.whenFalse(_view, "updating", function (evt) {});

    _setSearchWidget();
    _ratingsInfo && _setFilter();
    _setUpSketchViewModel();
    _setMeasurement();
    _setPrintWidget();
    _setBasemapWidget();
  } catch (error) {
    console.log("initialize: ", error);
  }
}

//

export function clear() {
  document.getElementById("gis_indicator").style.display = "block";
  _map.removeAll();
}

export async function setLayers(layers, assets) {
  try {
    _search.sources.removeAll();

    var layerInfo = _createLayerInfo(layers);
    //_map.allLayers.filter((layer) => layer.type === "feature").forEach((layer) => (layer.visible = false));

    for (let info of layerInfo) {
      if (!layers.find((i) => i.url === info.url)) continue;

      if (!info.data) {
        var layer = new FeatureLayer({
          url: info.url,
          outFields: [info.gisId],
        });
        await layer.load();
        var query = layer.createQuery();
        query.outFields = [info.gisId];
        var features = await (await layer.queryFeatures(query)).features;
        sort(features).asc((i) => i.attributes[info.gisId]);
        info.data = features;
        info.title = layer.title;
        info.geometryType = layer.geometryType;
        info.renderer = layer.renderer;
      }
      layer = new FeatureLayer({
        title: info.title,
        objectIdField: "ObjectId",
        outFields: ["*"],
        source: await _createGraphics(info.data, assets, info.gisId),
        fields: _fields,
        //popupTemplate: _getPopupTemplate(),
        geometryType: info.geometryType,
        renderer: _useRenderer ? _getRenderer(info.geometryType, _ratingsInfo && _ratingsInfo.find((item) => item.selected).field) : info.renderer,
      });
      _map.add(layer);
      info.id = layer.id;
      //var layerView = await _view.whenLayerView(layer);
      //layerView.filter = { where: "visible > 0" };
      _search.sources.push({
        layer: layer,
        searchFields: ["name"],
        exactMatch: true,
        outFields: ["*"],
        name: layer.title,
        placeholder: "Asset Name / Id",
        zoomScale: 18,
      });
      _filterLayer(layer);
    }
  } catch (error) {
    console.log("createLayers: ", error);
  } finally {
    document.getElementById("gis_indicator").style.display = "none";
  }
}

//

export async function selectFeatureFromId(id) {
  _view.popup.close();
  const query = {
    where: `gisId = '${id}' `,
    outFields: ["*"],
    returnGeometry: true,
  };

  for (let i = 0; i < _map.allLayers.length; i++) {
    let layer = _map.allLayers.items[i];
    if (layer.type !== "feature") continue;

    let results = await layer.queryFeatures(query);

    const graphics = results.features;
    if (graphics.length === 0) continue;

    _view.graphics.removeAll();

    const selectedGraphic = new Graphic({
      geometry: graphics[0].geometry,
      symbol: {
        type: "simple-marker",
        style: "circle",
        color: "orange",
        size: "12px",
        outline: {
          color: [255, 255, 0],
          width: 2,
        },
      },
    });

    _view.graphics.add(selectedGraphic);
  }
}

export function dispose() {
  _clearSelection();

  _map = null;
  _search = null;
  _layerInfo = [];
  _highlight = [];
  if (_view) {
    _view.container = null;
    _view = null;
  }
}

// Components

function SketchButton() {
  return (
    <React.Fragment>
      <div id="select-by-polygon" className="esri-widget esri-widget--button esri-interactive" title="Select features by polygon">
        <span className="esri-icon-polygon"></span>
      </div>
      <div id="clear-selection" className="esri-widget esri-widget--button esri-widget esri-interactive" title="Clear selection">
        <span className="esri-icon-close-circled"></span>
      </div>
    </React.Fragment>
  );
}

function Measurement() {
  return (
    <React.Fragment>
      <div
        id="distanceButton"
        className=" esri-widget esri-widget--button esri-widget esri-interactive"
        title="Measure distance between two or more points"
      >
        <span className=" action-button esri-icon-measure-line "></span>
      </div>
      <div id="areaButton" className="esri-widget esri-widget--button esri-widget esri-interactive" title="Measure area">
        <span className="action-button esri-icon-measure-area"></span>
      </div>
    </React.Fragment>
  );
}

function RatingTitle() {
  return (
    <div className="esri-widget">
      <div id="helper_gis_rating-title">Text</div>
    </div>
  );
}

function FilterMenu() {
  return (
    <div id="helper_gis_infoDiv" className="esri-widget">
      <b>Filters</b>
      <br />
      <br />
      <label htmlFor="helper_gis_rating">Rating:</label>
      <select id="helper_gis_rating" className="helper_gis_options">
        <option value="riskRating">Risk</option>
        <option value="conditionRating">Condition</option>
      </select>
      <br />
      <br />
      <label htmlFor="helper_gis_risk">Risk:</label>
      <select id="helper_gis_risk" className="helper_gis_options">
        <option value="All">All</option>
        {_getRating("riskRating").map((i) => (
          <option key={`${i.name}`} value={`${i.name}`}>{`${i.name}`}</option>
        ))}
      </select>
      <br />
      <br />
      <label htmlFor="helper_gis_condition">Condition:</label>
      <select id="helper_gis_condition" className="helper_gis_options">
        <option value="All">All</option>
        {_getRating("conditionRating").map((i) => (
          <option key={`${i.name}`} value={`${i.name}`}>{`${i.name}`}</option>
        ))}
      </select>
      <br />
      <br />
      <label htmlFor="helper_gis_spatial">Spatial:</label>
      <select id="helper_gis_spatial" className="helper_gis_options">
        <option value="intersects">Intersects</option>
        <option value="contains">Contains</option>
        <option value="disjoint">Disjoint</option>
      </select>
      <br />
      <br />
    </div>
  );
}
