import React, { useRef } from "react";
import styles from "./task.module.scss";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { addFilter, withMemo } from "helper/global";
import { Column } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";

function Component(props) {
  // stores

  const taskStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOption) => {
        if (!props.projectId) return Promise.resolve({ data: [] });
        return exporting.current
          ? request({ url: `${url}/task/download`, loadOptions: loadOption })
          : request({ url: `${url}/task`, loadOptions: addFilter(loadOption, "and", ["projectId", "=", props.projectId]) });
      },
      insert: (data) => {
        data.projectId = props.projectId;
        return request({ url: `${url}/task`, method: "Post", data: data });
      },
      update: (_, data) => {
        data.projectId = props.projectId;
        return request({ url: `${url}/task`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/task/${id}`, method: "Delete" });
      },
      onModified: () => {
        props.clearHome();
      },
    }),
  };

  // hooks

  const exporting = useRef(false);

  // event handlers

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_TASK", url: `${url}/task/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.beginUpdate();
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("project", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("project", "visible", false);
    exporting.current = false;
  }

  return (
    <ProgressPanel name={"UPLOAD_TASK"} onComplete={props.clearHome}>
      <Grid title={"Tasks"} dataSource={taskStore} onUploaded={onUploaded} onExported={onExported} onExporting={onExporting}>
        <Column visible={false} dataField={"id"} formItem={{ visible: false }} />
        <Column
          caption={"Operation"}
          name={"operation"}
          calculateCellValue={() => "Update"}
          width={100}
          visible={false}
          formItem={{ visible: false }}
        />

        <Column caption={"Project"} dataField={"project"} visible={false} allowFiltering={true} width={200} formItem={{ visible: false }} />
        <Column caption={"Name"} dataField={"name"} allowFiltering={true} width={200} />

        <Column caption={"Description"} dataField={"description"} allowFiltering={true} width={200} />
        <Column caption={"DateTime"} dataField={"dateTime"} dataType={"date"} width={100} />
        <Column caption={"Value"} dataField={"value"} dataType={"number"} width={100} />
        <Column caption={"Completed"} dataField={"completed"} dateType="boolean" width={100} formItem={{ editorType: "dxCheckBox" }} />
        <Column visible={false} formItem={{ itemType: "empty" }} />
        <Column
          caption={"Notes"}
          dataField={"notes"}
          allowFiltering={true}
          //width={400}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
        />
      </Grid>
    </ProgressPanel>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
    clearHome: () => dispatch(actions.clearHome()),
  };
};

export default connect(null, mapDispatchToProps)(withMemo(Component, ["projectId"]));
