import React, { useEffect, useRef, useContext } from "react";
import Chart from "components/chart/chart";
import { url } from "config.js";
import { currencyFormat, dict, withMemo } from "helper/global";
import styles from "./profile.module.scss";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { request } from "helper/http-client";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { connect } from "react-redux";
import {
  CommonSeriesSettings,
  Series,
  ValueAxis,
  ArgumentAxis,
  Legend,
  Label,
  Point,
  MinorTick,
  Format,
  Tooltip,
  ConstantLine,
  Font,
} from "devextreme-react/chart";
import Card from "./card/card";
import { useImmerReducer } from "use-immer";
import ProgressContext from "components/progress-panel/context";
import HierarchyContext from "components/hierarchy/context";

function Component() {
  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  return selectedRowsData.length > 0 && <Profile selectedRowsData={selectedRowsData} />;
}

export default Component;

const mapStateToProps = (state) => {
  return {
    firstYear: state.scenario.firstYear,
    lastYear: state.scenario.firstYear + state.scenario.planningHorizon,
  };
};

const Profile = connect(
  mapStateToProps,
  null
)(
  withMemo(
    (props) => {
      function reducer(draft, action) {
        switch (action.type) {
          case "data":
            draft.data = action.payload;
            draft.loading = false;
            return;
          case "escalate":
            draft.escalate = !draft.escalate;
            draft.render++;
            return;
          case "loading":
            draft.loading = !draft.loading;
            return;
          case "smoothing":
            draft.smoothing = action.payload;
            draft.render++;
            return;
          default:
            return;
        }
      }

      // hooks

      const chartRef = useRef(null);
      const renderChart = useRef(false);

      const stateContext = useContext(ProgressContext);
      const previousState = stateContext.state;
      const onStateChanged = stateContext.onStateChanged;

      const intialState = {
        data: null,
        escalate: (previousState && previousState.escalate) || false,
        render: 0,
        loading: false,
        smoothing: (previousState && previousState.smoothing) || 0,
      };

      const [state, dispatch] = useImmerReducer(reducer, intialState);

      useEffect(() => {
        if (state.year) {
          renderChart.current = true;
          return;
        }
        onStateChanged(state);
        (async () => {
          try {
            dispatch({ type: "loading" });
            var result = await request({
              url: `${url}/tool/smoothing/cost-profile`,
              params: { ids: props.selectedRowsData.map((i) => i.id), escalate: state.escalate, smoothing: state.smoothing },
            });
            dispatch({ type: "data", payload: result });
          } catch (error) {
            console.log(error);
          }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.selectedRowsData, state.render]);

      // event handlers

      function onPointClick(e) {
        var year = e.target.originalArgument;
        dispatch({ type: "year", payload: { year: year } });
      }

      function onEscalateChanged(e) {
        dispatch({ type: "escalate" });
      }

      function onInitialized(e) {
        chartRef.current = e.component;
      }

      function onSmoothingChanged(e) {
        dispatch({ type: "smoothing", payload: e.value });
      }

      // render

      function cardRender(title, value) {
        return <Card title={title} value={value}></Card>;
      }

      function customizeText(e) {
        return { text: `Year: ${e.argument}, Value: ${currencyFormat(e.value)}` };
      }

      function toolbarRender() {
        return (
          <div className={`${styles.toolbar} theme-toolbar`}>
            <Toolbar>
              <Item location="before" render={() => cardRender(`${dict("Total Cost")}:`, currencyFormat(state.data.header.totalCost))} />
              <Item location="before" render={() => cardRender(`${dict("Annual Cost")}:`, currencyFormat(state.data.header.annualCost))} />
              {/* <Item location="before" render={() => cardRender(`${dict("Replacement Cost")}:`, currencyFormat(state.data.header.replacementCost))} /> */}
              <Item location="after" locateInMenu="never">
                <div className={styles.toolbar_item}>Smoothing:</div>
              </Item>
              <Item
                location="after"
                locateInMenu="auto"
                widget="dxSelectBox"
                visible={true}
                options={{
                  valueExpr: "id",
                  displayExpr: "text",
                  value: state.smoothing,
                  items: [
                    { id: 0, text: "None" },
                    { id: 1, text: "Activity Cost" },
                    { id: 2, text: "Useful Life" },
                  ],
                  onValueChanged: onSmoothingChanged,
                }}
              />
              <Item location="after" locateInMenu="never">
                <div className={styles.toolbar_item}>{`${dict("Escalate")}:`}</div>
              </Item>
              <Item location="after" locateInMenu="auto" widget="dxCheckBox" options={{ value: state.escalate, onValueChanged: onEscalateChanged }} />
            </Toolbar>
          </div>
        );
      }

      const profileRender = () => {
        return (
          <div className={styles.chart}>
            <Chart dataSource={state.data.profile} onPointClick={onPointClick} onInitialized={onInitialized}>
              <CommonSeriesSettings argumentField="year" />
              <Legend visible={false} />
              <Tooltip enabled={true} customizeTooltip={customizeText} />
              <ArgumentAxis allowDecimals={false} minorTickCount={4} visualRange={[props.firstYear, props.lastYear - 1]}>
                <MinorTick visible={false} />
                <Label>
                  <Format type="decimal" />
                </Label>
              </ArgumentAxis>
              <ValueAxis minValueMargin={0} maxValueMargin={0} title="">
                <ConstantLine width={2} value={state.data.header.annualCost} color={"#bb86fc"} dashStyle={"longDash"}>
                  <Label visible={false} horizontalAlignment={"center"}>
                    <Font size={14} color={"#fff"} weight={500}></Font>
                  </Label>
                </ConstantLine>
              </ValueAxis>
              <Series valueField="value" type="bar">
                <Point visible={false} />
              </Series>
            </Chart>
          </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
      };

      function indicatorRender() {
        return (
          <div className={styles.loading}>
            <LoadIndicator />
          </div>
        );
      }

      return (
        <div className={styles.main}>
          {state.data && toolbarRender()}
          {state.data && profileRender()}
          {state.loading && indicatorRender()}
        </div>
      );
    },
    ["selectedRowsData"]
  )
);
