import axios from "axios";
import { api } from "config.js";
import { store } from "index.js";
import { expire } from "redux/actions";
import { confirm } from "devextreme/ui/dialog";
import { notify } from "helper/global";

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && 401 === error.response.status) {
      store.dispatch(expire());
    } else {
      return Promise.reject(error);
    }
  }
);

// export function httpRequest(url, method = "Get", data = {}, options) {
//   url = `${api}/${url}`;
//   if (method === "Get" && isNotEmpty(options)) {
//     let optionsParams = "?";
//     ["skip", "take", "requireTotalCount", "requireGroupCount", "sort", "filter", "totalSummary", "group", "groupSummary", "search"].forEach(function (
//       i
//     ) {
//       if (i in options && isNotEmpty(options[i])) {
//         optionsParams += `${i}=${JSON.stringify(options[i])}&`;
//       }
//     });
//     optionsParams = optionsParams.slice(0, -1);
//     url = `${url}/${optionsParams}`;
//   }

//   if (process.env.REACT_APP_CUSTOM_NODE_ENV === "Development") console.log("URL: ", url);

//   return axios({
//     method: method,
//     url: url,
//     data: data,
//     withCredentials: true,
//   })
//     .then((response) => {
//       const result = response.data;
//       //if (process.env.REACT_APP_CUSTOM_NODE_ENV === "Development") console.log("RESULT", { Result: result, Url: url });
//       if (options) {
//         return {
//           data: result.data,
//           totalCount: result.totalCount,
//           summary: result.summary,
//           groupCount: result.groupCount,
//         };
//       } else {
//         return result;
//       }
//     })
//     .catch((error) => {
//       if (error.response) {
//         throw Error(error.response.data);
//       } else if (error.request) {
//         throw Error(error.request);
//       } else {
//         throw Error(error.message);
//       }
//     });
// }

// export function webRequest(url, method = "Get", data = {}, options) {
//   url = `${api}/${url}`;
//   var dev = process.env.REACT_APP_CUSTOM_NODE_ENV === "Development";

//   if (method === "Get" && isNotEmpty(options)) {
//     let optionsParams = "";
//     ["skip", "take", "requireTotalCount", "requireGroupCount", "sort", "filter", "totalSummary", "group", "groupSummary"].forEach(function (i) {
//       if (i in options && isNotEmpty(options[i])) {
//         optionsParams += `${i}=${JSON.stringify(options[i])}&`;
//       }
//     });
//     optionsParams = optionsParams.slice(0, -1);
//     url = url.includes("?") ? `${url}&${optionsParams}` : `${url}?${optionsParams}`;
//   }

//   if (dev) console.log("WEB REQUEST URL: ", url);

//   return axios({
//     method: method,
//     url: url,
//     data: data,
//     withCredentials: true,
//     // crossDomain: true
//   })
//     .then((response) => {
//       const result = response.data;
//       if (dev) console.log("WEB REQUEST RESULT", result);
//       return result;
//     })
//     .catch((error) => {
//       if (error.response) {
//         throw Error(error.response.data);
//       } else if (error.request) {
//         throw Error(error.request);
//       } else {
//         throw Error(error.message);
//       }
//     });
// }

// export function httpRequestBlob(url) {
//   url = `${api}/${url}`;

//   const cancelToken = axios.CancelToken;
//   const source = cancelToken.source();

//   function onCancel(e) {
//     source.cancel(e);
//   }

//   var promise = axios({
//     method: "Post",
//     url: url,
//     cancelToken: source.token,
//     responseType: "blob",
//   })
//     .then((response) => {
//       if (response.data.size > 0) {
//         let result = confirm("<i>Operation failed. Do you want to download the error log file?</i>", "Error");
//         result.then((dialogResult) => {
//           if (dialogResult) {
//             url = window.URL.createObjectURL(new Blob([response.data]));
//             const link = document.createElement("a");
//             link.href = url;
//             link.setAttribute("download", "errorlog.txt");
//             document.body.appendChild(link);
//             link.click();
//             notify("File downloading", "success", 600);
//           }
//         });
//       }
//     })
//     .catch((error) => {
//       if (axios.isCancel(error)) {
//         notify("Operation cancelled by user", "warning");
//       }
//       if (error.response) {
//         throw Error(error.response.data);
//       } else if (error.request) {
//         throw Error(error.request);
//       } else {
//         throw Error(error.message);
//       }
//     });

//   return [promise, onCancel];
// }

export function requestBlob(options) {
  let { url, params = null } = options;

  url = `${api}/${url}`;

  if (params) url = url + "?" + getQueryParams(params);

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  function onCancel(e) {
    source.cancel(e);
  }

  var promise = axios({
    method: "Post",
    url: url,
    cancelToken: source.token,
    responseType: "blob",
  })
    .then((response) => {
      if (response.data.size > 0) {
        let result = confirm("<i>Operation failed. Do you want to download the error log file?</i>", "Error");
        result.then((dialogResult) => {
          if (dialogResult) {
            url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "errorlog.txt");
            document.body.appendChild(link);
            link.click();
            notify("File downloading", "success", 600);
          }
        });
      }
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        notify("Operation cancelled by user", "warning");
      }
      if (error.response) {
        throw Error(error.response.data);
      } else if (error.request) {
        throw Error(error.request);
      } else {
        throw Error(error.message);
      }
    });

  return [promise, onCancel];
}

export function request(options) {
  if (!options.url) {
    console.log("OPTIONS", options);
  }

  let { url, method = "Get", data = null, loadOptions = null, params = null } = options;

  url = `${api}/${url}`;
  var dev = process.env.REACT_APP_CUSTOM_NODE_ENV === "Development";

  if (params) url = url.includes("?") ? url + getQueryParams(params) : url + "?" + getQueryParams(params);

  if (method === "Get" && loadOptions) {
    let p = "";
    ["skip", "take", "sort", "filter"].forEach(function (i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        p += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    p = p.slice(0, -1);
    url = url + (url.includes("?") ? "&" + p : "?" + p);
  }

  if (dev) console.log(`METHOD: ${method}`, "REQUEST URL: ", `${url}`);

  return axios({
    method: method,
    url: url,
    data: data,
    withCredentials: true,
  })
    .then((response) => {
      if (dev) console.log("REQUEST RESULT", response.data);
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        throw Error(error.response.data);
      } else if (error.request) {
        throw Error(error.request);
      } else {
        throw Error(error.message);
      }
    });
}

// export function request(url, method = "Get", data = null, loadOptions = null, params = null, arrayParams = null) {
//   url = `${api}/${url}`;
//   var dev = process.env.REACT_APP_CUSTOM_NODE_ENV === "Development";

//   if (params) {
//     let p = params.map((i) => `${i.name}=${i.value}`).join("&");
//     url = url + "?" + p;
//   }

//   if (arrayParams) {
//     arrayParams.forEach((obj) => {
//       let p = obj.values.map((i) => `${obj.name}=${i}`).join("&");
//       url = url + (params ? "&" + p : "?" + p);
//     });
//   }

//   if (method === "Get" && options) {
//     let p = "";
//     ["skip", "take", "sort", "filter"].forEach(function (i) {
//       if (i in options && isNotEmpty(options[i])) {
//         p += `${i}=${JSON.stringify(options[i])}&`;
//       }
//     });
//     p = p.slice(0, -1);
//     // url = url.includes("?") ? `${url}&${optionsParams}` : `${url}?${optionsParams}`;
//     url = url + (params || arrayParams ? "&" + p : "?" + p);
//   }

//   if (dev) console.log("REQUEST URL: ", url);

//   return axios({
//     method: method,
//     url: url,
//     data: data,
//     withCredentials: true,
//   })
//     .then((response) => {
//       if (dev) console.log("REQUEST RESULT", response.data);
//       return response.data;
//     })
//     .catch((error) => {
//       if (error.response) {
//         throw Error(error.response.data);
//       } else if (error.request) {
//         throw Error(error.request);
//       } else {
//         throw Error(error.message);
//       }
//     });
// }

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

export function getQueryParams(params) {
  let p = [];
  for (let key in params) {
    if (Array.isArray(params[key])) params[key].map((i) => p.push(`${key}=${i}`));
    else p.push(`${key}=${params[key]}`);
  }
  return p.join("&");
}
