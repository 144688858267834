import React, { useRef } from "react";
import Grid from "components/data-grid/data-grid.js";
import styles from "./phase.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { addFilter } from "helper/global";

function Component(props) {
  //
  // stores

  const activityPhaseStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!props.activityId) return Promise.resolve([]);
        return exporting.current
          ? request({
              url: `${url}/activityphase/download`,
              loadOptions: loadOptions,
            })
          : request({
              url: `${url}/activityphase`,
              loadOptions: addFilter(loadOptions, "and", ["activityId", "=", props.activityId]),
            });
      },
      insert: (data) => {
        return request({ url: `${url}/activityphase`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/activityphase`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/activityphase/${id}`, method: "Delete" });
      },
    }),
  };

  const activityPhaseTypeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/activityphasetype`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/activityphasetype/${id}` });
      },
    }),
  };

  // hooks

  const exporting = useRef(false);

  // event handlers

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_ACTIVITYPHASE", url: `${url}/activityphase/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("classId", "visible", true);
    e.component.columnOption("strategy", "visible", true);
    e.component.columnOption("activityName", "visible", true);
    e.component.columnOption("activityType", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("classId", "visible", false);
    e.component.columnOption("strategy", "visible", false);
    e.component.columnOption("activityName", "visible", false);
    e.component.columnOption("activityType", "visible", false);
    exporting.current = false;
  }

  // render

  return (
    <ProgressPanel name={"UPLOAD_ACTIVITYPHASE"}>
      <div className={styles.main}>
        <Grid
          title={"Phase"}
          dataSource={activityPhaseStore}
          onUploaded={onUploaded}
          onExported={onExported}
          onExporting={onExporting}
          onInitNewRow={(e) => {
            e.data.activityId = props.activityId;
          }}
        >
          <Column visible={false} dataField={"id"} sortOrder={"desc"} allowEditing={false} formItem={{ visible: false }} />
          <Column
            caption={"Operation"}
            name={"operation"}
            calculateCellValue={() => "Update"}
            width={100}
            visible={false}
            allowEditing={false}
            formItem={{ visible: false }}
          />
          <Column caption={"Type"} dataField={"typeId"} calculateDisplayValue="type">
            <Lookup dataSource={activityPhaseTypeStore} valueExpr={"id"} displayExpr="name" />
            <RequiredRule />
          </Column>

          <Column caption={"Delta Year"} dataField={"year"} dataType={"number"} width={100} />
          <Column
            caption={"Percent"}
            dataField={"percent"}
            dataType={"number"}
            format={`###.##%`}
            width={100}
            formItem={{ editorOptions: { format: `###.##%`, showClearButton: true } }}
          />
          <Column caption={"Strategy"} dataField={"strategy"} visible={false} formItem={{ visible: false }} />
          <Column caption={"Activity"} dataField={"activity"} visible={false} formItem={{ visible: false }}></Column>
          <Column caption={"Activity Type"} dataField={"activityType"} visible={false} formItem={{ visible: false }}></Column>
        </Grid>
      </div>
    </ProgressPanel>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);
