import React from "react";
import List from "components/list/list.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, FilterRow, Sorting } from "devextreme-react/data-grid";

function Component(props) {
  //
  // stores
  const assetStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (props.selectedRowsData.length === 0) return Promise.resolve([]);
        return request({
          url: `${url}/engine/output/nodes`,
          params: { ids: props.selectedRowsData.map((i) => i.id), filterExpression: props.filter },
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // render

  function onContextMenuPreparing(e) {
    e.items.push({
      text: "Run",
      icon: "fas fa-play",
      onItemClick: props.onRun,
    });
    e.items.push({
      text: "Edit",
      icon: "fas fa-edit",
      onItemClick: props.onEdit,
    });
  }

  return (
    <List
      title={"Assets"}
      dataSource={assetStore}
      selection={{ mode: "single" }}
      onSelectionChanged={props.onSelectionChanged}
      onContextMenuPreparing={onContextMenuPreparing}
      resetRowSelectionOnLoad={true}
      allowAdding={false}
      allowDeleting={false}
      allowUpdating={false}
      allowDownloading={false}
      onInitialized={props.onInitialized}
    >
      <FilterRow visible={true} />
      <Sorting mode="none" />
      <Column visible={false} dataField={"id"} allowEditing={false} formItem={{ visible: false }} />
      <Column caption={"AssetId"} visible={true} dataField={"name"} width={100} />
      <Column caption={"Description"} dataField={"description"} />
    </List>
  );
}

export default React.memo(Component, (prev, next) => {
  return prev.selectedRowsData === next.selectedRowsData && prev.filter === next.filter;
});
