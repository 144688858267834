import React, { useRef } from "react";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { addFilter, withMemo } from "helper/global";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { RequiredRule, NumericRule } from "devextreme-react/validator";

function Component(props) {
  //
  // hooks

  const exporting = useRef(false);

  // stores

  const measurementStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (exporting.current) {
          return request({
            url: `${url}/measurement/download`,
            loadOptions: loadOptions,
            params: { ids: props.selectedHierarchyNodes.map((i) => i.id) },
          });
        } else {
          if (!props.assetId) return Promise.resolve({ data: [] });
          return request({ url: `${url}/measurement`, loadOptions: addFilter(loadOptions, "and", ["assetId", "=", props.assetId]) });
        }
      },
      insert: (data) => {
        data.assetId = props.assetId;
        return request({ url: `${url}/measurement`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/measurement`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/measurement/${id}`, method: "Delete" });
      },
    }),
  };

  const measurementTypeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/measurementType`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/measurementType/${id}` });
      },
    }),
  };

  // event Handler

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_MEASUREMENT", url: `${url}/measurement/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("node", "visible", true);
    e.component.columnOption("unit", "visible", false);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("node", "visible", false);
    e.component.columnOption("unit", "visible", true);
    exporting.current = false;
  }

  // render

  const measurementRender = () => {
    return (
      <Grid
        title={"Measurement"}
        showTitle={false}
        dataSource={measurementStore}
        onExported={onExported}
        onExporting={onExporting}
        onUploaded={props.selectedHierarchyNodes && onUploaded}
        allowDownloading={props.allowDownloading === undefined || props.allowDownloading}
      >
        <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
        <Column
          caption={"Operation"}
          name={"operation"}
          calculateCellValue={() => "Update"}
          width={100}
          visible={false}
          formItem={{ visible: false }}
        />
        <Column visible={false} caption={"AssetId"} dataField={"node"} formItem={{ visible: false }} />
        <Column caption={"Type"} dataField={"typeId"} calculateDisplayValue="type" width={200}>
          <Lookup dataSource={measurementTypeStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Unit"} dataField={"unit"} allowFiltering={true} width={200} allowEditing={false} formItem={{ visible: false }} />
        <Column caption={"Value"} dataField={"value"} dataType={"number"} allowFiltering={true} width={200}>
          <NumericRule />
        </Column>
      </Grid>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return <ProgressPanel name={"UPLOAD_MEASUREMENT"}>{measurementRender()}</ProgressPanel>;
}

// redux

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(withMemo(Component, ["assetId", "selectedHierarchyNodes"]));
