import React, { useState, useEffect } from "react";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import TagBox from "components/tag-box/tag-box";

function Component(props) {
  // stores

  const tagStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/tag`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/tag/${id}` });
      },
    }),
  };

  // useState
  const [state, setState] = useState([]);

  useEffect(() => {
    request({ url: `${url}/nodetag/node/${props.data.id}` })
      .then((results) => {
        setState(results.tagIds);
      })
      .catch((error) => {});
  }, [props.data]);

  // event handlers

  async function onValueChanged(e) {
    await request({ url: `${url}/nodetag`, method: "Put", data: { nodeId: props.data.id, tagIds: e } });
  }

  // render

  return (
    <div>
      <TagBox
        dataSource={tagStore}
        selectedTags={state}
        displayExpr="name"
        valueExpr="id"
        placeholder={"Click to select tags..."}
        onValueChanged={onValueChanged}
      />
    </div>
  );
}

export default Component;
