import React from "react";
import styles from "./yearly-activity.module.scss";
import CustomStore from "devextreme/data/custom_store";
import Activity from "components/activity-grid/activity-grid";
import { request } from "helper/http-client";
import { url } from "config.js";

function Component(props) {
  //
  // stores
  const activityStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/engine/output/activities`,
          params: { ids: props.selectedRowsData.map((i) => i.id), year: props.year, filterExpression: props.filter },
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      visible: !!props.onBackButtonClick,
      options: {
        hint: "Back",
        type: "back",
        onClick: props.onBackButtonClick,
      },
    });
  }

  return (
    <div className={styles.main}>
      <Activity dataSource={activityStore} title={`Activities Year: ${props.year}`} onToolbarPreparing={onToolbarPreparing} />
    </div>
  );
}

export default Component;
