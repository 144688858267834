import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import styles from "./lookup.module.scss";
import { selectedTabItem } from "helper/global";
import Revenue from "./revenue-type/revenue-type";
import Expenditure from "./expenditure-type/expenditure-type";
import ActivityPhaseType from "./activityphase-type/activityphase-type";
import { dict } from "helper/global";

function Lookup(props) {
  // tabs

  const tabItems = [
    {
      text: dict("Revenue"),
      icon: "fad fa-inbox-in",
      path: "/revenuetype",
    },
    {
      text: dict("Expenditure"),
      icon: "fad fa-inbox-out",
      path: "/expendituretype",
    },
    {
      text: dict("Phase"),
      icon: "fad fa-chart-network",
      path: "/activityphasetype",
    },
  ];

  // functions

  function changeView(e) {
    props.history.push(`/klient/finance/lookup${e.path}`);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView} />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route exact path={["/klient/finance", "/klient/finance/lookup"]} component={Revenue} />
          <Route exact path="/klient/finance/lookup/revenuetype" component={Revenue} />
          <Route exact path="/klient/finance/lookup/expendituretype" component={Expenditure} />
          <Route exact path="/klient/finance/lookup/activityphasetype" component={ActivityPhaseType} />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

export default Lookup;
