import React, { useRef } from "react";
import styles from "./asset.module.scss";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { withMemo } from "helper/global";
import { Column, Lookup } from "devextreme-react/data-grid";

function Component(props) {
  //
  // stores

  const connectAssetStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!props.endpointId) return Promise.resolve({ data: [] });
        return request({ url: `${url}/connectasset/endpoint/${props.endpointId}`, loadOptions: loadOptions });
      },
      update: (_, data) => {
        data.endpointId = props.endpointId;
        return request({ url: `${url}/connectasset`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/connectasset/${id}`, method: "Delete" });
      },
    }),
  };

  const conditionRatingStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/conditionrating/basescenario`, loadOptions: loadOptions });
      },
      byKey: function (key) {
        return request({ url: `${url}/conditionrating/${key}` });
      },
    }),
  };

  // hooks

  const dataGrid = useRef(null);

  // event handlers

  function onInitialized(e) {
    dataGrid.current = e.component;
  }

  function isNewAssetIconVisible(e) {
    return e.row.data.isNew;
  }

  function isAcceptIconVisible(e) {
    return !e.row.data.isNew && !e.row.data.isResolved && e.row.data.conditionRatingId !== null;
  }

  async function createNewAsset(e) {
    await request({ url: `${url}/connectasset/asset/${e.row.data.id}`, method: "Post" });
    dataGrid.current.refresh();
  }

  async function resolve(e) {
    await request({ url: `${url}/connectasset/resolve/${e.row.data.id}`, method: "Post" });
    dataGrid.current.refresh();
  }

  // render

  return (
    <div className={styles.main}>
      <Grid
        title={"Asset"}
        dataSource={connectAssetStore}
        showButtons={false}
        onInitialized={onInitialized}
        allowAdding={false}
        allowDownloading={false}
      >
        <Column visible={false} dataField={"id"} formItem={{ visible: false }} />

        <Column caption={"Asset Id"} dataField={"name"} allowFiltering={true} width={200} />
        <Column caption={"Asset Description"} dataField={"description"} allowFiltering={true} width={200} formItem={{ visible: false }} />
        <Column caption={"Reported Condition"} dataField={"conditionRatingId"} calculateDisplayValue="conditionRating" width={150}>
          <Lookup dataSource={conditionRatingStore} valueExpr={"id"} displayExpr="name" />
        </Column>
        <Column caption={"Existing Condition"} dataField={"existingConditionRating"} width={150} formItem={{ visible: false }} />
        <Column visible={false} formItem={{ itemType: "empty" }} />
        <Column
          caption={"Notes"}
          dataField={"notes"}
          allowFiltering={true}
          width={400}
          formItem={{ visible: false, colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
        />
        <Column caption={"Workorder Id"} dataField={"workorderName"} allowFiltering={true} width={125} formItem={{ visible: false }} />
        <Column
          caption={"Workorder Description"}
          dataField={"workorderDescription"}
          allowFiltering={true}
          width={200}
          formItem={{ visible: false }}
        />
        <Column
          caption={"Workorder Activity Comments"}
          dataField={"workorderComments"}
          allowFiltering={true}
          width={300}
          formItem={{ visible: false }}
        />
        <Column
          caption={"Workorder Activity Log"}
          dataField={"workorderActivityLog"}
          allowFiltering={true}
          width={300}
          formItem={{ visible: false }}
        />
        <Column
          caption={"Workorder Instructions"}
          dataField={"workorderInstructions"}
          allowFiltering={true}
          width={300}
          formItem={{ visible: false }}
        />
        <Column
          type={"buttons"}
          buttons={[
            "edit",
            {
              hint: "Add New Asset",
              icon: "plus",
              visible: isNewAssetIconVisible,
              onClick: createNewAsset,
            },
            {
              hint: "Accept",
              icon: "check",
              visible: isAcceptIconVisible,
              onClick: resolve,
            },
            "delete",
          ]}
          width={125}
          fixed={true}
        />
      </Grid>
    </div>
  );
}

export default withMemo(Component, "endpointId");
