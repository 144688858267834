import React, { useRef, useState } from "react";
import styles from "./commentbox.module.scss";
import HtmlEditor from "devextreme-react/html-editor";
import { Button } from "devextreme-react";
import moment from "moment";
import "./commentbox.scss";
import Editor from "../../editor/editor";
import { connect } from "react-redux";
import { request } from "helper/http-client";
import { url } from "config.js";
import { confirm } from "devextreme/ui/dialog";
import { notify } from "helper/global";

function Component(props) {
  // render
  return (
    <div className={styles.main}>
      <Header dataSource={props.dataSource} onRefresh={props.onRefresh} showEmailButton={props.accessTicket} />
      <Body dataSource={props.dataSource} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    accessTicket: !!state.user.accessTicket,
  };
}

export default connect(mapStateToProps, null)(Component);

function Header(props) {
  // hooks

  const [editor, setEditor] = useState(false);

  // event handlers

  function showEditor() {
    setEditor(true);
  }

  function hideEditor(e) {
    if (e) props.onRefresh();
    setEditor(false);
  }

  async function onEmail() {
    var result = await confirm("<i>Do you really want to send an email to the user?</i>", "Confirm");

    if (result)
      try {
        await request({ url: `${url}/message/${props.dataSource.id}/email`, method: "Post", data: props.dataSource });
      } catch (error) {
        notify(`Operation failed. Error: ${error}`, "error", 5000);
      }
  }

  // render

  return (
    <div className={styles.header}>
      <Editor dataSource={props.dataSource} type="message" operation="update" showEditor={editor} hideEditor={hideEditor} />
      <div>
        <img
          className={styles.header_avatar}
          alt="/"
          src={props.dataSource.avatar || `https://stirisprod001.blob.core.windows.net/avatars/Avatar.jpg`}
        />
      </div>
      <div className={styles.header_subject}>
        <div className={styles.header_subject_name}>{props.dataSource.user}</div>
        <div className={styles.header_subject_org}>{`(${props.dataSource.organization})`}</div>
      </div>
      <div className={styles.header_createdon}>{moment(props.dataSource.modifiedOn).fromNow()}</div>
      <div className={styles.header_button}>
        <Button
          text={"Email"}
          icon={"email"}
          type={"success"}
          stylingMode="text"
          visible={props.showEmailButton}
          disabled={props.dataSource.editable === "No"}
          onClick={onEmail}
        />
      </div>
      <div className={styles.header_button}>
        <Button text={"Edit"} icon={"edit"} type={"success"} stylingMode="text" onClick={showEditor} disabled={props.dataSource.editable === "No"} />
      </div>
    </div>
  );
}

function Body(props) {
  const htmlEditor = useRef(null);

  return (
    <div className={`${styles.body} commentbox_htmleditor`}>
      <HtmlEditor ref={htmlEditor} value={props.dataSource.text} readOnly={true}></HtmlEditor>
    </div>
  );
}
