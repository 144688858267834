import React, { useEffect, useRef, useState, useContext } from "react";
import "./map.scss";
import { request } from "helper/http-client";
import { url } from "config.js";
import * as gis from "helper/gis";
import Grid from "components/data-grid/data-grid.js";
import { Column, Editing, FilterRow } from "devextreme-react/data-grid";
import HierarchyContext from "components/hierarchy/context";
import { LoadIndicator } from "devextreme-react/load-indicator";
import Editor from "components/editor/editor";
import { Popup as DxPopup } from "devextreme-react";

function Component(props) {
  //
  // constants
  const fields = [
    {
      name: "id",
      alias: "Id",
      type: "integer",
    },
    {
      name: "assetId",
      alias: "AssetId",
      type: "integer",
    },
    {
      name: "name",
      alias: "Name",
      type: "string",
    },
    {
      name: "description",
      alias: "Description",
      type: "string",
    },
    {
      name: "class",
      alias: "Class",
      type: "string",
    },
    {
      name: "type",
      alias: "Type",
      type: "string",
    },
    {
      name: "size",
      alias: "Size",
      type: "string",
    },
    {
      name: "material",
      alias: "Material",
      type: "string",
    },
    {
      name: "manufacturer",
      alias: "Manufacturer",
      type: "string",
    },
    {
      name: "model",
      alias: "Model",
      type: "string",
    },
    {
      name: "count",
      alias: "Count",
      type: "integer",
    },
    {
      name: "installYear",
      alias: "Install Year",
      type: "integer",
    },
    {
      name: "gisId",
      alias: "Gis Id",
      type: "string",
    },
    {
      name: "visible",
      alias: "Visible",
      type: "integer",
    },
  ];

  // useState

  const [data, setData] = useState(null);
  const [state, setState] = useState({ showEditor: false, nodeId: false, assetId: false });

  // useRef

  const mapRef = useRef();
  const firstRender = useRef(true);

  // useContext

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  // useEffect

  useEffect(
    function () {
      (async () => {
        if (firstRender.current) {
          await gis.initialize(mapRef, fields, null, null, onSelection, onClearSelection, onClick, false);
          firstRender.current = false;
        }
        await getData();
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRowsData, filter]
  );

  useEffect(() => {
    return function () {
      gis.dispose();
    };
  }, []);

  // event handler

  function onSelection(e) {
    setData(e);
  }

  function onClearSelection() {
    setData(null);
  }

  function onSelectionChanged(e) {
    gis.selectFeatureFromId(e && e.gisId);
  }

  // functions

  async function getData() {
    try {
      gis.clear();
      let p1 = request({ url: `${url}/gislayer/node`, params: { ids: selectedRowsData.map((i) => i.id) } });
      let p2 = request({ url: `${url}/node/gis/hierarchy`, params: { ids: selectedRowsData.map((i) => i.id), filterExpression: filter } });
      let [layerInfo, data] = await Promise.all([p1, p2]);
      await gis.setLayers(layerInfo, data);
    } catch (error) {}
  }

  function onClick(e) {
    if (e && e.id) {
      setState({ showEditor: true, nodeId: e.id, assetId: e.assetId });
    }
  }

  function hideEditor(e) {
    setState((state) => {
      return { ...state, showEditor: false };
    });
  }

  // render

  function gridRender() {
    return (
      <Grid
        title={"Assets"}
        dataSource={data}
        editingMode={"none"}
        rowNumbering={true}
        selection={{ mode: "single" }}
        onSelectionChanged={onSelectionChanged}
      >
        <Editing mode={"row"} allowUpdating={false} allowDeleting={false} />
        <FilterRow visible={true} />
        <Column visible={false} dataField={"id"} sortOrder={"desc"} />
        <Column caption={"GIS Id"} dataField={"gisId"} allowFiltering={true} width={150} />
        <Column caption={"Name"} dataField={"name"} allowFiltering={true} width={200} />
        <Column caption={"Description"} dataField={"description"} allowFiltering={true} width={400} />
        <Column caption={"Class"} dataField={"class"} allowFiltering={true} width={150} />
        <Column caption={"Type"} dataField={"type"} allowFiltering={true} width={150} visible={true} />
        <Column caption={"Size"} dataField={"size"} allowFiltering={true} width={150} visible={true} />
        <Column caption={"Material"} dataField={"material"} allowFiltering={true} width={150} visible={true} />
        <Column caption={"Manufacturer"} dataField={"manufacturer"} allowFiltering={true} width={150} visible={true} />
        <Column caption={"Model"} dataField={"model"} allowFiltering={true} width={150} visible={true} />
        <Column caption={"Count"} dataField={"count"} dataType="number" allowFiltering={true} width={150} />
        <Column caption={"Install Year"} dataType="number" dataField={"installYear"} allowFiltering={true} width={150} />
      </Grid>
    );
  }

  return (
    selectedRowsData.length > 0 && (
      <div className={"klient_data_gis_main"}>
        <DxPopup
          visible={state.showEditor}
          onHiding={hideEditor}
          showCloseButton={true}
          dragEnabled={true}
          closeOnOutsideClick={false}
          showTitle={true}
          title={"Asset"}
          width={700}
          height={700}
        >
          <Editor nodeId={state.nodeId} hideEditor={hideEditor} />
        </DxPopup>
        <div className={"klient_data_gis_main_map"} ref={mapRef}>
          <div id={"gis_indicator"} className={"klient_data_gis_main_indicator"} hidden>
            <LoadIndicator />
          </div>
        </div>
        {data && <div className={"klient_data_gis_main_grid"}>{gridRender()} </div>}
      </div>
    )
  );
}

export default Component;
