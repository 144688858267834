import React from "react";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { addFilter } from "helper/global";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule, NumericRule } from "devextreme-react/validator";
import styles from "./measurement.module.scss";

function Component(props) {
  //
  // stores

  const measurementStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/measurement`, loadOptions: addFilter(loadOptions, "and", ["assetId", "=", props.data.assetId]) });
      },
      insert: (data) => {
        data.assetId = props.data.assetId;
        return request({ url: `${url}/measurement`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/measurement`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/measurement/${id}`, method: "Delete" });
      },
    }),
  };

  const measurementTypeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/measurementType`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/measurementType/${id}` });
      },
    }),
  };

  // render

  return (
    <div className={styles.main}>
      <Grid title={"Measurement"} showTitle={false} dataSource={measurementStore} allowDownloading={false}>
        <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
        <Column visible={false} caption={"AssetId"} dataField={"node"} formItem={{ visible: false }} />
        <Column caption={"Type"} dataField={"typeId"} calculateDisplayValue="type" width={175}>
          <Lookup dataSource={measurementTypeStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Unit"} dataField={"unit"} allowFiltering={true} width={150} allowEditing={false} formItem={{ visible: false }} />
        <Column caption={"Value"} dataField={"value"} dataType={"number"} allowFiltering={true} width={150}>
          <NumericRule />
        </Column>
      </Grid>
    </div>
  );
}

export default Component;
